import * as React from 'react';
import Link from 'next/link';
import moment from 'moment';

import track from '../../../lib/track';
import { REALREPORTS_LINK, HOMEBUZZ_LINK } from '../../../lib/constants';
import { setupMapBounds } from '../../../actions/feed';

const Footer = ({ dispatch }: { dispatch: Function }) => {
  const currentYear = moment().format('YYYY');

  const searchLocation = (lat: number, lng: number, name: string) => {
    const valuesToSubmit = {
      location: `${lat},${lng}`,
      locationName: name,
    };
    dispatch(setupMapBounds(valuesToSubmit));
  };

  return (
    <footer>
      <div className="row col-xs-12 footer-inner">
        <div className="row col-xs-12 col-sm-4 footer-column">
          <div className="col-xs-12 col-sm-6 no-mobile-margin">
            <div className="column-header">TORII</div>
            <Link href="/about">
              <a aria-label="About us" className="footer-link">
                About Us
              </a>
            </Link>
            <Link href="/buy">
              <a aria-label="Buying with Torii" className="footer-link">
                Buying with Torii
              </a>
            </Link>
            <Link href="/sell">
              <a aria-label="Selling with Torii" className="footer-link">
                Selling with Torii
              </a>
            </Link>
            <a
              aria-label="Torii blog"
              href="https://www.toriihomes.com/blog"
              target="_blank"
              className="footer-link"
            >
              Blog
            </a>
            <Link href="/careers" prefetch={false}>
              <a aria-label="Careers" className="footer-link" target="_blank">
                Careers
              </a>
            </Link>
            <Link href="/contact" prefetch={false}>
              <a className="footer-link">Contact Us</a>
            </Link>
            <Link href="/get-preapproved" prefetch={false}>
              <a className="footer-link">Get Pre-Approved</a>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="column-spacer" />
            <Link href="/join" prefetch={false}>
              <a aria-label="For Agents" className="footer-link">
                For Agents
              </a>
            </Link>
            <Link href="/legal/privacy" prefetch={false}>
              <a aria-label="Privacy Policy" className="footer-link">
                Privacy Policy
              </a>
            </Link>
            <Link href="/reviews" prefetch={false}>
              <a aria-label="Reviews" className="footer-link">
                Reviews
              </a>
            </Link>
            <Link
              href={{
                pathname: '/search',
                query: { location: '42.3600825,-71.05888010000001' },
              }}
            >
              <a
                aria-label="Search homes in Massachusetts"
                className="footer-link"
                onClick={() => {
                  searchLocation(
                    42.360082500000004,
                    -71.05888010000001,
                    'Boston, MA, USA',
                  );
                }}
              >
                Search Homes in MA
              </a>
            </Link>

            <Link href="/legal/terms" prefetch={false}>
              <a aria-label="Terms of Service" className="footer-link">
                Terms of Service
              </a>
            </Link>
            <div className="no-mobile no-tablet">
              <Link href="https://www.toriio.com/" prefetch={false}>
                <a
                  aria-label="Torii game"
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Toriio
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="row col-xs-12 col-sm-4 footer-column">
          <div className="col-xs-12 col-sm-6 no-mobile-margin">
            <div className="column-header">TOOLS</div>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick('Best Buys', 'Footer', '/tools/best-buys')
              }
            >
              <Link href="/tools/best-buys">
                <a
                  aria-label="Best Buys"
                  className="footer-link"
                  target="_blank"
                >
                  Best Buys
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick(
                  'Closing Cost Calculator',
                  'Footer',
                  '/tools/closing-costs',
                )
              }
            >
              <Link href="/tools/closing-costs">
                <a
                  aria-label="Closing Cost Calculator"
                  className="footer-link"
                  target="_blank"
                >
                  Closing Cost Calculator
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick(
                  'Comps Chomper',
                  'Footer',
                  '/tools/comps-chomper',
                )
              }
            >
              <Link href="/tools/comps-chomper">
                <a
                  aria-label="Comps Chomper"
                  className="footer-link"
                  target="_blank"
                >
                  Comps Chomper
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick(
                  'Fixer Finder',
                  'Footer',
                  '/tools/fixer-finder',
                )
              }
            >
              <Link href="/tools/fixer-finder">
                <a
                  aria-label="Fixer Finder"
                  className="footer-link"
                  target="_blank"
                >
                  Fixer Finder
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick('Fresh Finds', 'Footer', '/tools/fresh-finds')
              }
            >
              <Link href="/tools/fresh-finds">
                <a
                  aria-label="Fresh Finds"
                  className="footer-link"
                  target="_blank"
                >
                  Fresh Finds
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick(
                  'Mortgage Calculator',
                  'Footer',
                  '/tools/mortgage-calculator',
                )
              }
            >
              <Link href="/tools/mortgage-calculator">
                <a
                  aria-label="Mortgage Calculator"
                  className="footer-link"
                  target="_blank"
                >
                  Mortgage Calculator
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick('Homebuzz', 'Footer', HOMEBUZZ_LINK)
              }
            >
              <Link href={HOMEBUZZ_LINK}>
                <a
                  aria-label="Homebuzz"
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Homebuzz
                </a>
              </Link>
            </button>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="column-spacer" />
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick('Homebuzz', 'Footer', '/monthly-market')
              }
            >
              <Link href="/monthly-market">
                <a
                  aria-label="Monthly Market Data"
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Monthly Market Data
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick(
                  'Price Pointer',
                  'Footer',
                  '/tools/price-pointer',
                )
              }
            >
              <Link href="/tools/price-pointer">
                <a
                  aria-label="Price Pointer"
                  className="footer-link"
                  target="_blank"
                >
                  Price Pointer
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick('Random Home', 'Footer', '/random')
              }
            >
              <Link href="/random">
                <a
                  aria-label="Random Home"
                  className="footer-link"
                  target="_blank"
                >
                  Random Home
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick(
                  'Rent Cruncher',
                  'Footer',
                  '/tools/rent-cruncher',
                )
              }
            >
              <Link href="/tools/rent-cruncher">
                <a
                  aria-label="Rent Cruncher"
                  className="footer-link"
                  target="_blank"
                >
                  Rent Cruncher
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick(
                  'Trend Spotter',
                  'Footer',
                  '/tools/trend-spotter',
                )
              }
            >
              <Link href="/tools/trend-spotter">
                <a
                  aria-label="Trend Spotter"
                  className="footer-link"
                  target="_blank"
                >
                  Trend Spotter
                </a>
              </Link>
            </button>
            <button
              className="link-container"
              onClick={() =>
                track.buttonClick('RealReports', 'Footer', REALREPORTS_LINK)
              }
            >
              <Link href={REALREPORTS_LINK}>
                <a
                  aria-label="RealReports"
                  className="footer-link"
                  target="_blank"
                >
                  RealReports
                </a>
              </Link>
            </button>
          </div>
        </div>
        <div className="row col-xs-12 col-sm-2 footer-column">
          <div className="col-xs-12 col-sm-12 no-mobile-margin">
            <div className="column-header">MOBILE APPS</div>
            <a
              aria-label="Torii for iOS"
              href="https://itunes.apple.com/us/app/torii-real-estate/id1362196303?ls=1&mt=8"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <div>Torii for iOS</div>
            </a>
            <a
              aria-label="Torii for Android"
              href="https://play.google.com/store/apps/details?id=com.toriiapp.android"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <div>Torii for Android</div>
            </a>
          </div>
        </div>
        <div className="row col-xs-12 col-sm-2 footer-column">
          <div
            className="row-sm-12 no-mobile-margin"
            style={{ paddingLeft: '0.5rem' }}
          >
            <div className="column-header">CONNECT</div>
            <div className="social-container row-xs-12">
              <a
                aria-label="Torii Facebook Page"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/ToriiHomes/"
                className="social-icon-button"
              >
                <button
                  aria-label="facebook-link"
                  className="social-icon-button-2"
                  onClick={() =>
                    track.buttonClick(
                      'Social Link: Facebook',
                      'footer',
                      'https://www.facebook.com/ToriiHomes/',
                    )
                  }
                >
                  <div className="social-icon fb" />
                </button>
              </a>
              <a
                aria-label="Torii Twitter"
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/toriihomes"
                className="social-icon-button"
              >
                <button
                  aria-label="twitter-link"
                  className="social-icon-button-2"
                  onClick={() =>
                    track.buttonClick(
                      'Social Link: Twitter',
                      'footer',
                      'https://twitter.com/toriihomes',
                    )
                  }
                >
                  <div className="social-icon tw" />
                </button>
              </a>
              <a
                aria-label="Torii Instagram"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/toriihomes/"
                className="social-icon-button"
              >
                <button
                  aria-label="instagram-link"
                  className="social-icon-button-2"
                  onClick={() =>
                    track.buttonClick(
                      'Social Link: Instagram',
                      'footer',
                      'https://www.instagram.com/toriihomes/',
                    )
                  }
                >
                  <div className="social-icon ig" />
                </button>
              </a>
              <a
                aria-label="Torii LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/toriihomes/"
                className="social-icon-button"
              >
                <button
                  aria-label="linkedin-link"
                  className="social-icon-button-2"
                  onClick={() =>
                    track.buttonClick(
                      'Social Link: Linkedin',
                      'footer',
                      'https://www.linkedin.com/company/toriihomes/',
                    )
                  }
                >
                  <div className="social-icon li" />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="row col-xs-12 footer-bottom">
          <div className="torii-logo" />
          <div className="footer-eho-container" />
          <div className="realtor-logo" />
          <div className="pride-flag-container" />
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <p>
              © {currentYear} Torii, Inc. All Rights Reserved.{' '}
              <br className="no-desktop" />
              MA License #422946
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
