import React from 'react';
import {
  CirclesFour,
  MagnifyingGlass,
  HeartStraight,
  Note,
  Wrench,
  ClipboardText,
  Gear,
  Info,
  ChatText,
  Question,
  CurrencyCircleDollar,
  CalendarBlank,
} from 'phosphor-react';

export const AuthenticatedMenu = [
  {
    title: 'Dashboard',
    iconNotActive: <CirclesFour size={24} color="#737373" weight="regular" />,
    iconIsActive: <CirclesFour size={24} color="#443461" weight="fill" />,
    linkURL: '/dashboard',
  },
  {
    title: 'Search',
    iconNotActive: (
      <MagnifyingGlass size={24} color="#737373" weight="regular" />
    ),
    iconIsActive: <MagnifyingGlass size={24} color="#443461" weight="fill" />,
    linkURL: '/search',
  },
  {
    title: 'Favorites',
    iconNotActive: <HeartStraight size={24} color="#737373" weight="regular" />,
    iconIsActive: <HeartStraight size={24} color="#443461" weight="fill" />,
    linkURL: '/favorites',
  },
  {
    title: 'Saved Searches',
    iconNotActive: <Note size={24} color="#737373" weight="regular" />,
    iconIsActive: <Note size={24} color="#443461" weight="fill" />,
    linkURL: '/saved-searches',
  },
  {
    title: 'Tours',
    iconNotActive: <CalendarBlank size={24} color="#737373" weight="regular" />,
    iconIsActive: <CalendarBlank size={24} color="#443461" weight="fill" />,
    linkURL: '/tours',
  },
  {
    title: 'Homebuying Tools',
    iconNotActive: <Wrench size={24} color="#737373" weight="regular" />,
    iconIsActive: <Wrench size={24} color="#443461" weight="fill" />,
    linkURL: '/tools',
  },
  {
    title: 'Your Preferences',
    iconNotActive: <ClipboardText size={24} color="#737373" weight="regular" />,
    iconIsActive: <ClipboardText size={24} color="#443461" weight="fill" />,
    linkURL: '/your-preferences',
  },
  {
    title: 'Account Settings',
    iconNotActive: <Gear size={24} color="#737373" weight="regular" />,
    iconIsActive: <Gear size={24} color="#443461" weight="fill" />,
    linkURL: '/account',
  },
];

export const UnAuthenticatedMenu = [
  {
    title: 'About',
    iconNotActive: <Info size={24} color="#737373" weight="regular" />,
    iconIsActive: <Info size={24} color="#443461" weight="fill" />,
    linkURL: '/about',
  },
  {
    title: 'Contact',
    iconNotActive: <ChatText size={24} color="#737373" weight="regular" />,
    iconIsActive: <ChatText size={24} color="#443461" weight="fill" />,
    linkURL: '/contact',
  },
  {
    title: 'FAQ',
    iconNotActive: <Question size={24} color="#737373" weight="regular" />,
    iconIsActive: <Question size={24} color="#443461" weight="fill" />,
    linkURL: '/buying-with-torii?focus=faq',
  },
  {
    title: 'Get Preapproved',
    iconNotActive: (
      <CurrencyCircleDollar size={24} color="#737373" weight="regular" />
    ),
    iconIsActive: (
      <CurrencyCircleDollar size={24} color="#443461" weight="fill" />
    ),
    linkURL: '/get-preapproved',
  },
  {
    title: 'Homebuying Tools',
    iconNotActive: <Wrench size={24} color="#737373" weight="regular" />,
    iconIsActive: <Wrench size={24} color="#443461" weight="fill" />,
    linkURL: '/tools',
  },
];
