import * as React from 'react';
import { connect } from 'react-redux';
import * as types from '../../../actions/actionTypes';
import ResponsiveDialog from '../../utility/ResponsiveDialog';
import WelcomeDialogContents from './WelcomeDialogContents';
import styles from '../../../styles/sass/components/WelcomeDialog.module.scss';

export interface Props {
  dispatch: any;
  newUser: boolean;
  open: boolean;
  scheduleMeeting?: boolean;
}

/**
 * @class WelcomeDialog
 * @extends {React.Component}
 */
export class WelcomeDialog extends React.Component<Props> {
  handleClose = () => {
    if (this.props.open) {
      this.props.dispatch({ type: types.CloseWelcomeDialog });
      setTimeout(() => {
        this.props.dispatch({ type: types.ClearNewUser });
      }, 500);
    }
  };

  render() {
    const { newUser, open } = this.props;

    return (
      <ResponsiveDialog
        buttonClassName={styles.welcomeDialogCloseButton}
        className={styles.welcomeDialog}
        closeButtonColor="#FFFFFF"
        largePaper={newUser && open ? true : false}
        onClose={this.handleClose}
        open={open}
      >
        <WelcomeDialogContents
          closeModal={this.handleClose}
          newUser={newUser}
          scheduleMeeting={this.props.scheduleMeeting}
        />
      </ResponsiveDialog>
    );
  }
}

const mapStateToProps = (state: any) => ({
  newUser: state.dialogs.newUser,
  open: state.dialogs.welcomeDialogOpen,
});

export default connect(mapStateToProps)(WelcomeDialog);
