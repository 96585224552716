import * as types from '../actions/actionTypes';

const initialState = {};

/**
 * State management for messages to display to a user.
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function messages(state: any = initialState, action: any) {
  switch (action.type) {
    case types.ChangePasswordFailure:
    case types.CheckoutFormFailure:
    case types.ContactFormFailure:
    case types.EListSubscriptionFailure:
    case types.FeedEmailOptOutFailure:
    case types.ForgotPasswordFailure:
    case types.LinkFailure:
    case types.ListingFetchFailure:
    case types.LoginFailure:
    case types.OauthFailure:
    case types.PricePointerFailure:
    case types.RentFormFailure:
    case types.ResetPasswordFailure:
    case types.ShowingRequestFailure:
    case types.SignupFailure:
    case types.SignUpNpFailure:
    case types.TrendsFormFailure:
    case types.UnlinkFailure:
    case types.UpdateProfileFailure:
      return {
        error: action.messages,
      };
    case types.GenericError:
      return {
        error: ['Something went wrong. Please try again.'],
      };
    case types.ChangePasswordSuccess:
    case types.ContactFormSuccess:
    case types.EListSubscriptionSuccess:
    case types.FeedEmailOptOutSuccess:
    case types.FixersFetchSuccess:
    case types.OnboardingProfileSuccess:
    case types.PricePointerSuccess:
    case types.RentFormSuccess:
    case types.ResetPasswordSuccess:
    case types.TrendsFormSuccess:
    case types.UpdateProfileSuccess:
      return {
        success: action.messages,
      };
    case types.ForgotPasswordSuccess:
    case types.UnlinkSucces:
      return {
        info: action.messages,
      };
    case types.OpenForgotDialog:
    case types.OpenLoginDialog:
    case types.ClearMessages:
    case types.OpenSignupDialog:
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
