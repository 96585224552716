import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Transition from './Transition';
import { isTablet } from '../../../lib/getDeviceSize';

const styles = {
  closeIcon: {
    color: '#ccc',
    height: 20,
    width: 20,
  },
  fullScreen: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
    minHeight: '100%',
    padding: 0,
  },
  extraLargePaper: {
    width: isTablet() ? 702 : 1092,
    height: 'calc(100% - 70px)',
    padding: isTablet() ? '24px 5px 5px' : '32px 10px 5px',
    borderRadius: 24,
    maxWidth: 1000,
    maxHeight: 'calc(100% - 70px)',
    margin: 0,
    alignSelf: 'flex-end',
  },
  largePaper: {
    borderRadius: 5,
    maxWidth: 900,
    minHeight: 519,
    padding: 0,
  },
  mediumLargerPaper: {
    borderRadius: 20,
    width: 480,
    height: 'auto',
    padding: 0,
  },
  mediumLargerPaperMobile: {
    borderRadius: 20,
    width: 350,
    height: 710,
    padding: 0,
  },
  mediumLargePaper: {
    borderRadius: 15,
    width: 400,
    height: 440,
    padding: 0,
  },
  mediumLargePaperMobile: {
    borderRadius: 20,
    width: 360,
    height: '75vh',
    padding: 0,
  },
  mediumPaper: {
    borderRadius: 15,
    width: 350,
    height: 440,
    padding: 0,
  },
  smallPaper: {
    borderRadius: 10,
    maxWidth: 218,
    minHeight: 166,
    padding: 0,
    margin: 0,
  },
  paper: {
    borderRadius: 5,
    maxWidth: 720,
    minHeight: 430,
    padding: 0,
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
};

export interface Props {
  buttonClassName?: string;
  children: any;
  classes: any;
  className: string;
  closeButtonColor?: string;
  closeIconStyles?: any;
  hideCloseButton?: Boolean;
  largePaper?: boolean;
  extraLargePaper?: boolean;
  mediumLargerPaper?: boolean;
  mediumLargerPaperMobile?: boolean;
  mediumLargePaper?: boolean;
  mediumLargePaperMobile?: boolean;
  mediumPaper?: boolean;
  smallPaper?: boolean;
  isListingPage?: boolean;
  onClose: Function;
  onEntered?: Function;
  open: boolean;
  overrideFullScreen?: boolean | any;
  backdrop?: string;
}

export interface State {
  width: number;
}

/**
 * @constructor @extends
 * Popup wrapper
 */
export class ResponsiveDialog extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  render() {
    const {
      buttonClassName,
      children,
      classes,
      className,
      closeButtonColor,
      closeIconStyles,
      hideCloseButton,
      largePaper,
      extraLargePaper,
      mediumLargerPaper,
      mediumLargerPaperMobile,
      mediumLargePaper,
      mediumLargePaperMobile,
      mediumPaper,
      smallPaper,
      onClose,
      onEntered,
      open,
      overrideFullScreen,
      backdrop,
    } = this.props;
    const { width } = this.state;
    const fullScreen = width <= 529;

    return (
      <Dialog
        classes={{
          paper: overrideFullScreen
            ? classes.fullScreen
            : largePaper
            ? classes.largePaper
            : mediumLargerPaper
            ? classes.mediumLargerPaper
            : mediumLargerPaperMobile
            ? classes.mediumLargerPaperMobile
            : mediumPaper
            ? classes.mediumPaper
            : smallPaper
            ? classes.smallPaper
            : mediumLargePaper
            ? classes.mediumLargePaper
            : mediumLargePaperMobile
            ? classes.mediumLargePaperMobile
            : extraLargePaper
            ? classes.extraLargePaper
            : classes.Paper,
        }}
        className={className}
        fullScreen={
          typeof overrideFullScreen === 'boolean'
            ? overrideFullScreen
            : fullScreen
        }
        onClose={() => onClose()}
        open={open}
        TransitionComponent={Transition}
        TransitionProps={{ onEntered: () => onEntered && onEntered() }}
        BackdropProps={{
          classes: {
            root: backdrop === 'transparent' && classes.backdrop,
          },
          style: {
            backgroundColor: extraLargePaper ? 'rgba(0, 0, 0, 0.25)' : '',
          },
        }}
      >
        {!hideCloseButton && (
          <IconButton
            className={buttonClassName}
            aria-label="Close"
            onClick={() => onClose()}
            style={{ backgroundColor: 'transparent' }}
          >
            <Close
              style={{
                ...styles.closeIcon,
                ...closeIconStyles,
                color: closeButtonColor || '#ccc',
              }}
            />
          </IconButton>
        )}
        {children}
      </Dialog>
    );
  }
}

export default withStyles(styles)(ResponsiveDialog);
