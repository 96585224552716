import { removeFromArrayOfObjects } from '../lib/helpers';
import * as types from '../actions/actionTypes';

const initialState = {
  blockedListings: [],
  blockedListingsFetched: false,
  blockedLoading: false,
};

/**
 * State management for data related to user's blockedListings
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function blocked(state: any = initialState, action: any) {
  switch (action.type) {
    case types.FavoritesSaveSuccess:
      return Object.assign({}, state, {
        blockedListings: removeFromArrayOfObjects(
          state.blockedListings,
          'id',
          parseInt(action.listing.id, 10),
        ),
      });
    case types.SetBlockedLoading:
      return Object.assign({}, state, {
        blockedLoading: true,
      });
    // TODO: NOT SURE WHY THIS WAS HERE IN THE FIRST PLACE, NEED TO CONFIRM
    // case types.FavoritesDeleteSuccess:
    //   return Object.assign({}, state, {
    //     blockedListings: [action.deletedListing].concat(state.blockedListings),
    //   });
    case types.BlockedFetchSuccess:
      return Object.assign({}, state, {
        blockedListings: state.blockedListings.concat(action.blockedListings),
        blockedListingsFetched: true,
        blockedLoading: false,
      });
    case types.ClearBlockedListings:
      return Object.assign({}, state, {
        blockedListings: [],
        blockedListingsFetched: false,
      });
    case types.DeleteBlockedSuccess:
      return Object.assign({}, state, {
        blockedListings: removeFromArrayOfObjects(
          state.blockedListings,
          'id',
          action.deleteBlockedListingId,
        ),
      });
    case types.DeleteBlockedFailure:
    case types.BlockedFetchFailure:
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
