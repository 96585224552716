import { Dispatch } from 'redux';
import fetchApi from '../services/api';
import * as types from './actionTypes';
import { emitNotificationEvent } from './auth';

const endPoints = {
  email: '/send-email-verification',
  phone: '/send-phone-verification',
  userAgents: '/user-agents',
  verify: '/verify',
  preApproval: '/upload-preapproval',
};

/**
 * Open the Your Team modal
 */
export function openYourTeamModal(agent: any) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      agent,
      type: types.OpenYourTeamModal,
    });
  };
}

/**
 * Close the Your Team modal
 */
export function closeYourTeamModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.CloseYourTeamModal,
    });
  };
}

/**
 * Open the preapproved true modal
 */
export function openPreapprovedTrueModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ShowPreapprovedTrueModal,
    });
  };
}

/**
 * Close the preapproved true modal
 */
export function closePreapprovedTrueModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.HidePreapprovedTrueModal,
    });
  };
}

/**
 * Open the preapproved false modal
 */
export function openPreapprovedFalseModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ShowPreapprovedFalseModal,
    });
  };
}

/**
 * Close the preapproved true modal
 */
export function closePreapprovedFalseModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.HidePreapprovedFalseModal,
    });
  };
}

/**
 * Open the preapproved contact modal
 */
export function openPreapprovedContactModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ShowPreapprovedContactModal,
    });
  };
}

/**
 * Close the preapproved contact modal
 */
export function closePreapprovedContactModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.HidePreapprovedContactModal,
    });
  };
}

/**
 * Set Lender Introduction as complete
 */
export function setLenderIntroductionComplete() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetLenderIntroductionComplete,
    });
  };
}

/**
 * Unset Lender Introduction
 */
export function unsetLenderIntroduction() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetLenderIntroduction,
    });
  };
}

/**
 * Set Lender Introduction as complete
 */
export function setLenderAlreadyExists() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetLenderAlreadyExists,
    });
  };
}

/**
 * Unset Lender Introduction
 */
export function unsetLenderAlreadyExists() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetLenderAlreadyExists,
    });
  };
}

/**
 * Open File Upload Modal
 */
export function openFileUploadModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.OpenFileUploadModal,
    });
  };
}

/**
 * Close File Upload Modal
 */
export function closeFileUploadModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.CloseFileUploadModal,
    });
  };
}

/**
 * Show Documents Successfully Uploaded Modal
 */
export function showUploadSuccessModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ShowDocumentsUploadSuccessModal,
    });
  };
}

/**
 * Hide Documents Successfully Uploaded Modal
 */
export function hideUploadSuccessModal() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.HideDocumentsUploadSuccessModal,
    });
  };
}

/**
 * Set Document Upload Complete
 */
export function setDocumentUploadComplete() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetDocumentUploadComplete,
    });
  };
}

/**
 * Unset Document Upload Complete
 */
export function unsetDocumentUploadComplete() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetUploadComplete,
    });
  };
}

/**
 * Set First Showing Scheduled
 */
export function setFirstShowingScheduled() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetFirstShowingCompleted,
    });
  };
}

export function toggleEmailVerificationPrompt(showEmailVerifyPrompt: boolean) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      showEmailVerifyPrompt,
      type: types.ShowEmailVerifyPrompt,
    });
  };
}

/**
 * Unset First Showing Schedlued
 */
export function unsetFirstShowingScheduled() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetFirstShowingCompleted,
    });
  };
}

export function togglePhoneVerificationPrompt(showPhoneVerifyPrompt: boolean) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      showPhoneVerifyPrompt,
      type: types.ShowPhoneVerifyPrompt,
    });
  };
}

/**
 * Set First Offer Submitted
 */
export function setFirstOfferSubmitted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetFirstOfferSubmitted,
    });
  };
}

export function toggleEmailVerifyModal(showEmailVerifyModal: boolean = false) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      showEmailVerifyModal,
      type: types.ShowEmailVerifyModal,
    });
  };
}

/**
 * Unset First Offer Submitted
 */
export function UnsetFirstOfferSubmitted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetFirstOfferSubmitted,
    });
  };
}

export function togglePhoneVerifyModal(showPhoneVerifyModal: boolean = false) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      showPhoneVerifyModal,
      type: types.ShowPhoneVerifyModal,
    });
  };
}

/**
 * Toggle Quick Start Guide
 */
export function toggleQuickStartGuide() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ToggleQuickStartGuide,
    });
  };
}

/**
 * Disable Quick Start Guide
 */
export function disableQuickStartGuide() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.DisableQuickStartGuide,
    });
  };
}

/**
 * Toggle Your Next Step
 */
export function toggleYourNextTask() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ToggleYourNextTask,
    });
  };
}

/**
 * Disable Your Next Step
 */
export function disableYourNextTask() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.DisableYourNextTask,
    });
  };
}

/**
 * Set Search Preferences Completed
 */
export function setSearchPreferencesCompleted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetSearchPreferencesCompleted,
    });
  };
}

/**
 * Unset Search Preferences Completed
 */
export function unsetSearchPreferencesCompleted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetSearchPreferencesCompleted,
    });
  };
}

/**
 * Set First Search Completed
 */
export function setFirstSearchCompleted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetFirstSearchCompleted,
    });
  };
}

/**
 * Unset First Search Completed
 */
export function UnsetFirstSearchCompleted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetFirstSearchCompleted,
    });
  };
}

/**
 * Set first Favorite Completed
 */
export function setFirstFavoriteCompleted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetFirstFavoriteCompleted,
    });
  };
}

/**
 * Unset first favorite completed
 */
export function unsetFirstFavoriteCompleted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetFirstFavoriteCompleted,
    });
  };
}

/**
 * Set Quick Start Guide Completed
 */
export function setQuickStartGuideCompleted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetQuickStartGuideCompleted,
    });
  };
}

/**
 * Unset Quick Start Guide Completed
 */
export function unsetQuickStartGuideCompleted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetQuickStartGuideCompleted,
    });
  };
}

/**
 * Set Preferences Started
 */
export function setPreferencesStarted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SetPreferencesStarted,
    });
  };
}

/**
 * Unset Preferences Started
 */
export function unsetPreferencesStarted() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UnsetPreferencesStarted,
    });
  };
}

/**
 * Toggle Phone Number Oauth Modal
 */
export function togglePhoneNumberOauthModal(toggle: boolean) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.TogglePhoneNumberOauthModal,
      toggle,
    });
  };
}

export function sendVerification(
  verificationType: 'email' | 'phone',
  token: string,
  showMessage?: boolean,
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchApi(
        endPoints[verificationType],
        {},
        'post',
        {},
        token,
      );
      if (response.ok) {
        if (showMessage) {
          emitNotificationEvent('success', 'Verification code sent!');
        }
        if (verificationType === 'email') {
          return dispatch({
            type: types.SendEmailVerification,
          });
        } else {
          return dispatch({
            type: types.SendPhoneVerification,
          });
        }
      } else {
        dispatch({
          type: types.GenericError,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

export function fetchUsersTeam(token: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.YourTeamLoading,
      yourTeamLoading: true,
    });
    try {
      const response = await fetchApi(
        endPoints.userAgents,
        {},
        'get',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        return dispatch({
          yourTeam: json.yourTeam,
          type: types.YourTeamSuccess,
        });
      } else {
        return dispatch({ type: types.YourTeamFailure });
      }
    } catch (error) {
      dispatch({ type: types.YourTeamFailure });
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

export function uploadPreapprovalDocument(
  values: any,
  token: string,
  callback: (success: boolean) => void,
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchApi(
        endPoints.preApproval,
        values,
        'post',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        callback(true);
        dispatch({ type: types.UpdateProfileSuccess, user: json.user });
      } else {
        callback(false);
      }
    } catch (error) {
      callback(false);
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}
