// tslint:disable: object-literal-sort-keys

export const media = {
  mobile: '@media only screen and (max-width: 529px)',
  mobileLandscape:
    '@media only screen and (max-width: 529px) and (orientation :landscape)',
  gtMobile: '@media only screen and (min-width: 529px)',
  tablet: '@media only screen and (min-width: 530px) and (max-width: 949px)',
  tabletSmall:
    '@media only screen and (min-width: 530px) and (max-width: 767px)',
  tabletLarge:
    '@media only screen and (min-width: 767px) and (max-width: 949px)',
  tabletLandscape:
    '@media only screen and (min-width: 530px) and (max-width: 949px) and (orientation :landscape)',
  gtTablet: '@media only screen and (min-width: 950px)',
  desktopSmall: '@media only screen and (width: 1024px)',
  tabletAndDesktop:
    '@media only screen and (min-width: 530px) and (max-width: 1349px)',
  desktop: '@media only screen and (min-width: 950px) and (max-width: 1349px)',
  desktopLandscape:
    '@media only screen and (min-width: 950px) and (max-width: 1349px) and (orientation :landscape)',
  desktopXl: '@media only screen and (min-width: 1350px)',
  desktopMedium:
    '@media only screen and (min-width: 950px) and (max-width: 1125px)',
};

export const colors = {
  bluePrimary: '#42A5f5',
  blueSecondary: '#3D86EF',
  greenPrimary: '#2CC7A4',
  greenSecondary: '#59b599',
  greenButton: '#2C5853',
  greyPrimary: '#5E738F',
  greySecondary: '#455468',
  text: 'rgba(0, 0, 0, 0.87)',
  textLight: '#555555',
  textDark: '#4a4a4a',
  white: '#FFFFFF',
  // New Dahsboard palette
  clementine: '#FF7350',
  purplePrimary: '#443461',
  purpleSecondary: '#C8BDDB',
  orangePrimary: '#f18257',
  pinkPrimary: '#f3a4bf',
  creamBackground: '#FBF5EF',
  pineGreen: '#195A50',
};

export const helpers: any = {
  mobileOnly: {
    [media.tablet]: {
      display: 'none !important',
    },
    [media.desktop]: {
      display: 'none !important',
    },
    [media.desktopXl]: {
      display: 'none !important',
    },
  },
  tabletOnly: {
    [media.mobile]: {
      display: 'none !important',
    },
    [media.desktop]: {
      display: 'none !important',
    },
    [media.desktopXl]: {
      display: 'none !important',
    },
  },
  noDesktopXl: {
    [media.desktopXl]: {
      display: 'none !important',
    },
  },
  desktopXlOnly: {
    [media.mobile]: {
      display: 'none !important',
    },
    [media.tablet]: {
      display: 'none !important',
    },
    [media.desktop]: {
      display: 'none !important',
    },
  },
  noTablet: {
    [media.tablet]: {
      display: 'none !important',
    },
  },
  noMobile: {
    [media.mobile]: {
      display: 'none !important',
    },
  },
  noDesktopSmall: {
    [media.desktopSmall]: {
      display: 'none !important',
    },
  },
  noDesktop: {
    [media.desktop]: {
      display: 'none !important',
    },
    [media.desktopXl]: {
      display: 'none !important',
    },
  },
};

const buttonLargeBase: any = {
  fontSize: 20,
  height: 65,
  letterSpacing: 2,
  textTransform: 'uppercase',
  width: 320,
};
const buttonMediumLargeBase: any = {
  height: 48,
  width: 265,
};
const buttonMediumBase: any = {
  fontSize: 16,
  height: 41,
  letterSpacing: 2,
  textTransform: 'uppercase',
  width: 204,
};
const buttonSmall: any = {
  fontSize: 16,
  height: 50,
  letterSpacing: 0,
  width: 165,
};
const buttonPurpleDialog: any = {
  height: 56,
  width: '100%',
};
const buttonOrange: any = {
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '28px',
  letterSpacing: '0em',
  textAlign: 'left',
  textTransform: 'none',
  width: 'auto',
  height: 60,
};

export const buttonStyles: any = {
  buttonLarge: {
    ...buttonLargeBase,
  },
  buttonLargeBlue: {
    borderRadius: 60,
    '&:hover': {
      backgroundColor: colors.purpleSecondary,
    },
    backgroundColor: colors.purplePrimary,
    color: colors.white,
    ...buttonLargeBase,
  },
  buttonLargeGreen: {
    borderRadius: '40px',
    '&:hover': {
      backgroundColor: colors.greenSecondary,
    },
    backgroundColor: colors.greenButton,
    color: colors.white,
    ...buttonLargeBase,
  },
  buttonSignupGreen: {
    ...buttonLargeBase,
    '&:hover': {
      backgroundColor: colors.greenSecondary,
    },
    backgroundColor: colors.greenButton,
    color: colors.white,
    width: 275,
    height: 60,
    padding: '0px 16px',
    borderRadius: '60px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  buttonSignupGreenSmall: {
    ...buttonSmall,
    '&:hover': {
      backgroundColor: colors.greenSecondary,
    },
    backgroundColor: colors.greenButton,
    color: colors.white,
    borderRadius: '60px',
    textTransform: 'none',
    padding: '0px 16px',
    width: 175,
  },
  buttonSignupNavigation: {
    ...buttonMediumLargeBase,
    '&:hover': {
      backgroundColor: colors.greenSecondary,
    },
    '&:disabled': {
      backgroundColor: '#DCD7D3',
      color: '#A39F9C',
    },
    backgroundColor: colors.greenButton,
    color: colors.white,
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: '10px',
    marginTop: '0px!important',
  },
  buttonSocialAuth: {
    ...buttonMediumLargeBase,
    '&:hover': {
      backgroundColor: colors.white,
    },
    backgroundColor: colors.white,
    color: '#2D2332',
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: '5px',
  },
  buttonLargeGrey: {
    '&:hover': {
      backgroundColor: colors.greySecondary,
    },
    backgroundColor: colors.greyPrimary,
    color: colors.white,
    ...buttonLargeBase,
  },
  buttonLargePink: {
    '&:hover': {
      backgroundColor: colors.pinkPrimary,
    },
    backgroundColor: colors.pinkPrimary,
    color: colors.white,
    ...buttonLargeBase,
  },
  buttonMedium: {
    ...buttonMediumBase,
  },
  buttonMediumBlue: {
    '&:hover': {
      backgroundColor: colors.purpleSecondary,
    },
    backgroundColor: colors.purplePrimary,
    color: colors.white,
    ...buttonMediumBase,
  },
  buttonMediumGreen: {
    '&:hover': {
      backgroundColor: colors.greenSecondary,
    },
    backgroundColor: colors.greenButton,
    color: colors.white,
    ...buttonMediumBase,
  },
  buttonMediumPink: {
    '&:hover': {
      backgroundColor: colors.pinkPrimary,
    },
    backgroundColor: colors.pinkPrimary,
    color: colors.white,
    ...buttonMediumBase,
  },
  buttonMediumWideGreen: {
    '&:hover': {
      backgroundColor: colors.greenSecondary,
    },
    backgroundColor: colors.greenButton,
    color: colors.white,
    ...buttonMediumBase,
  },
  buttonSmallPurple: {
    '&:hover': {
      backgroundColor: '#e3deed',
      color: colors.purplePrimary,
    },
    backgroundColor: colors.pineGreen,
    borderRadius: 30,
    color: colors.white,
    textTransform: 'none',
    ...buttonSmall,
  },
  buttonWhite: {
    '&:hover': {
      backgroundColor: colors.pineGreen,
      color: colors.white,
    },
    backgroundColor: colors.white,
    color: colors.pineGreen,
    borderRadius: '10px',
    textTransform: 'none',
    ...buttonSmall,
  },
  buttonPurple: {
    '&:hover': {
      backgroundColor: colors.creamBackground,
      color: colors.purplePrimary,
    },
    borderRadius: '10px',
    backgroundColor: colors.purplePrimary,
    color: colors.white,
    ...buttonMediumLargeBase,
  },
  buttonPurpleSaveDialog: {
    ...buttonPurpleDialog,
    '&:hover': {
      backgroundColor: colors.creamBackground,
      color: colors.purplePrimary,
    },
    borderRadius: '10px',
    backgroundColor: colors.purplePrimary,
    color: colors.white,
  },
  buttonOutlineSaveDialog: {
    ...buttonPurpleDialog,
    '&:hover': {
      backgroundColor: colors.purpleSecondary,
      color: colors.creamBackground,
    },
    borderRadius: '10px',
    border: '2px solid #463264',
    backgroundColor: colors.white,
    color: colors.purplePrimary,
  },
  buttonHomeOrange: {
    ...buttonOrange,
    color: colors.white,
    backgroundColor: colors.clementine,
    borderRadius: '24px',
    maxWidth: 275,
  },
  buttonWhiteOrange: {
    ...buttonOrange,
    '&:hover': {
      backgroundColor: colors.clementine,
      color: colors.white,
      border: '2px solid #ffffff',
    },
    color: colors.clementine,
    backgroundColor: colors.white,
    borderRadius: '24px',
    border: '2px solid #FF7350',
  },
  link: {
    textDecoration: 'none',
  },
};

export default {
  buttonStyles,
  colors,
  media,
  helpers,
};

// tslint:enable: object-literal-sort-keys
