import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  teams: [],
  locations: [],
  categories: [],
  message: '',
};

/**
 * State management for data related to careers
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function careers(state: any = initialState, action: any) {
  switch (action.type) {
    case types.LoadOpeningsStart:
      return Object.assign({}, state, {
        loading: true,
      });
    case types.LoadOpeningsSuccess:
      return Object.assign({}, state, {
        categories: action.categories,
        teams: action.teams,
        locations: action.locations,
        loading: false,
      });
    case types.LoadOpeningsError:
      return Object.assign({}, state, {
        loading: false,
        message: action.error,
        teams: [],
        locations: [],
        categories: [],
      });
    default:
      return state;
  }
}
