import { combineReducers } from 'redux';
import auth from './auth';
import blocked from './blocked';
import closingCosts from './closingCosts';
import comps from './comps';
import dialogs from './dialogs';
import errors from './errors';
import favorites from './favorites';
import feed from './feed';
import listings from './listings';
import messages from './messages';
import monthlyData from './monthlyData';
import offer from './offer';
import randomHome from './randomHome';
import searchSurvey from './searchSurvey';
import signupSurvey from './signupSurvey';
import careers from './careers';
import provider from './provider';
import socialListing from './socialListing';
import dashboard from './dashboard';
import showings from './showings';

export default combineReducers({
  auth,
  blocked,
  closingCosts,
  comps,
  dashboard,
  dialogs,
  errors,
  favorites,
  feed,
  listings,
  messages,
  monthlyData,
  offer,
  randomHome,
  searchSurvey,
  signupSurvey,
  careers,
  provider,
  socialListing,
  showings,
});
