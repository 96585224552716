import * as types from '../actions/actionTypes';
import { searchFilterState } from '../components/pages/search/constants';
import { removeBlockedListings } from '../lib/helpers';

const initialState = {
  feed: [],
  feedEmpty: false,
  feedFetched: false,
  feedSearch: {
    boundingBox:
      '42.40642719652907,-70.99708200429689,42.31344971859909,-71.12067819570314',
    location: '42.3600825,-71.05888010000001', // Boston, MA, USA
  },
  feedSearchFetched: false,
  initialMapLoad: true,
  placeBoundaries: undefined,
  popularSearchAreas: [],
  searchHistory: [],
  usePlaceBoundary: true,
  savedSearchesLoading: false,
  savedSearches: {},
  sortBy: '',
  updateSaveSearch: {},
  filterSearch: searchFilterState,
  markerClicked: '',
  selectedListing: '',
};

/**
 * State management for data related to user feeds
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function feed(state: any = initialState, action: any) {
  switch (action.type) {
    case types.ModifyCityBoundary:
      return Object.assign({}, state, {
        usePlaceBoundary: action.usePlaceBoundary,
      });
    case types.SortedFeed:
      return Object.assign({}, state, {
        feed: action.feed,
        sortBy: action.sortBy,
        feedFetched: true,
      });
    case types.FetchingFeed:
      return Object.assign({}, state, {
        feedFetched: action.feedFetched,
      });
    case types.FeedFetchSuccess:
      return Object.assign({}, state, {
        feed: action.feed,
        feedEmpty: false,
        feedFetched: true,
        feedSearch: action.feedSearch,
        initialMapLoad: false,
        placeBoundaries: action.placeBoundaries,
        popularSearchAreas: action.popularSearchAreas,
      });
    case types.FeedFetchFailure:
      return Object.assign({}, state, {
        feed: [],
        feedFetched: true,
        placeBoundaries: undefined,
        popularSearchAreas: [],
      });
    case types.FeedMarkerSelect:
      return Object.assign({}, state, {
        markerClicked: action.lisitingId,
        selectedListing: action.selectedListing,
      });
    case types.FeedSearchSaveSuccess:
      return Object.assign({}, state, {
        feedSearch: action.feedSearch,
        feedSearchFetched: true,
      });
    case types.FeedSearchFetchSuccess:
      return Object.assign({}, state, {
        feedSearchFetched: true,
        searchHistory: action.searchHistory,
      });
    case types.InitialMapLoad:
      return Object.assign({}, state, {
        feedFetched: false,
        feedSearch: action.feedSearch,
        initialMapLoad: true,
        usePlaceBoundary: action.usePlaceBoundary,
      });
    case types.UpdateFeedSearch:
      return Object.assign({}, state, {
        feedSearch: action.feedSearch,
      });
    case types.BlockListingSuccess:
      return Object.assign({}, state, {
        feed: removeBlockedListings(state.feed, action.listing),
      });
    case types.LogoutSuccess:
      return Object.assign({}, state, {
        searchHistory: [],
      });
    case types.FetchSavedSearchesRequest:
      return Object.assign({}, state, {
        savedSearchesLoading: true,
      });
    case types.FetchSavedSearchesSuccess:
      return Object.assign({}, state, {
        savedSearchesLoading: false,
        savedSearches: action.savedSearches,
      });
    case types.FetchSavedSearchesFailure:
      return Object.assign({}, state, {
        savedSearchesLoading: false,
      });

    case types.UpdateSavedSearchRequest:
      return Object.assign({}, state, {
        updateSaveSearch: {
          ...state.updateSaveSearch,
          ...action.payload,
        },
      });
    case types.UpdateSavedSearchSuccess:
      return Object.assign({}, state, {
        updateSaveSearch: {
          ...state.updateSaveSearch,
          searchId: null,
          saveSearchLoading: null,
          emailNotificationLoading: null,
        },
        savedSearches: action.savedSearches,
      });
    case types.UpdateSavedSearchFailure:
      return Object.assign({}, state, {
        updateSaveSearch: {
          ...state.updateSaveSearch,
          searchId: null,
          saveSearchLoading: null,
          emailNotificationLoading: null,
        },
      });
    case types.UpdateFilterSearch:
      return Object.assign({}, state, {
        filterSearch: {
          ...state.filterSearch,
          ...action.updatedFilter,
        },
      });
    case types.OpenUndoSavedSearchToast:
      return Object.assign({}, state, {
        updateSaveSearch: {
          ...state.updateSaveSearch,
          search: action.search,
        },
      });
    case types.CloseUndoSavedSearchToast:
      return Object.assign({}, state, {
        updateSaveSearch: {
          ...state.updateSaveSearch,
          search: null,
        },
      });
    case types.ClearFeed:
      return initialState;
    default:
      return state;
  }
}
