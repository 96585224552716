import * as types from '../actions/actionTypes';

const initialState = {
  area: 'boston',
  availableMonths: [],
  month: {},
};

/**
 * State management for monthly data. Used primarily in the Monthly Market report.
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function monthlyData(state: any = initialState, action: any) {
  switch (action.type) {
    case types.MonthlyMarketMonthsFetchSuccess:
      return Object.assign({}, state, {
        area: action.area,
        availableMonths: action.months,
      });
    case types.MonthlyMarketDataFetchSuccess:
      return Object.assign({}, state, {
        area: action.area,
        month: action.data,
      });
    case types.MonthlyMarketDataFetchFailure:
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
