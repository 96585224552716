import * as types from '../actions/actionTypes';

const initialState = {
  address: undefined,
  errorMessage: undefined,
  maxBudget: undefined,
  timeFrame: undefined,
  welcomeSurveyCompleted: false,
};

export default function searchSurvey(state: any = initialState, action: any) {
  switch (action.type) {
    case types.UpdateSearchSurvey:
      return Object.assign({}, state, {
        ...action.values,
      });
    case types.SearchSurveyFetchSuccess:
      return Object.assign({}, state, {
        ...action.values,
        welcomeSurveyCompleted: true,
      });
    case types.SearchSurveyFetchFailure:
      return Object.assign({}, state, {
        errorMessage: action.message,
      });
    case types.UpdateSearchSurveySuccess:
      return Object.assign({}, state, {
        welcomeSurveyCompleted: true,
      });
    case types.UpdateSearchSurveyFailure:
      return Object.assign({}, state, {
        timeFrame: undefined,
        address: undefined,
        maxBudget: undefined,
        errorMessage: action.message,
        welcomeSurveyCompleted: false,
      });
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
