import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';
import config from '../services/config';
import { getOrCreateStore } from './with-redux-store';
import {
  calculateConversionValue,
  formatBathrooms,
  formatUrlAddress,
  isProduction,
  capitalize,
} from './helpers';

const store = getOrCreateStore();

let user: any;
// Check to see if we've disabled tracking in this environment
const enableTracking: boolean = true;

// Track an event with Google Tag Manager
function gtmEvent(eventName: string, extras?: any) {
  if (window && (window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: eventName,
      ...extras,
    });
  }
}

function trackEvent(
  eventName: string,
  eventValues: any,
  registerUser?: boolean,
  identify?: boolean,
) {
  if (!enableTracking) {
    return null;
  }
  const auth = store.getState().auth;
  if (!user || isEmpty(user)) {
    user = auth.user;
  }
  // TODO: add tracking client here
}

const track = {
  // tslint:disable-next-line: no-empty
  initialize: () => {},

  login: (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: number,
    type: string = 'credentials',
  ) => {
    // Track event using Amplitude analytics and mixpanel
    user = { firstName, lastName, email, phoneNumber };
    // TODO: Type value
    trackEvent('Login', { Type: type }, false, true);

    if (isProduction()) {
      gtmEvent('login');
      ga('send', 'event', 'Forms', 'login');
    }
  },

  logout: () => {
    trackEvent('Logout', {});
  },

  register: (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: number,
    type: string = 'credentials',
  ) => {
    gtmEvent('signup', { firstName, lastName, email, phoneNumber });
    trackEvent('Register', { Type: type }, true);
  },

  pageView: (page: string) => {
    trackEvent('Page View', { page });
    gtmEvent('pageview', { page });
  },

  trackPage: (page: string, extraParams?: any) => {
    trackEvent(capitalize(page), { ...extraParams });
  },

  search: (payload: any = {}, location: string = '') => {
    trackEvent('Search', { query: payload.query, location });
  },

  toolUsed: (toolName: string, query: any = {}) => {
    let properties: any = { toolName };
    if (query) {
      properties = { ...properties, query };
    }
    trackEvent('Used Tool', properties);
  },

  scheduleShowing: (listing: any) => {
    trackEvent('Scheduled Showing', { listing });
  },

  blockedListing: (listingId: any) => {
    trackEvent('Blocked Listing', { listingId });
  },

  favoritedListing: (listingId: any) => {
    trackEvent('Favorited Listing', { listingId });
  },

  unfavoritedListing: (listingId: any) => {
    trackEvent('Unfavorited Listing', { listingId });
  },

  editAccount: (newAccountValues: any) => {
    trackEvent('Edit Account', { newAccountValues });
  },

  submitContactForm: (contactValues: any) => {
    trackEvent('Submit Contact Form', { contactValues });
  },

  verifiedEmail: () => {
    trackEvent('Verified Email', {});
  },

  verifiedPhone: () => {
    trackEvent('Verified Phone', {});
  },

  completeProfile: (values: any) => {
    trackEvent('Complete Profile', { ...values });
  },

  listingView: (listing: any) => {
    trackEvent('Listing View', {
      address: formatUrlAddress(listing),
      bathrooms: formatBathrooms(listing),
      bedrooms: listing.noBedrooms,
      mlsId: listing.listingMlsId,
      price: listing.listPrice,
      status: listing.status,
    });
  },

  completeSearchSurvey: (values: any) => {
    // Conversion tracking for ads
    // We only want to associate a value with conversions when users are within our target
    // If they are in the wrong place or budget, use a value of zero,
    // otherwise, use their price range as their value for ads
    const value = calculateConversionValue(values.maxBudget, values.state);

    if (isProduction()) {
      if (ga) {
        ga('send', 'event', 'Forms', 'signup', { currency: 'USD', value });
      }
    }
    trackEvent('Complete Search Survey', { ...values });
  },

  completeFinancingForm: (values: any) => {
    trackEvent('Complete Financing', { ...values });
  },

  swiperCardSwiped: () => {
    trackEvent('Swiper swiped', {});
  },

  buttonClick: (buttonText: string, location: string, destination: string) => {
    trackEvent('Button Click', { buttonText, location, destination });
  },

  modalView: (modalName: string) => {
    trackEvent('Modal View', { modalName });
  },

  view: (contentName: string) => {
    trackEvent('View', { contentName });
  },

  playedToriioGame() {
    trackEvent('Played Toriio', {});
  },

  explainerVideoWatched() {
    trackEvent('Watched Explainer Video', {});
  },

  homePageSliderSwiped(homePrice: number) {
    trackEvent('Home page Slider', { homePrice });
  },

  trackLastWebLogin(lastWebLogin: String) {
    trackEvent('Last web login', { lastWebLogin });
  },
};

if (typeof window !== 'undefined') {
  // Include track in window. It is being used in _document.tsx file
  window.track = track;
}

export default track;
