import * as types from '../actions/actionTypes';

const initialState = {
  closingCostLoading: false,
  closingCostState: 'MA',
  prepaidInfo: {},
  toriiBrokerage: {},
  traditionalBrokerage: {},
};

/**
 * State management for data related to comps
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */

export default function comps(state: any = initialState, action: any) {
  switch (action.type) {
    case types.ClosingCostsFetchSuccess:
      return Object.assign({}, state, {
        closingCostLoading: false,
        closingCostState: action.closingCostState,
        prepaidInfo: action.prepaidInfo,
        toriiBrokerage: action.toriiBrokerage,
        traditionalBrokerage: action.traditionalBrokerage,
      });
    case types.ClosingCostsFetchFailure:
      return Object.assign({}, state, {
        closingCostLoading: false,
        closingCostState: 'MA',
        prepaidInfo: {},
        toriiBrokerage: {},
        traditionalBrokerage: {},
      });
    case types.ClosingCostsFetch:
      return Object.assign({}, state, {
        closingCostLoading: true,
      });
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
