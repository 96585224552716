import * as types from '../actions/actionTypes';

const initialState = {
  loading: true,
  provider: undefined,
  message: '',
};

/**
 * State management for data related to provider
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function provider(state: any = initialState, action: any) {
  switch (action.type) {
    case types.LoadProviderStart:
      return Object.assign({}, state, {
        loading: true,
      });
    case types.LoadProviderSuccess:
      return Object.assign({}, state, {
        loading: false,
        provider: action.provider,
      });
    case types.LoadProviderError:
      return Object.assign({}, state, {
        loading: false,
        message: action.error,
        provider: undefined,
      });
    default:
      return state;
  }
}
