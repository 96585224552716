import { Dispatch } from 'redux';
import fetchApi from '../services/api';
import Router from 'next/router';
import track from '../lib/track';
import * as types from './actionTypes';
import { get, findIndex, kebabCase } from 'lodash';
import { getQueryParams, parseNumber } from '../lib/helpers';
import EventEmitter from '../lib/eventEmitter';
import { isMobile } from '../lib/getDeviceSize';
import { forceLoginHelper } from './auth';

export const FEED_FETCH_LIMIT = isMobile() ? 100 : 200;
const endPoints = {
  feed: (params: string) =>
    `/listings/feed?${params}&photos=photoMedium,photoMediumList,photoSmall,photoLargeList`,
  feedPost: '/listings/feed',
  feedSearch: '/listings/feed-search',
  mapFeed: (params: string, fetchLargePhotoList: boolean) =>
    `/listings/map-feed?${params}&photos=photoMedium,photoMediumList,photoSmall${
      fetchLargePhotoList
        ? isMobile()
          ? ',photoTallList'
          : ',photoLargeList'
        : ''
    }`,
  savedSearches: '/listings/saved-search',
  updateSavedSearch: (searchId: number) => `/listings/saved-search/${searchId}`,
};

export function clearFeed() {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearFeed,
    });
  };
}

/**
 * Make a query string for feed searches
 * @export
 * @param {*} values
 * @returns
 */
export function constructFeedParams(values: any) {
  let params = '';
  const paramsToCheck = [
    'location',
    'maxPrice',
    'minPrice',
    'minBr',
    'minBath',
    'minSf',
    'maxSf',
    'maxHOA',
    'minParking',
    'maxAge',
    'minAge',
    'propType',
    'statuses',
    'daysOnMarket',
    'minDaysOnMarket',
    'openHousesOnly',
    'hasGarageSpaces',
    'photosByRoom',
    'conditionsByRoomType',
    'polygonBoundaries',
  ];
  const stringParams = [
    'boundingBox',
    'polygonBoundaries',
    'location',
    'photosByRoom',
    'conditionsByRoomType',
  ];
  const arrayParams = ['propType', 'statuses'];
  paramsToCheck.forEach(param => {
    if (values[param]) {
      if (stringParams.indexOf(param) >= 0) {
        // Add this value to the query string without modification
        params += `&${param}=${values[param]}`;
      } else if (arrayParams.indexOf(param) >= 0) {
        let items = '';
        if (Array.isArray(values[param]) && values[param].length > 0) {
          values[param].forEach((item: string, index: number) => {
            items += `${index !== 0 ? ',' : ''}${item}`;
          });
          params += `&${param}=${items}`;
        }
      } else {
        // Parse the value as a number
        values[param] = parseNumber(values[param]);
        params += `&${param}=${values[param]}`;
      }
    }
  });
  return params;
}

export function setupMapBounds(values: any, mapView: boolean = true) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      feedSearch: values,
      type: types.InitialMapLoad,
      usePlaceBoundary: true,
    });
    if (mapView) {
      Router.push({
        pathname: '/search',
        query: {
          location: values.location,
          filters: JSON.stringify(values),
          view: mapView ? 'map' : 'swiper',
        },
      });
    }
  };
}

/**
 * Fetch a user's listing feed. <br>
 * @param {string} values - values
 * @param {string} token - A user's authentication token
 * @param {number} limit - Number of listings to be fetched
 * @param {number} offset - The number of listings to start back from in our search
 * @param {boolean} refresh - if we want to show Loader
 * @param {boolean} dashboard - If we are calling this function from Dashboard component
 * @param {Function} emptyResultCallback - Callback to handle if response is empty
 */
export function fetchFeed(
  values: any,
  token: string,
  limit: number = 10,
  offset?: number,
  refresh?: boolean,
  dashboard?: boolean,
  emptyResultCallback?: () => void,
) {
  return async (dispatch: Dispatch<any>) => {
    const radiusMiles = values.radiusMiles ? values.radiusMiles : 8;
    dispatch({
      type: types.ClearMessages,
    });
    if (!refresh) {
      dispatch({
        feedFetched: false,
        type: types.FetchingFeed,
      });
    }
    if (dashboard && refresh) {
      dispatch({
        recommendsLoading: true,
        type: types.FetchingRecommendedListings,
      });
    }
    let params = '';
    const paramsToCheck = [
      'maxPrice',
      'minBr',
      'minBath',
      'minPrice',
      'minSf',
      'maxAge',
    ];
    for (let i = 0; i < paramsToCheck.length; i += 1) {
      const p = paramsToCheck[i];
      if (values[p]) {
        params += `&${p}=${values[p]}`;
      }
    }

    if (offset) {
      params += `&offset=${offset}`;
    }
    params += `&limit=${limit}`;
    params += `&radiusMiles=${radiusMiles}`;
    params += `&location=${values.location}`;
    try {
      const response = await fetchApi(
        endPoints.mapFeed(params, false),
        {},
        'get',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        if (dashboard) {
          // If no recommendedListings, execute callback so we search with different parameters.
          if (json.results.length === 0 && emptyResultCallback) {
            return emptyResultCallback();
          }
          return dispatch({
            recommendedListings: json.results,
            type: types.RecommendsFetchSuccess,
          });
        }
        return dispatch({
          feed: json.results,
          feedSearch: values,
          messages: [json],
          type: types.FeedFetchSuccess,
        });
      } else {
        return dispatch(
          forceLoginHelper(json, () => {
            if (dashboard) {
              dispatch({
                messages: [json],
                type: types.RecommendsFetchFail,
              });
            } else {
              dispatch({
                messages: [json],
                type: types.FeedFetchFailure,
              });
            }
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Store a user's feed search so that we can fetch it later.<br>
 * @param {Object} values - values
 * @param {type} token - A user's authentication token
 */
export function saveFeedSearch(
  values: {},
  token: string,
  {
    saveSearch,
    emailNotification,
    searchName,
  }: {
    saveSearch?: boolean | null;
    emailNotification?: boolean | null;
    searchName?: string | null;
  } = {},
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    const payload = {
      query: JSON.stringify(values),
      saveSearch,
      emailNotification,
      searchName,
    };
    try {
      const response = await fetchApi(
        endPoints.feedPost,
        payload,
        'post',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        track.search(payload);
        if (saveSearch) {
          dispatch({ type: types.CloseSaveSearchDialog });
          dispatch({ type: types.OpenSavedSearchPopup });
          dispatch(fetchSavedSearches());
        }
        return dispatch({
          feedSearch: values,
          messages: [json],
          type: types.FeedSearchSaveSuccess,
        });
      } else {
        return dispatch(
          forceLoginHelper(json, () => {
            dispatch({
              messages: [json],
              type: types.FeedSearchSaveFailure,
            });
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch a user's feed search <br>
 * @param {type} token - A user's authentication token
 */
export function fetchFeedSearch(token: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const feedSearchResponse = await fetchApi(
        endPoints.feedSearch,
        {},
        'get',
        {},
        token,
      );
      const feedSearchJson = await feedSearchResponse.json();
      const { searchHistory } = feedSearchJson;

      if (feedSearchResponse.ok) {
        const searchHistoryQueriesWithoutMatches: any = [];
        const searchHistoryQueriesWithMatches: any = [];

        // Note: this is a bit hacky, but it allows a user to see their recent searches quickly,
        // and then we go try to fetch their searches right after

        // Push all the recent searches without feed results
        for (const search of searchHistory) {
          searchHistoryQueriesWithoutMatches.push({
            ...search.query,
          });
        }
        dispatch({
          messages: [feedSearchResponse],
          searchHistory: searchHistoryQueriesWithoutMatches,
          type: types.FeedSearchFetchSuccess,
        });

        // For each search, fetch it and then set the number of 'New' listings
        for (const search of searchHistory) {
          const paramValues = Object.assign({}, search.query, {
            statuses: ['New'],
          });
          let params = constructFeedParams(paramValues);
          if (search.query.locationName) {
            params += `&locationName=${search.query.locationName}`;
          }
          const feedResponse = await fetchApi(endPoints.feed(params));
          const feedJson = await feedResponse.json();
          const listings = feedJson.results;
          const newListings = listings.filter(
            (listing: any) =>
              get(listing, 'status', '').toLowerCase() === 'new',
          );
          searchHistoryQueriesWithMatches.push({
            ...search.query,
            numberOfNewMatches: newListings.length,
          });
        }
        // Now use the updated list of searches with 'New' listing counts added
        dispatch({
          messages: [feedSearchResponse],
          searchHistory: searchHistoryQueriesWithMatches,
          type: types.FeedSearchFetchSuccess,
        });
      } else {
        dispatch(
          forceLoginHelper(feedSearchJson, () => {
            dispatch({
              messages: [feedSearchResponse],
              searchHistory: [],
              type: types.FeedSearchFetchFailure,
            });
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch a feed for the map view<br>
 * Note that it's possible to only pass a location to this search
 * @export
 * @param {string} location
 * @param {string} token
 * @param {boolean} useBoundingBox
 * @param {string} boundingBox
 * @param {*} [values={}]
 * @returns
 */
export function fetchMapFeed(
  location: string,
  token: string,
  boundingBox?: string,
  useBoundingBox: boolean = false,
  values: any = {},
  usePlaceBoundary: boolean = false,
  fetchLargePhotoList: boolean = false,
  mapView: boolean = true,
) {
  // Always override the location of a feedSearch with the passed location
  values.location = location;
  let params = constructFeedParams(values);
  if (boundingBox) {
    params += `&boundingBox=${boundingBox}`;
  }
  params += `&useBoundingBox=${useBoundingBox}`;
  if (usePlaceBoundary && values.locationName) {
    params += `&locationName=${values.locationName}`;
  }
  params += `&limit=${FEED_FETCH_LIMIT}`;
  values.boundingBox = boundingBox;
  return async (dispatch: any) => {
    dispatch({
      feedFetched: false,
      type: types.FetchingFeed,
    });
    try {
      const response = await fetchApi(
        endPoints.mapFeed(params, fetchLargePhotoList),
        {},
        'get',
        {},
        token,
      );
      const json = await response.json();

      const queries = getQueryParams();

      // polygonBoundaries value is huge, so do not set the polygonBoundaries to the URL
      const filters = Object.assign({}, values);
      delete filters.polygonBoundaries;
      let locationString = '';
      if (filters.locationName && usePlaceBoundary) {
        locationString = kebabCase(filters.locationName);
      }
      const currentLocation = filters.locationName
        ? `/${locationString}`
        : filters.area
        ? `/${filters.area}`
        : ``;

      const urlQueries = {
        ...queries,
        location: location,
        filters: JSON.stringify(filters),
        view: mapView ? 'map' : 'swiper',
      };

      Router.push(
        {
          pathname: `/search`,
          query: urlQueries,
        },
        {
          pathname: `/search${currentLocation}`,
          query: urlQueries,
        },
      );
      if (response.ok) {
        EventEmitter.emit('showMarker');
        const { placeBoundaries, popularSearchAreas, results } = json;
        dispatch({
          feed: results,
          feedSearch: values,
          messages: [json],
          placeBoundaries,
          popularSearchAreas,
          type: types.FeedFetchSuccess,
        });
        // Trigger the event after dispatch, orelse the component uses old values.
        // On successful request of largePhotoList trigger this event.
        if (fetchLargePhotoList) {
          EventEmitter.emit('largePhotosLoaded');
          EventEmitter.emit('refreshSwiper', results);
        }
        EventEmitter.emit('feedLoaded');
        return;
      } else {
        return dispatch({
          messages: 'No results found.',
          type: types.FeedFetchFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

export function feedFetchedHelper(status: boolean) {
  return (dispatch: Dispatch<any>) =>
    dispatch({
      feedFetched: status,
      type: types.FetchingFeed,
    });
}

export function sortFeedHelper(
  sortBy: string,
  sortAsc: boolean,
  feed: Object[],
) {
  return async (dispatch: any) => {
    let sortedFeed = [];
    let sortByCriteria = '';
    sortedFeed = feed.sort((a: any, b: any) => {
      if (sortAsc) {
        // Sort ascending
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      } else {
        // Sort descending
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        return 0;
      }
    });
    sortByCriteria = `${sortBy}${sortAsc ? 'Asc' : 'Dsc'}`;
    try {
      return dispatch({
        feed,
        sortBy: sortByCriteria,
        type: types.SortedFeed,
      });
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

export function updateFeedSearchHelper(feedSearch: any) {
  return async (dispatch: any) => {
    return dispatch({
      feedSearch,
      type: types.UpdateFeedSearch,
    });
  };
}

export function modifyCityBoundaryHelper(usePlaceBoundary: boolean) {
  return async (dispatch: any) => {
    return dispatch({
      type: types.ModifyCityBoundary,
      usePlaceBoundary,
    });
  };
}

/**
 * Fetch a user's saved searches <br>
 * @param {type} token - A user's authentication token
 */
export function fetchSavedSearches() {
  return async (dispatch: Dispatch<any>, getState: any) => {
    dispatch({ type: types.FetchSavedSearchesRequest });

    const state = getState();
    const token = state.auth?.token;

    try {
      const savedSearchesResponse = await fetchApi(
        endPoints.savedSearches,
        {},
        'get',
        {},
        token,
      );
      const feedSearchJson = await savedSearchesResponse.json();

      if (savedSearchesResponse.ok) {
        dispatch({
          type: types.FetchSavedSearchesSuccess,
          savedSearches: feedSearchJson,
        });
      } else {
        dispatch(
          forceLoginHelper(feedSearchJson, () => {
            dispatch({
              type: types.FetchSavedSearchesFailure,
            });
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Update a user's saved search
 * @param {type} token - A user's authentication token
 */
export function updateSavedSearches(
  searchId: number,
  updateData: {
    saveSearch?: boolean;
    emailNotification?: boolean;
  },
) {
  const { saveSearch, emailNotification } = updateData;

  return async (dispatch: Dispatch<any>, getState: any) => {
    dispatch({
      type: types.UpdateSavedSearchRequest,
      payload: {
        searchId,
        saveSearchLoading: typeof saveSearch === 'boolean',
        emailNotificationLoading: typeof emailNotification === 'boolean',
      },
    });

    const state = getState();

    const token = state.auth?.token;
    const savedSearch = state.feed.savedSearches.savedSearch || [];
    const undoSearch = state.feed.updateSaveSearch.search;

    try {
      const updateSavedSearchResponse = await fetchApi(
        endPoints.updateSavedSearch(searchId),
        updateData,
        'put',
        {},
        token,
      );
      if (updateSavedSearchResponse.ok) {
        const searchIndex = findIndex(savedSearch, ['id', searchId]);
        const search = savedSearch[searchIndex];

        let updatedSaveSearches = { ...state.feed.savedSearches };

        if (typeof emailNotification === 'boolean') {
          updatedSaveSearches.savedSearch = savedSearch.map((search: any) => {
            if (search.id === searchId) {
              return {
                ...search,
                emailNotifications: emailNotification,
              };
            }

            return search;
          });
        }

        if (saveSearch === false) {
          dispatch({
            type: types.OpenUndoSavedSearchToast,
            search: { ...search, searchIndex },
          });
          dispatch({ type: types.CloseDeleteSearchDialog });

          updatedSaveSearches.savedSearch = savedSearch.filter(
            (search: any) => search.id !== searchId,
          );
        }

        if (saveSearch) {
          const searchIndex = undoSearch.searchIndex;
          delete undoSearch.searchIndex;
          updatedSaveSearches.savedSearch.splice(searchIndex, 0, undoSearch);
        }

        dispatch({
          type: types.UpdateSavedSearchSuccess,
          savedSearches: updatedSaveSearches,
        });
      } else {
        const json = await updateSavedSearchResponse.json();
        dispatch(
          forceLoginHelper(json, () => {
            dispatch({
              type: types.UpdateSavedSearchFailure,
            });
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

export const updateFilterSearch = (updatedFilter: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UpdateFilterSearch,
      updatedFilter,
    });
  };
};

export const selectFeedMarker = (lisitingId: string, selectedListing: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.FeedMarkerSelect,
      lisitingId,
      selectedListing,
    });
  };
};
