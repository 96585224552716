import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import track from '../../lib/track';
import moment from 'moment';

import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';
import { updateCta, updateProfile, updateUtm } from '../../actions/auth';
import { initializeStorage, localStore } from '../../lib/storage';
import { fetchFeedSearch } from '../../actions/feed';
import { checkCookies } from '../../lib/helpers';
import { isMobile, isWeb } from '../../lib/getDeviceSize';

const acceptCookiesKey = 'cookieNotificationHidden';
const HEADER_HEIGHT = 70;
// Pages where we want to hide the header, footer, and sidebar
export const HEADER_HIDDEN_ROUTES = [
  '/welcome',
  '/signup/onboarding',
  '/survey',
  '/sidebar-extension',
  '/auth/facebook/callback',
  '/auth/google/callback',
];

export const SIDEBAR_HIDDEN_ROUTES = [
  '/',
  '/welcome',
  '/contact',
  '/careers',
  '/about',
  '/get-preapproved',
  '/signup/onboarding',
  '/search-homes',
  '/survey',
  '/reviews',
  '/unsubscribe/feed-email',
  '/buying-with-torii',
  '/reset/[token]',
  '/get-started',
  '/join',
  '/auth/facebook/callback',
  '/auth/google/callback',
  '/torii-agent/[slug]',
  '/404',
  '/sell',
  '/buy',
  '/tools',
  '/tools/mortgage-calculator',
  '/tools/affordability-calculator',
];
export const FOOTER_HIDDEN_ROUTES = [
  '/welcome',
  '/sidebar-extension',
  '/auth/facebook/callback',
  '/auth/google/callback',
];
export const MOBILE_FOOTER_HIDDEN_ROUTES = [
  '/welcome',
  '/listing/r/[listingId]',
  '/listing/r/[listingId]/[address]',
  '/sidebar-extension',
];

export interface Props {
  children: any;
  dispatch: any;
  feedSearch: any;
  searchHistory: any[];
  token: string;
  router: any;
  user: any;
}

export interface State {
  acceptCookies: boolean;
  collapsed: boolean;
  height: number;
  toggled: boolean;
  hideHeader: boolean;
  hideSidebar: boolean;
  hideFooter: boolean;
  isSignup: boolean;
}

export class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.initialFunctionalities();
    this.state = {
      toggled: false,
      acceptCookies: true,
      collapsed: false,
      height: 500,
      hideHeader: false,
      hideSidebar: false,
      hideFooter: false,
      isSignup: false,
    };
  }

  initialFunctionalities = () => {
    try {
      initializeStorage();
    } catch (error) {
      Sentry.captureException(error);
    }
    this.utmUpdates();
  };

  utmUpdates = () => {
    const { dispatch, router } = this.props;
    // Store the type of landing page the user came in on,
    // so that we can show them that type everywhere
    const {
      app,
      signup,
      utm_campaign,
      utm_medium,
      utm_source,
      utm_term,
    } = router.query;
    if (app) {
      dispatch(updateCta('app'));
    } else if (signup) {
      dispatch(updateCta(''));
    }
    // Store a user's UTM params and the full path they came in with
    // so that we can send them along when that sign up
    dispatch(
      updateUtm(utm_campaign, utm_medium, utm_source, utm_term, router.asPath),
    );
  };

  componentDidMount() {
    this.checkLayoutCondition();
    if (typeof window !== 'undefined') {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
    const { dispatch, searchHistory, token } = this.props;
    if (token && searchHistory.length === 0) {
      dispatch(fetchFeedSearch(token));
    }
    // If the user is logged in, track the current timestamp
    if (token) {
      this.trackLatestWebLogin();
    }
    if (!checkCookies()) {
      if (localStore.getItem(acceptCookiesKey) === null) {
        this.setState({ acceptCookies: false });
      }
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.router.pathname !== this.props.router.pathname) {
      this.checkLayoutCondition();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  trackLatestWebLogin = () => {
    const currentTimeStamp = moment();
    // Updating the last web login of the current user
    this.props.dispatch(
      updateProfile(
        { lastWebLogin: currentTimeStamp },
        this.props.token,
        false,
        true,
      ),
    );
    track.trackLastWebLogin(currentTimeStamp.format('MM/DD/YY hh:mm A'));
  };

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
    this.checkLayoutCondition();
  };

  cookiesConsent = () => {
    this.setState({ acceptCookies: true });
    localStore.setItem(acceptCookiesKey, 'true');
  };

  checkLayoutCondition = () => {
    const { router } = this.props;
    // Do not show layout components for HomeBuzz extension
    if (router.pathname === '/sidebar-extension') {
      return this.setState({
        hideHeader: true,
        hideSidebar: true,
        hideFooter: true,
      });
    }
    if (!isWeb()) {
      this.setState({ hideHeader: false, hideSidebar: false });
    } else {
      this.setState({
        hideHeader: HEADER_HIDDEN_ROUTES.indexOf(router.pathname) >= 0,
        hideSidebar: SIDEBAR_HIDDEN_ROUTES.indexOf(router.pathname) >= 0,
        hideFooter: FOOTER_HIDDEN_ROUTES.indexOf(router.pathname) >= 0,
      });
    }
    // Need to hide footer for mobile devices
    if (isMobile()) {
      this.setState({
        hideFooter: MOBILE_FOOTER_HIDDEN_ROUTES.indexOf(router.pathname) >= 0,
      });
    }
    if (
      router.pathname.includes('signup') ||
      router.pathname.includes('survey')
    ) {
      this.setState({ isSignup: true });
    }
  };

  render() {
    const { children, dispatch, router, token, user } = this.props;
    const {
      acceptCookies,
      height,
      hideFooter,
      hideHeader,
      hideSidebar,
      isSignup,
    } = this.state;
    const contentHeight = height - HEADER_HEIGHT;

    return (
      <div
        id="root"
        className={
          token
            ? 'app-container col-xs-12 row logged-in'
            : 'app-container col-xs-12 row'
        }
      >
        {!hideHeader && <Header token={token} user={user} />}
        <div className="app-content-container col-xs-12">
          {!hideSidebar ? (
            <div className="content">
              <SideBar router={router} height={contentHeight} />
              <div className="content-child">{children}</div>
            </div>
          ) : isSignup ? (
            <div className="content-signup">{children}</div>
          ) : (
            <div className="content">
              <div className="content-child">{children}</div>
            </div>
          )}
        </div>
        {!hideFooter && <Footer dispatch={dispatch} />}
        <div
          className={`row middle-xs space-between-xs bottom-notification ${
            acceptCookies ? 'hidden' : ''
          }`}
        >
          <span>
            Torii uses cookies to help us improve your experience on our site.
            Cookies enable you to enjoy certain site features, social sharing
            functionality, and to tailor messages and display ads to your
            interests. <br className="mobile-only" />
            By continuing to use our site you consent to use our cookies.{' '}
            <div className="spacer mobile-only" />
            To find out more about the cookies we use, please see our{' '}
            <Link href="/legal/privacy">
              <a>Privacy Policy</a>
            </Link>
            .
            <Button
              size="small"
              onClick={this.cookiesConsent}
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)',
                color: '#fff',
                marginLeft: 20,
              }}
            >
              Accept
            </Button>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  feedSearch: state.feed.feedSearch,
  searchHistory: state.feed.searchHistory,
  token: state.auth.token,
  user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(Layout));
