import * as types from '../actions/actionTypes';

const initialSurveyDetails = {
  purpose: '',
  homebuyingKnowledge: '',
  homeType: [],
  state: '',
  city: '',
  maxBudget: undefined,
  noBedrooms: 0,
  noBaths: 0,
  minSquareFeet: 0,
  parking: '',
  importantThings: [],
  homeStyle: [],
  timeFrame: '',
  hasOtherAgent: '',
  preapproved: '',
  neighborhoodAmenities: [],
  commuteType: [],
  soundPreferences: '',
  lifeStyle: '',
  importantRoom: '',
  pets: undefined,
  favoriteHomeActivites: [],
  favoriteOutdoorActivities: [],
};

const initialState = {
  signupSurveyTouched: false,
  signupSurveyCompleted: false,
  showSurveyLoader: false,
  surveyFetchLoader: false,
  signupSurveyDetails: {
    ...initialSurveyDetails,
  },
  errorMessage: '',
};

export default function searchSurvey(state: any = initialState, action: any) {
  switch (action.type) {
    case types.ToggleSurveyLoader:
      return Object.assign({}, state, {
        showSurveyLoader: action.showSurveyLoader,
      });
    case types.ToggleSurveyFetchLoader:
      return Object.assign({}, state, {
        surveyFetchLoader: action.surveyFetchLoader,
      });

    case types.UpdateSignupSurvey:
      return Object.assign({}, state, {
        ...state,
        signupSurveyTouched: true,
        signupSurveyDetails: {
          ...state.signupSurveyDetails,
          ...action.values,
        },
      });
    case types.UpdateSignupSurveySuccess:
      return Object.assign({}, state, {
        ...state,
        signupSurveyCompleted: true,
      });
    case types.UpdateSignupSurveyFailure:
      return Object.assign({}, state, {
        ...state,
        signupSurveyDetails: {
          ...initialSurveyDetails,
        },
        errorMessage: action.message,
      });
    case types.SignupSurveyFetchSuccess:
      const { values } = action;
      return Object.assign({}, state, {
        surveyFetchLoader: false,
        signupSurveyDetails: {
          purpose: values.purpose ? values.purpose : '',
          homebuyingKnowledge: values.homebuyingKnowledge
            ? values.homebuyingKnowledge
            : '',
          homeType: values.homeType ? values.homeType : [],
          state: values.state ? values.state : '',
          city: values.city ? values.city : '',
          maxBudget: values.maxBudget ? values.maxBudget : 0,
          noBedrooms: values.noBedrooms ? values.noBedrooms : 0,
          noBaths: values.noBaths ? values.noBaths : 0,
          minSquareFeet: values.minSquareFeet ? values.minSquareFeet : 0,
          parking: values.parking ? values.parking : '',
          importantThings: values.importantThings ? values.importantThings : [],
          homeStyle: values.homeStyle ? values.homeStyle : [],
          timeFrame: values.timeFrame ? values.timeFrame : '',
          hasOtherAgent: values.hasOtherAgent ? values.hasOtherAgent : '',
          preapproved: values.preapproved ? values.preapproved : '',
          neighborhoodAmenities: values.neighborhoodAmenities
            ? values.neighborhoodAmenities
            : [],
          commuteType: values.commuteType ? values.commuteType : [],
          soundPreferences: values.soundPreferences
            ? values.soundPreferences
            : '',
          lifeStyle: values.lifeStyle ? values.lifeStyle : '',
          importantRoom: values.importantRoom ? values.importantRoom : '',
          favoriteHomeActivites: values.favoriteHomeActivites
            ? values.favoriteHomeActivites
            : [],
          favoriteOutdoorActivities: values.favoriteOutdoorActivities
            ? values.favoriteOutdoorActivities
            : [],
          pets: values.pets ? values.pets : undefined,
        },
        signupSurveyCompleted: true,
      });
    case types.SignupSurveyFetchFailure:
      return Object.assign({}, state, {
        ...state,
        surveyFetchLoader: false,
        errorMessage: action.message,
      });

    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
