import * as React from 'react';
import { Dispatch } from 'redux';
import Router from 'next/router';
import { Form, Field } from 'react-final-form';
import { createStyles, withStyles } from '@material-ui/core/styles';

import * as types from '../../../actions/actionTypes';
import SearchInput from '../../utility/SearchInput';
import { formatUrlAddress } from '../../../lib/helpers';
import { isMobile, isTablet } from '../../../lib/getDeviceSize';
import { MagnifyingGlass } from 'phosphor-react';

const styles = createStyles({
  icon: {
    color: '#443461',
  },
  paper: {
    marginTop: 5,
    marginLeft: -40,
    overflow: 'hidden',
    position: 'absolute',
    width: isTablet() ? 450 : 500,
    zIndex: 50,
  },
  paperSmall: {
    marginTop: 5,
    marginLeft: isMobile() ? -105 : -150,
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    zIndex: 50,
  },
});

export interface Props {
  classes: any;
  dispatch: any;
}

export interface State {
  id: number | undefined;
  width: number;
}

export class HeaderSearch extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: undefined,
      width: 0,
    };
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  setId = (id: number) => {
    this.setState({ id });
  };

  handleSubmit = (id: number, address: string, isListing: boolean) => {
    if (isListing) {
      Router.push(`/listing/r/${id}/${formatUrlAddress(null, address)}`);
    }
    this.props.dispatch({ type: types.HeaderSearchComplete });
  };

  render() {
    const { classes } = this.props;
    const { width } = this.state;
    const mobile = width <= 529;
    return (
      <Form
        onSubmit={() => this.handleSubmit}
        render={({ handleSubmit, valid }) => (
          <form className="header-search">
            <div className="row middle-xs header-search-container">
              <div className="header-search-icon">
                <MagnifyingGlass color="#443461" size={25} />
              </div>
              <div className="header-search-input">
                <Field
                  allowPlaceSearch
                  component={SearchInput}
                  inputClasses={{}}
                  additionalProps={{
                    classes: {},
                    fullWidth: true,
                    id: 'headerSearch',
                    label: '',
                    name: 'searchListings',
                    placeholder: mobile
                      ? 'Enter a place, address…'
                      : 'Enter a place, address, city, or zip code',
                    required: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name="searchListings"
                  onInputBlur={() => null}
                  onInputFocus={() => null}
                  paperStyles={mobile ? styles.paperSmall : styles.paper}
                  style={{ width: '100%' }}
                  results={(result: any, isListing: boolean) => {
                    if (
                      !this.state.id ||
                      (result.id && this.state.id !== result.id)
                    ) {
                      this.setId(result.id);
                    }
                    this.handleSubmit(result.id, result.address, isListing);
                  }}
                />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

export default withStyles(styles)(HeaderSearch);
