import { safeStorage } from './safeStorage';
import { checkCookies } from './helpers';

export const local = safeStorage(() => localStorage);
// If necessary we can use sessionStorage in the same manner
// export const session = safeStorage(() => sessionStorage);

export let localStore = local;
export function initializeStorage() {
  // Check if the browser allows localStorage
  // If not, use our safeStorage
  if (checkCookies()) {
    localStore = localStorage;
  }
}

export const storageKeys = {
  homeVisitDate: 'homeVisitDate',
  noOfRandomHomeVisits: 'noOfRandomHomeVisits',
  noOfSearches: 'noOfSearches',
};
