const styles: any = {
  newTag: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  newTagContainer: {
    alignItems: 'center',
    backgroundColor: '#e2658c',
    borderColor: '#e2658c',
    borderRadius: 15,
    display: 'flex',
    height: 25,
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 15,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

export default styles;
