import * as types from '../actions/actionTypes';

const initialState = {
  authLoading: false,
  cta: null,
  eListSubscriptionEnabled: false,
  hydrated: false,
  postAuthPath: '/dashboard',
  requestedLenderIntro: false,
  signupPath: '',
  signupDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    introductoryActivity: '',
    introduceReferralAgent: false,
    state: '',
    city: '',
  },
  entrancePath: '',
  token: null,
  user: {},
  utmCampaign: '',
  utmMedium: '',
  utmSource: '',
  utmTerm: '',
  deleteLoading: false,
  selectedActivity: null,
  signupProgress: {
    intro: 0,
    info: {
      basics: 0,
      neighborhood: 0,
      lifestyle: 0,
    },
  },
};

/**
 * State management for authentication
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function auth(state: any = initialState, action: any) {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }
  switch (action.type) {
    case types.AuthenticationLoading:
      return Object.assign({}, state, {
        authLoading: action.authLoading,
      });
    case types.FeedEmailOptOutSuccess:
      return Object.assign({}, state, {
        user: action.user,
      });
    case types.LoginSuccess:
    case types.OauthLoginSuccess:
      return Object.assign({}, state, {
        authLoading: false,
        cta: null,
        token: action.token,
        user: action.user,
      });
    case types.OpenLoginDialog:
      return Object.assign({}, state, {
        postAuthPath: action.postAuthPath,
      });
    case types.OpenSignupDialog:
      return Object.assign({}, state, {
        postAuthPath: action.postAuthPath,
      });
    case types.EListSubscriptionSuccess:
      return Object.assign({}, state, {
        eListSubscriptionEnabled: action.subscribed,
      });
    case types.UpdateSignupDetails:
      return Object.assign({}, state, {
        signupDetails: {
          ...state.signupDetails,
          ...action.details,
        },
      });
    case types.SignupSuccess:
    case types.SignUpNpSuccess:
    case types.SignUpNewSuccess:
    case types.SignupSidebarSuccess:
    case types.OauthSignupSuccess:
      return Object.assign({}, state, {
        authLoading: false,
        cta: null,
        signupPath: action.signupPath,
        entrancePath: action.entrancePath,
        token: action.token,
        user: action.user,
      });
    case types.SignUpNpFailure:
    case types.SignupFailure:
    case types.SignUpNewFailure:
    case types.LoginFailure:
    case types.OauthFailure:
      return Object.assign({}, state, {
        authLoading: false,
      });
    case types.OnboardingProfileSuccess:
    case types.UpdateProfileSuccess:
    case types.VerifyEmailSuccess:
    case types.VerifyPhoneSuccess:
      return Object.assign({}, state, {
        user: action.user,
      });
    case types.UpdateCta:
      return Object.assign({}, state, {
        cta: action.cta,
      });
    case types.UpdateUtm:
      return Object.assign({}, state, {
        utmCampaign: action.utmCampaign,
        utmMedium: action.utmMedium,
        utmSource: action.utmSource,
        utmTerm: action.utmTerm,
        entrancePath: action.entrancePath,
      });
    case types.UpdateSignupProgress:
      return Object.assign({}, state, {
        signupProgress: {
          ...state.signupProgress,
          ...action.progress,
        },
      });
    case types.RequestedLenderIntro:
      return Object.assign({}, state, {
        requestedLenderIntro: true,
      });
    case types.DeleteAccountLoading:
      return Object.assign({}, state, {
        deleteLoading: action.deleteLoading,
      });
    case types.DeleteAccountSuccess:
      return Object.assign({}, state, {
        deleteLoading: false,
      });
    case types.SetSignupPath:
      return Object.assign({}, state, {
        signupPath: action.signupPath || '/',
      });
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
