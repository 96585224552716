import * as types from '../actions/actionTypes';

const initialState = {
  comps: [],
  compsLoading: false,
  relatedListings: [],
  relatedListingsLoading: false,
};

/**
 * State management for data related to comps
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function comps(state: any = initialState, action: any) {
  switch (action.type) {
    case types.CompsFetchFailure:
      return Object.assign({}, state, {
        comps: [],
        compsLoading: false,
      });
    case types.CompsFetchStart:
      return Object.assign({}, state, {
        compsLoading: true,
      });
    case types.CompsFetchSuccess:
      return Object.assign({}, state, {
        comps: action.comps,
        compsLoading: false,
      });
    case types.RelatedListingsFetchFailure:
      return Object.assign({}, state, {
        relatedListings: [],
        relatedListingsLoading: false,
      });
    case types.RelatedListingsFetchStart:
      return Object.assign({}, state, {
        relatedListingsLoading: true,
      });
    case types.RelatedListingsFetchSuccess:
      return Object.assign({}, state, {
        relatedListings: action.relatedListings,
        relatedListingsLoading: false,
      });
    case types.ClearComps:
    case types.ClearListings:
      return initialState;
    default:
      return state;
  }
}
