import moment from 'moment';

export const TOKEN_EXPIRATION_DAYS = 365;

export const HOMEBUZZ_LINK =
  'https://chrome.google.com/webstore/detail/homebuzz-comment-on-real/gokijpcjnbdpolblfkbpgcjocmjlnopa';

export const REALREPORTS_LINK =
  'https://www.realreports.ai?utm_source=toriihomes&utm_medium=tools';

export const schedulingLink = 'https://calendly.com/saadtorii';

export const agentTypeformLink = 'https://form.typeform.com/to/YqdoRSFS';

// Constants for calculating ClosingCost
export const lendersTitleRatio = 0.00275;
export const ownersFixedAddition = 175;
export const borrowersTitleRatio = 0.004015;
export const brokerFeePercentage = 0.025;
export const TORII_MINIMUM = 6000;
// JR 9/16/21: we're going to try removing the minimum price to increase volume
export const MINIMUM_SUPPORTED_PRICE = 0;

export enum ListingStatus {
  Active = 'Active',
  New = 'New',
  Extended = 'Extended',
  PriceChanged = 'Price Changed',
  BackOnMarket = 'Back on Market',
  ComingSoon = 'Coming Soon',
  Contingent = 'Contingent',
  Pending = 'Pending',
  Rented = 'Rented',
  Sold = 'Sold',
  Expired = 'Expired',
  Canceled = 'Canceled',
  TemporarilyWithdrawn = 'Temporarily Withdrawn',
}

export const locations: any = {
  MA: 'Massachusetts',
};

export interface LocationDetailType {
  id: number;
  locationName: string;
  lat: number;
  lng: number;
}

export const MARKET_LOCATIONS = [
  'Massachusetts - Greater Boston Area',
  'Massachusetts - Other',
  'Other',
];

export const STATES_LIST: any = {
  AL: 'Alabama',
  // tslint:disable-next-line: object-literal-sort-keys
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  DC: 'Washington D.C.',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const STATE_POSTAL_CODES: any = {
  alabama: 'AL',
  alaska: 'AK',
  arizona: 'AZ',
  arkansas: 'AR',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  delaware: 'DE',
  florida: 'FL',
  georgia: 'GA',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  pennsylvania: 'PA',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  vermont: 'VT',
  virginia: 'VA',
  washington: 'WA',
  'washington d.c.': 'DC',
  'west virginia': 'WV',
  wisconsin: 'WI',
  wyoming: 'WY',
};

export const TIME_FRAME_TYPES: any = {
  ACTIVELY_LOOKING: 'Actively looking',
  LESS_THAN_SIX_MONTHS: '< 6 months',
  SIX_TO_TWELVE_MONTHS: '6 - 12 months',
  MORE_THAN_ONE_YEAR: '1 year +',
  LOOKING_TO_SELL: 'Looking to sell',
  LOOKING_TO_BUY_AND_SELL: 'Looking to buy and sell',
  NOT_LOOKING_TO_BUY: 'Not looking to buy',
};

export interface SchoolSearchParams {
  lat?: number;
  lon?: number;
  school_type?: string;
  level_codes?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export const FRIENDLY_URL_AREA: any = {
  'boston-ma': {
    location: '42.3600825,-71.05888010000001',
    locationName: 'Boston, MA, USA',
  },
  'san-francisco-ca': {
    location: '37.774929499999985,-122.4194155',
    locationName: 'San Francisco, CA, USA',
  },
  'los-angeles-ca': {
    location: '34.05223419999999,-118.2436849',
    locationName: 'Los Angeles, CA, USA',
  },
};

export const DONUT_COLORS = {
  noRating: { indicatorColor: '#E2E2E2', trackColor: '#E2E2E2' },
  poor: { indicatorColor: '#E31515', trackColor: '#FBD0D0' },
  average: { indicatorColor: '#E3AA15', trackColor: '#FBEFD0' },
  good: { indicatorColor: '#159C18', trackColor: '#D8FBD0' },

  red: { indicatorColor: '#E31515', trackColor: '#FBD0D0' },
  lightRed: { indicatorColor: '#E15F58', trackColor: '#F7D6D4' },
  darkRed: { indicatorColor: '#A50026', trackColor: '#FFCCD8' },

  orange: { indicatorColor: '#F3A358', trackColor: '#FDE4CE' },
  lightOrange: { indicatorColor: '#FDAE61', trackColor: '#FEE5CD' },
  darkOrange: { indicatorColor: '#F46D43', trackColor: '#FCDACF' },

  blue: { indicatorColor: '#0023CB', trackColor: '#D0E2FB' },
  lightBlue: { indicatorColor: '#3481F0', trackColor: '#D0E2FB' },
};

export const SUPPORTED_LISTINGS_PAGES = [
  { source: 'torii', path: 'localhost:3001/listing/r/' },
  { source: 'zillow', path: 'zillow.com/homedetails/' },
  { source: 'torii', path: 'toriihomes.com/listing/r/' },
  { source: 'redfin', path: '/home/' },
  { source: 'realtor', path: 'realestateandhomes-detail/' },
];

// Room types to sort images
export const ROOM_TYPES = [
  { value: 'bathroom', label: 'Bathroom' },
  { value: 'bedroom', label: 'Bedroom' },
  { value: 'dining_area', label: 'Dining Room' },
  { value: 'home_exterior', label: 'Home Exterior' },
  { value: 'kitchen', label: 'Kitchen' },
  { value: 'living_room', label: 'Living Room' },
  { value: 'outdoor_space', label: 'Outdoor Space' },
];

const today = moment().format('MM/DD/YYYY');
const currentYear = moment().format('YYYY');
export const COPYRIGHT_TEXT = {
  bareis: `Listing information deemed reliable but not guaranteed. Copyright ${currentYear} Bay Area Real Estate Information Services, Inc. All Right Reserved.`,
  bright_mls: `Copyright © ${currentYear} BRIGHT, All Rights Reserved. Information Deemed Reliable But Not Guaranteed. The information provided by this website is for the personal, non-commercial use of consumers and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. The data relating to real estate for sale on this website appears in part through the BRIGHT Internet Data Exchange program, a voluntary cooperative exchange of property listing data between licensed real estate brokerage firms in which Homesavey Real Estate participates, and is provided by BRIGHT through a licensing agreement. Data last updated: ${today} Some properties which appear for sale on this website may no longer be available because they are under contract, have Closed or are no longer being offered for sale. Some real estate firms do not participate in IDX and their listings do not appear on this website. Some properties listed with participating firms do not appear on this website at the request of the seller.`,
  calrets: `Based on information from the MLSListings MLS as of ${today}. All data, including all measurements and calculations of area, is obtained from various sources and has not been, and will not be, verified by broker or MLS. All information should be independently reviewed and verified for accuracy. Properties may or may not be listed by the office/agent presenting the information.`,
  crmls: `Based on information from California Regional Multiple Listing Service, Inc. as of ${today}. All data, including all measurements and calculations of area, is obtained from various sources and has not been, and will not be, verified by broker or MLS. All information should be independently reviewed and verified for accuracy. Properties may or may not be listed by the office/agent presenting the information.`,
  mls_pin: `The property listing data and information, or the images, set forth herein were provided to MLS Property Information Network, Inc. from third party sources, including sellers, lessors, landlords and public records, and were compiled by MLS Property Information Network, Inc. The property listing data and information, and the images, are for the personal, non-commercial use of consumers having a good faith interest in purchasing, leasing or renting listed properties of the type displayed to them and may not be used for any purpose other than to identify prospective properties which such consumers may have a good faith interest in purchasing, leasing or renting. MLS Property Information Network, Inc. and its subscribers disclaim any and all representations and warranties as to the accuracy of the property listing data and information, or as to the accuracy of any of the images, set forth herein. Data last updated on ${today}.`,
  neren: `Copyright ${currentYear} New England Real Estate Network, Inc. All rights reserved. This information is deemed reliable, but not guaranteed. The data relating to real estate displayed on this Site comes in part from the IDX Program of NEREN. The information being provided is for consumers’ personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Data last updated ${today}`,
  sfar: `Listings on this page identified as belonging to another listing firm are based upon data obtained from the SFAR MLS, which data is copyrighted by the San Francisco Association of REALTORS®, but is not warranted. All information deemed reliable but not guaranteed and should be independently verified. All properties are subject to prior sale, change or withdrawal. Neither listing broker(s), nor Torii, Inc. shall be responsible for any typographical errors, misinformation, misprints and shall be held totally harmless. Data last updated on ${today}.`,
};

// Activities on sign-up.
export const activities = [
  { label: 'Get coffee ☕️', value: 'coffee' },
  { label: 'Grab drinks 🍻', value: 'drink' },
  { label: 'Eat a meal 🍝', value: 'food' },
  { label: 'Get outside 🏌', value: 'sports' },
  { label: 'Just text first 🤳', value: 'text' },
];

// Intent on sign-up.
export const intent = [
  { label: 'Looking to buy a home', value: 'Looking to buy' },
  { label: 'Looking to sell my home', value: 'Looking to sell' },
  { label: 'Just browsing', value: 'Just browsing' },
];

// Buying-knowledge on sign-up.
export const buyingKnowledge = [
  { label: 'I’ve bought a house before', value: 'Bought a house before' },
  { label: 'I’ve done some research', value: 'Done some research' },
  { label: 'I’m new to home buying', value: 'New to home buying' },
];

// Property type
export const propertyTypeOptions = [
  { label: 'Single-family', value: 'Single-Family' },
  { label: 'Multi-family', value: 'Multi-Family' },
  { label: 'Condo/Townhouse', value: 'Condo' },
];

// Parking type
export const parkingOptions = [
  { label: 'Off-Street Parking', value: 'Street Parking' },
  { label: 'Garage', value: 'Garage' },
  { label: `Don’t need parking`, value: `Don’t need parking` },
];

// Features you'd like to have
export const importantThingsOptions = [
  { label: 'Basement', value: 'Basement' },
  { label: 'Central air', value: 'Central air' },
  { label: 'High ceilings', value: 'High ceilings' },
  { label: 'Natural lighting', value: 'Natural lighting' },
  { label: 'Outdoor space', value: 'Outdoor space' },
  { label: 'Pool', value: 'Pool' },
  { label: 'Wheelchair accessible', value: 'Wheelchair accessible' },
];

// Favorite home types
export const homeStyleOptions = [
  {
    label: 'Bungalow',
    value: 'Bungalow',
    slug: 'bungalow',
  },
  {
    label: 'Colonial',
    value: 'Colonial',
    slug: 'colonial',
  },
  {
    label: 'Contemporary',
    value: 'Contemporary',
    slug: 'contemporary',
  },
  {
    label: 'Farmhouse',
    value: 'Farmhouse',
    slug: 'farmhouse',
  },
  {
    label: 'Mid-Century',
    value: 'Mid-Century',
    slug: 'midCentury',
  },
  {
    label: 'Modern',
    value: 'Modern',
    slug: 'modern',
  },
  {
    label: 'Traditional',
    value: 'Traditional',
    slug: 'traditional',
  },
  {
    label: 'Tudor',
    value: 'Tudor',
    slug: 'tudor',
  },
  {
    label: 'Victorian',
    value: 'Victorian',
    slug: 'victorian',
  },
];

// Timeframe
export const timeFrameOptions = [
  { label: 'Actively Looking', value: 'Actively looking' },
  { label: 'Less than 6 months', value: '< 6 months' },
  { label: '6-12 months', value: '6 - 12 months' },
  { label: '1+ year', value: '1 year +' },
];

export const agentOptions = [
  { label: 'No', value: 'No' },
  { label: 'Yes', value: 'Yes' },
  {
    label: 'Yes, but interested in switching to Torii',
    value: 'Yes, but interested in switching to Torii',
  },
];

export const preapprovedOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'Buying in cash', value: 'Buying in cash' },
];

export const neighborhoodAmenitiesOptions = [
  { label: 'Bars', value: 'Bars' },
  { label: 'Coffee shops', value: 'Coffee shops' },
  { label: `Farmer’s market`, value: `Farmer’s market` },
  { label: 'Grocery stores', value: 'Grocery stores' },
  { label: 'Gyms', value: 'Gyms' },
  { label: 'Park', value: 'Park' },
  { label: 'Music venues', value: 'Music venues' },
  { label: 'Restaurants', value: 'Restaurants' },
  { label: 'Shopping', value: 'Shopping' },
];

export const commuteOptions = [
  { label: 'I work from home', value: 'I work from home' },
  { label: 'Car', value: 'Car' },
  { label: 'Public transportation', value: 'Public transportation' },
  { label: 'Bike', value: 'Bike' },
  { label: 'Walk', value: 'Walk' },
];

export const soundOptions = [
  { label: 'I like my peace and quiet', value: 'Little noise as possible' },
  { label: 'Some noise is okay', value: 'Some noise is okay' },
  { label: `Noise doesn't bother me`, value: `Noise doesn't bother me` },
];

export const lifeStyles = [
  { label: 'A homebody', value: 'A homebody' },
  { label: 'Social butterfly', value: 'Social butterfly' },
  { label: 'I’m up for anything', value: 'I’m up for anything' },
];

export const importantRoomOptions = [
  { label: 'Bathroom', value: 'Bathroom' },
  { label: 'Bedroom', value: 'Bedroom' },
  { label: 'Dining Room', value: 'Dining Room' },
  { label: 'Kitchen', value: 'Kitchen' },
  { label: 'Living Room', value: 'Living Room' },
];

export const petOptions = [
  { label: 'Dog', value: 'Dog' },
  { label: 'Cat', value: 'Cat' },
  { label: 'Bird', value: 'Bird' },
  { label: 'Other', value: 'Other' },
];

export const indoorActivityOptions = [
  { label: 'Arts & crafts', value: 'Arts & crafts' },
  { label: 'Cook', value: 'Cook' },
  { label: 'Do nothing', value: 'Do nothing' },
  { label: 'Entertain ', value: 'Entertain ' },
  { label: 'Garden', value: 'Garden' },
  { label: 'Hang with loved ones', value: 'Hang with loved ones' },
  { label: 'Listen to music', value: 'Listen to music' },
  { label: 'Meditate', value: 'Meditate' },
  { label: 'Order delivery', value: 'Order delivery' },
  { label: 'Play music', value: 'Play music' },
  { label: 'Read', value: 'Read' },
  { label: 'Relax', value: 'Relax' },
  { label: 'Surf the internet', value: 'Surf the internet' },
  { label: 'Video games', value: 'Video games' },
  { label: 'Watch TV/Movies', value: 'Watch TV/Movies' },
];

export const outdoorActivityOptions = [
  { label: 'Attend concerts', value: 'Attend concerts' },
  { label: 'Local events', value: 'Local events' },
  { label: 'Brunch', value: 'Brunch' },
  { label: 'Dance', value: 'Dance' },
  { label: 'Eat at restaurants', value: 'Eat at restaurants' },
  { label: 'Go to bars', value: 'Go to bars' },
  { label: 'Go to classes', value: 'Go to classes' },
  { label: 'Go to coffee shops', value: 'Go to coffee shops' },
  { label: 'Hang with friends', value: 'Hang with friends' },
  { label: 'Hike', value: 'Hike' },
  { label: 'Outdoor things', value: 'Outdoor things' },
  { label: 'See movies', value: 'See movies' },
  { label: 'Shop', value: 'Shop' },
  { label: 'Sports', value: 'Sports' },
  { label: 'Volunteer', value: 'Volunteer' },
  { label: 'Workout', value: 'Workout' },
  { label: 'Travel', value: 'Travel' },
];

// Reviews
export const reviews = [
  {
    comment:
      '“What an incredible experience. Torii went above and beyond for us!”',
    id: 1,
    img: '/static/img/home/reviews/prop_1.jpg',
    name: 'Eugene & Steve',
    savings: 6169,
  },
  {
    comment:
      '“This was my first time buying a house and Torii made everything so easy.”',
    id: 2,
    img: '/static/img/home/reviews/prop_2.jpg',
    name: 'Chris',
    savings: 5220,
  },
  {
    comment: '“Torii is the real deal. Believe the hype!”',
    id: 3,
    img: '/static/img/home/reviews/prop_6.jpg',
    name: 'Ashley & Pete',
    savings: 6185,
  },
  {
    comment: '“This. Is. It. Torii is the future of real estate.”',
    id: 4,
    img: '/static/img/home/reviews/prop_4.jpg',
    name: 'Autumn & Jeff',
    savings: 5845,
  },
  {
    comment:
      '“We canʼt recommend Torii highly enough! Every expectation we had was completely blown away.”',
    id: 5,
    img: '/static/img/home/reviews/prop_3.jpg',
    name: 'Jenn & Mike',
    savings: 12254,
  },
  {
    comment:
      '“Torii really is the easiest way to buy a home! An absolute pleasure to work with.”',
    id: 6,
    img: '/static/img/home/reviews/saadia.png',
    name: 'Saadia',
    savings: 7537,
  },
  {
    comment: '“Everything was easy and hassle-free with Torii.”',
    id: 7,
    img: '/static/img/home/reviews/prop_5.jpg',
    name: 'Chris & Anna',
    savings: 8483,
  },
  {
    comment:
      '“The entire Torii team is very flexible, responsive, informative, attentive, and personal.”',
    id: 8,
    img: '/static/img/home/reviews/prop_7.jpg',
    name: 'Justin',
    savings: 6445,
  },
  {
    comment:
      '“As first time home-buyers, we couldnʼt have asked for a better experience with Torii.”',
    id: 9,
    img: '/static/img/home/reviews/prop_8.jpg',
    name: 'Jack & Casey',
    savings: 5550,
  },
  {
    comment: '“HIGHLY RECOMMEND working with Torii!”',
    id: 10,
    img: '/static/img/home/reviews/prop_9.jpg',
    name: 'Chris & Kristina',
    savings: 6162,
  },
  {
    comment: '“Working with Torii was absolutely amazing!”',
    id: 11,
    img: '/static/img/home/reviews/prop_10.jpg',
    name: 'Tom & Jackie',
    savings: 6400,
  },
  {
    comment:
      '“We absolutely loved working with the Torii team. Highly recommend!”',
    id: 12,
    img: '/static/img/home/reviews/prop_11.jpg',
    name: 'Sam & Andrew',
    savings: 6359,
  },
  {
    comment:
      '“We would highly recommend Torii—it made our first purchase incredibly smooth and successful!”',
    id: 13,
    img: '/static/img/home/reviews/prop_12.jpg',
    name: 'Laura & Jon',
    savings: 5861,
  },
  {
    comment: '“We absolutely loved working with Torii!”',
    id: 14,
    img: '/static/img/home/reviews/prop_13.jpg',
    name: 'Perri',
    savings: 6185,
  },
  {
    comment:
      '“Torii took care of everything and made a very complicated process smooth and effortless.”',
    id: 15,
    img: '/static/img/home/reviews/prop_14.jpg',
    name: 'Molly & Matt',
    savings: 6144,
  },
  {
    comment: '“Iʼm extremely satisfied with Torii and would highly recommend.”',
    id: 16,
    img: '/static/img/home/reviews/prop_15.jpg',
    name: 'Keith',
    savings: 4999,
  },

  {
    comment: '“Torii was incredible to work with!”',
    id: 17,
    img: '/static/img/home/reviews/aa@2x.jpg',
    name: 'Arlen & Andrew',
    savings: 8192,
  },
  {
    comment:
      '“Torii made my home buying journey easier than I ever could have imagined!”',
    id: 18,
    img: '/static/img/home/reviews/c@2x.jpg',
    name: 'Christina',
    savings: 6405,
  },
  {
    comment:
      '“Torii went above and beyond to make the home buying process as smooth and seamless as possible”',
    id: 19,
    img: '/static/img/home/reviews/cj@2x.jpg',
    name: 'Charles & Jenna',
    savings: 4575,
  },
];

// Pages where the sidebar will be collapsed
export const PAGES_WITH_COLLAPSED_SIDEBAR = ['/search', '/search/[area]'];
