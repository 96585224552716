const clientId = 'a2Q9d49j6gMPc6L3qGq742JGudEUaLMh';
const apiBase = '/v1';
const apiPort = '3000';

// web - https://dev.toriihomes.com/
// api - https://dev-api.toriihomes.com/
export default async function fetchApi(
  endPoint: string,
  payload?: {} | undefined,
  method: string = 'get',
  headers?: {},
  accessToken?: string,
  initialHost?: string,
) {
  let path = `${apiBase}${endPoint}`;
  // Check for the window object, since it won't exist on the server
  if (typeof window !== 'undefined') {
    let hostname = '';
    if (window.location.hostname.indexOf('localhost') >= 0) {
      hostname = window.location.hostname;
    } else if (window.location.hostname.indexOf('dev.toriihomes') >= 0) {
      hostname = 'dev-api.toriihomes.com';
    } else if (window.location.hostname.indexOf('staging') >= 0) {
      hostname = 'api-staging.toriihomes.com';
    } else {
      hostname = 'api.toriihomes.com';
    }
    path = `${window.location.protocol}//${hostname}${
      window.location.port ? `:${apiPort}` : ''
    }${path}`;
  } else {
    if (initialHost) {
      if (initialHost.indexOf('localhost') >= 0) {
        path = `http://localhost:${apiPort}${path}`;
      } else if (initialHost.indexOf('dev.toriihomes') >= 0) {
        path = `https://dev-api.toriihomes.com${path}`;
      } else if (initialHost.indexOf('staging') >= 0) {
        path = `https://api-staging.toriihomes.com${path}`;
      } else {
        path = `https://api.toriihomes.com${path}`;
      }
    }
  }
  const headersObject: any = {
    'Client-ID': clientId,
    'Content-Type': 'application/json',
    ...headers,
  };
  if (accessToken) {
    headersObject.Authorization = `Bearer ${accessToken}`;
  }
  let request = {};
  if (method.toLowerCase() === 'post' || method.toLowerCase() === 'put') {
    request = {
      body: JSON.stringify(payload),
      headers: headersObject,
      method: method.toLowerCase(),
    };
  } else {
    request = {
      headers: headersObject,
      method: method.toLowerCase(),
    };
  }

  try {
    return fetch(path, request);
  } catch (e) {
    const stringError = e && e.toString && e.toString();
    const type =
      stringError === 'TypeError: Network request failed'
        ? 'networkError'
        : 'unknown';
    const error = {
      text: stringError,
      type,
    };
    throw error;
  }
}
