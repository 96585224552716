import { Dispatch } from 'redux';
import Router from 'next/router';
import cookie from 'js-cookie';

import { TOKEN_EXPIRATION_DAYS } from '../lib/constants';
import { parsePhoneNumber } from '../lib/helpers';
import fetchApi from '../services/api';
import { fetchFeedSearch } from './feed';
import track from '../lib/track';
import * as types from './actionTypes';
import { localStore, storageKeys } from '../lib/storage';
import { fetchSearchSurvey } from './searchSurvey';
import { fetchSignupSurvey, saveSurveyValues } from './signupSurvey';
import EventEmitter from '../lib/eventEmitter';
import { getOrCreateStore } from '../lib/with-redux-store';
import moment from 'moment';
import { disableQuickStartGuide, sendVerification } from './dashboard';
import {
  toggleEmailVerificationPrompt,
  toggleEmailVerifyModal,
  togglePhoneVerificationPrompt,
  togglePhoneVerifyModal,
} from './dashboard';

export const STANDARD_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export function emitNotificationEvent(
  responseStatus: 'success' | 'error',
  responseMessage: string,
) {
  return EventEmitter.emit('showNotification', {
    responseMessage,
    responseStatus,
  });
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function openSignupDialog(postAuthPath?: string) {
  return {
    postAuthPath,
    type: types.OpenSignupDialog,
  };
}

export function openLoginDialog(postAuthPath?: string) {
  return {
    postAuthPath,
    type: types.OpenLoginDialog,
  };
}

export function storeSignupPath(signupPath: string | string[]) {
  return {
    signupPath,
    type: types.SetSignupPath,
  };
}

/**
 * If the account is flagged to delete and user tried to do any updates from an existing tab [logged in]
 * we logout them, so they login again to re-activate the account
 * @param jsonObject - Error response value
 * @param callback - Callback function
 * @returns
 */
export function forceLoginHelper(jsonObject: any, callback: Function) {
  return async (dispatch: Dispatch<any>) => {
    try {
      // Detect forceLogin property and navigate them to homepage
      if (jsonObject && jsonObject.forceLogin) {
        emitNotificationEvent(
          'error',
          'Your account has been deactivated. Please log in again to reactivate',
        );
        setTimeout(() => {
          dispatch(logout());
          Router.replace('/');
        }, 1000);
      } else {
        callback();
      }
    } catch (error) {
      emitNotificationEvent('error', STANDARD_ERROR_MESSAGE);
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Allow a user to login to their Torii account.
 * @param {Object} values - Values sent from the form
 * @param {string} postAuthPath - Where to send a user after they login
 */
export function login(values: {}, postAuthPath?: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi('/login', values, 'post');
      const json = await response.json();
      if (response.ok) {
        const { token, user } = json;
        dispatch({
          token,
          type: types.LoginSuccess,
          user,
        });
        cookie.set('token', token, {
          expires: TOKEN_EXPIRATION_DAYS,
        });
        track.login(
          user.firstName,
          user.lastName,
          user.email,
          user.phoneNumber,
        );
        localStore.setItem('token', token);
        // If a user is logged in, we no longer need to track their search count
        localStore.removeItem(storageKeys.noOfRandomHomeVisits);
        localStore.removeItem(storageKeys.noOfSearches);
        localStore.removeItem(storageKeys.homeVisitDate);
        if (postAuthPath) {
          Router.push(postAuthPath);
        }
        dispatch(fetchFeedSearch(token));
        dispatch(fetchSearchSurvey(token));
        dispatch(fetchSignupSurvey(token));
        dispatch(updateProfile({ lastWebLogin: moment() }, token, false, true));
        // Hide quick start guide, if user has already completed
        if (user.onboarding.quickStartComplete) {
          dispatch(disableQuickStartGuide());
        }
        track.trackLastWebLogin(moment().format('MM/DD/YY hh:mm A'));
        const store = getOrCreateStore();
        const dialogStore = store.getState().dialogs;
        if (dialogStore && dialogStore.signupCallback) {
          dialogStore.signupCallback();
        }
      } else {
        dispatch({
          messages: [json],
          type: types.LoginFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Allow a user to sign up for a Torii user account.
 * @param {Object} values - Values sent from the form
 * @param {string} postAuthPath - Where to send a user after they login
 */
export function signup(
  values: any,
  postAuthPath?: string,
  isNewSignup?: boolean,
) {
  /** Remove non-numbers from phone number if given */
  if (values.phoneNumber) {
    values.phoneNumber = parsePhoneNumber(values.phoneNumber);
  }

  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi('/signup', values, 'post');
      const json = await response.json();
      if (response.ok) {
        EventEmitter.emit('signupSuccess');
        // Setting sleep for 2 sec so we show signup success message
        await sleep(2000);
        const { token, user } = json;
        /** If the user has signed up from the sidebar extension */
        if (user.isSidebarUser) {
          dispatch({
            signupPath: values.signupPath,
            entrancePath: values.entrancePath,
            token,
            type: types.SignupSidebarSuccess,
            user,
          });
        } else if (isNewSignup) {
          /** If the user has signed up from the new sign-up flow*/
          dispatch({
            signupPath: values.signupPath,
            entrancePath: values.entrancePath,
            token,
            type: types.SignUpNewSuccess,
            user,
          });
          // Save their filled up survey values to DB if any
          dispatch(saveSurveyValues(token));
        } else {
          /** If the user has signed from the web */
          dispatch({
            signupPath: values.signupPath,
            entrancePath: values.entrancePath,
            token,
            type: types.SignupSuccess,
            user,
          });
        }
        cookie.set('token', token, {
          expires: TOKEN_EXPIRATION_DAYS,
        });
        // If a user signs up, we no longer need to track their search count
        localStore.removeItem(storageKeys.noOfRandomHomeVisits);
        localStore.removeItem(storageKeys.noOfSearches);
        localStore.removeItem(storageKeys.homeVisitDate);
        // Send email verification email
        dispatch(sendVerification('email', token));
        if (isNewSignup) {
          // Send phone verification email only with new signup flow
          dispatch(sendVerification('phone', token));
        }
        if (postAuthPath) {
          Router.push(postAuthPath);
        }
        dispatch(updateProfile({ lastWebLogin: moment() }, token, false, true));
        track.trackLastWebLogin(moment().format('MM/DD/YY hh:mm A'));
        track.register(
          user.firstName,
          user.lastName,
          user.email,
          user.phoneNumber,
        );
        // Get redux store values and execute signup callback if any
        const store = getOrCreateStore();
        const dialogStore = store.getState().dialogs;
        if (dialogStore && dialogStore.signupCallback) {
          dialogStore.signupCallback();
        }
      } else {
        EventEmitter.emit('signupError');
        dispatch({
          messages: [json],
          type: types.SignupFailure,
        });
      }
    } catch (error) {
      EventEmitter.emit('signupError');
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Allow a user to sign up without password.
 * @param {Object} values - Values sent from the form
 * @param {string} postAuthPath - Where to send a user after they login
 */
export function signUpNp(values: any) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    dispatch({
      type: types.AuthenticationLoading,
      authLoading: true,
    });
    try {
      const response = await fetchApi('/signup-np', values, 'post');
      const json = await response.json();
      if (response.ok) {
        const { token, user } = json;
        dispatch({
          signupPath: values.signupPath,
          entrancePath: values.entrancePath,
          token,
          type: types.SignUpNpSuccess,
          user,
        });
        cookie.set('token', token, {
          expires: TOKEN_EXPIRATION_DAYS,
        });
        localStore.removeItem(storageKeys.noOfRandomHomeVisits);
        localStore.removeItem(storageKeys.noOfSearches);
        localStore.removeItem(storageKeys.homeVisitDate);
        track.register(
          user.firstName,
          user.lastName,
          user.email,
          user.phoneNumber,
        );
      } else {
        dispatch({
          messages: [json],
          type: types.SignUpNpFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Allow a user to log out of their Torii account.
 */
export function logout() {
  track.logout();
  // TODO: This is a stupid hack to fix a bug where logging out leaves the user
  // in a weird half-logged in state. This should not stay this way.
  new Promise(resolve => setTimeout(resolve, 100)).then(() => {
    cookie.remove('token');
    localStore.removeItem('token');
    localStore.removeItem(storageKeys.noOfRandomHomeVisits);
    localStore.removeItem(storageKeys.noOfSearches);
    localStore.removeItem(storageKeys.homeVisitDate);
  });
  return {
    type: types.LogoutSuccess,
  };
}

/**
 * Allow a user to indicate they lost the password to their Torii account.
 * @param {Object} values - values
 */
export function forgotPassword(values: {}) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi('/forgot', values, 'post');
      if (response.ok) {
        return response.json().then((json: any) => {
          setTimeout(() => {
            dispatch({
              type: types.CloseForgotDialog,
            });
          }, 1000);

          dispatch({
            messages: [json],
            type: types.ForgotPasswordSuccess,
          });
        });
      }
      const responseNotOk = await response.json();
      dispatch({
        messages: Array.isArray(responseNotOk)
          ? responseNotOk
          : [responseNotOk],
        type: types.ForgotPasswordFailure,
      });
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Allow a user to opt out of receiving feed (daily listing) emails.
 * @param {Object} values - Values sent from the form
 */
export function feedEmailOptOut(values: {}) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi(
        '/account/feed-email-opt-out',
        values,
        'put',
      );
      const json = await response.json();
      if (response.ok) {
        emitNotificationEvent('success', json.msg);
        dispatch({
          messages: [json.msg],
          type: types.FeedEmailOptOutSuccess,
          user: json.user,
        });
      } else {
        dispatch(
          forceLoginHelper(json, () => {
            dispatch({
              messages: [json],
              type: types.FeedEmailOptOutFailure,
            });
          }),
        );
      }
    } catch (error) {
      emitNotificationEvent('error', STANDARD_ERROR_MESSAGE);
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Allow a user to reset the password to their Torii account.
 * @param {Object} values - Values sent from the form
 * @param {string} pathToken - The reset token we've generated for this reset to occur.<br>
 * Called "pathToken" because it gets passed in the URL params
 */
export function resetPassword(values: {}, pathToken: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi(`/reset/${pathToken}`, values, 'post');
      const json = await response.json();
      if (response.ok) {
        dispatch({
          messages: [json],
          type: types.ResetPasswordSuccess,
        });
      } else {
        dispatch(
          forceLoginHelper(json, () => {
            dispatch({
              messages: [json],
              type: types.ResetPasswordFailure,
            });
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Allow a user to update information in their Torii account.
 * @param {Object} values - Values sent from the form
 * @param {string} token - The user's authentication token
 * @param {boolean} onboarding - Whether or not a user is being updated<br>
 * from onboarding. Not currently in use.
 */
export function updateProfile(
  values: any,
  token: string,
  onboarding: boolean = false,
  skipNotificationEvent: boolean = false,
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      /** Remove non-numbers from phone number if given */
      if (values.phoneNumber) {
        values.phoneNumber = parsePhoneNumber(values.phoneNumber);
      }

      const response = await fetchApi('/account', values, 'put', {}, token);
      const json = await response.json();
      if (response.ok) {
        if (!skipNotificationEvent) {
          emitNotificationEvent('success', json.msg);
        }
        if (onboarding) {
          dispatch({
            messages: [json.msg],
            type: types.OnboardingProfileSuccess,
            user: json.user,
          });
        } else {
          dispatch({
            messages: [json.msg],
            type: types.UpdateProfileSuccess,
            user: json.user,
          });
        }
      } else {
        dispatch(
          forceLoginHelper(json, () => {
            if (!skipNotificationEvent) {
              emitNotificationEvent('error', json.msg);
            }
            dispatch({
              messages: [json],
              type: types.UpdateProfileFailure,
            });
          }),
        );
      }
    } catch (error) {
      emitNotificationEvent('error', STANDARD_ERROR_MESSAGE);
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Allow a user to change the password to their Torii account.
 * @param {Object} values - Values sent from the form
 * @param {string} token - The user's authentication token
 */
export function changePassword(values: {}, token: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi('/account', values, 'put', {}, token);

      const json = await response.json();

      if (response.ok) {
        emitNotificationEvent('success', json.msg);
        dispatch({
          messages: [json],
          type: types.ChangePasswordSuccess,
        });
      } else {
        dispatch(
          forceLoginHelper(json, () => {
            emitNotificationEvent('error', json.msg);
            dispatch({
              messages: [json],
              type: types.ChangePasswordFailure,
            });
          }),
        );
      }
    } catch (error) {
      emitNotificationEvent('error', STANDARD_ERROR_MESSAGE);
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

export function deleteLoader(deleteLoading: boolean) {
  return {
    deleteLoading,
    type: types.DeleteAccountLoading,
  };
}
/**
 * Allow a user to delete their Torii account.
 * @param {string} token - The user's authentication token
 */
export function deleteAccount(reason: string, token: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    dispatch(deleteLoader(true));
    try {
      const response = await fetchApi(
        `/account?reason=${reason}`,
        {},
        'delete',
        {},
        token,
      );
      await response.json();
      EventEmitter.emit('deleteSuccess');
      dispatch({
        type: types.DeleteAccountSuccess,
      });
    } catch (error) {
      dispatch(deleteLoader(false));
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Update the call to action that a user came in from on the state
 * @param {string} cta - The "call to action" a user saw. Could be<br>
 * to signup for an account or download the app.
 */
export function updateCta(cta: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      cta,
      type: types.UpdateCta,
    });
  };
}

/**
 * Update a users's UTM parameters that they entered with.<br>
 * Used for tracking purposes with marketing efforts.
 * @param {string} utmCampaign - utmCampaign
 * @param {string} utmMedium - utmMedium
 * @param {string} utmSource - utmSource
 * @param {string} utmTerm - utmTerm
 * @param {string} entrancePath - The path where a user first entered the site
 */
export function updateUtm(
  utmCampaign: string | string[],
  utmMedium: string | string[],
  utmSource: string | string[],
  utmTerm: string | string[],
  entrancePath: string,
) {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UpdateUtm,
      utmCampaign,
      utmMedium,
      utmSource,
      utmTerm,
      entrancePath,
    });
  };
}

/**
 * Subscribe a user to our marketing e-list
 * @param {string} token - The user's authentication token
 */
export function subscribeToElist(token: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });

    try {
      const response = await fetchApi(
        '/account/subscribe-to-elist',
        {},
        'put',
        {},
        token,
      );
      const json = await response.json();

      if (response.ok) {
        emitNotificationEvent('success', json.msg);
        dispatch({
          messages: [json.msg],
          subscribed: true,
          type: types.EListSubscriptionSuccess,
        });
      } else {
        dispatch(
          forceLoginHelper(json, () => {
            emitNotificationEvent('error', json.msg);
            dispatch({
              messages: [json],
              type: types.EListSubscriptionFailure,
            });
          }),
        );
      }
    } catch (error) {
      emitNotificationEvent('error', STANDARD_ERROR_MESSAGE);
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Unsubscribe a user from our marketing e-list
 * @param {string} token - The user's authentication token
 */
export function unsubscribeFromElist(token: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi(
        '/account/unsubscribe-from-elist',
        {},
        'put',
        {},
        token,
      );
      const json = await response.json();

      if (response.ok) {
        emitNotificationEvent('success', json.msg);
        dispatch({
          messages: [json.msg],
          subscribed: false,
          type: types.EListSubscriptionSuccess,
        });
      } else {
        dispatch(
          forceLoginHelper(json, () => {
            emitNotificationEvent('error', json.msg);
            dispatch({
              messages: [json],
              type: types.EListSubscriptionFailure,
            });
          }),
        );
      }
    } catch (error) {
      emitNotificationEvent('error', STANDARD_ERROR_MESSAGE);
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Check to see if a user is subscribed to our marketing e-list
 * @param {string} token - The user's authentication token
 */
export function checkIfUserIsSubscribedToElist(token: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi(
        '/account/check-user-elist-subscription',
        {},
        'post',
        {},
        token,
      );
      const json = await response.json();

      if (response.ok) {
        dispatch({
          subscribed: json.subscribed,
          type: types.EListSubscriptionSuccess,
        });
      } else {
        dispatch(
          forceLoginHelper(json, () => {
            dispatch({
              messages: [json],
              type: types.EListSubscriptionFailure,
            });
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Display the SignUp/Login Modal
 * @param {string} token - The user's authentication token
 */
export function showSignupModal(postAuthPath?: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      postAuthPath,
      type: types.OpenSignupDialog,
    });
  };
}

/**
 * Notify Team about request for lender introduction
 */
export function sendRequestForLenderIntroduction(
  token: string,
  skipPrompt?: boolean,
) {
  return async (dispatch: any) => {
    try {
      const response = await fetchApi(
        '/send-request-to-introduce-lender',
        {},
        'put',
        {},
        token,
      );
      if (response.ok) {
        dispatch({
          type: types.RequestedLenderIntro,
        });
        if (!skipPrompt) {
          dispatch({
            successDialogText:
              'A Torii team member will email you shortly with an introduction to a lender.',
            type: types.OpenSuccessDialog,
          });
        }
      } else {
        const json = await response.json();
        dispatch(forceLoginHelper(json, () => {}));
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Update the signupDetails as per data from the user.
 * @param {any} userDetail - The data given by the user
 */
export const updateSignupDetails = (details: any) => {
  return (dispatch: any) => {
    dispatch({
      type: types.UpdateSignupDetails,
      details,
    });
  };
};

export const updateSignupProgress = (progress: any) => {
  return (dispatch: any) => {
    dispatch({
      type: types.UpdateSignupProgress,
      progress,
    });
  };
};

export function verifyUserSuccess(user: any, isEmail: boolean = true) {
  if (isEmail) {
    return {
      type: types.VerifyEmailSuccess,
      user,
    };
  }
  return {
    type: types.VerifyPhoneSuccess,
    user,
  };
}

export function verifyUser(values: any, token: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const key = values.emailVerificationToken ? 'Email' : 'Phone number';
      const response = await fetchApi('/verify', values, 'post', {}, token);
      const json = await response.json();
      if (response.ok) {
        dispatch(verifyUserSuccess(json.user, key === 'Email'));
        if (key === 'Email') {
          dispatch(toggleEmailVerifyModal(false));
        } else {
          dispatch(togglePhoneVerifyModal(false));
        }
        // Remove the prompt after 2 sec
        setTimeout(() => {
          if (key === 'Email') {
            dispatch(toggleEmailVerificationPrompt(false));
          } else {
            dispatch(togglePhoneVerificationPrompt(false));
          }
        }, 2000);
      } else {
        emitNotificationEvent('error', json.msg);
      }
    } catch (error) {
      return dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Send sellingAddress to API, so we can update to CRM
 * @param sellingAddress
 * @param token
 * @returns
 */
export function updateSellingAddress(sellingAddress: string, token: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchApi(
        '/user/selling-address',
        { sellingAddress },
        'post',
        {},
        token,
      );
      if (response.ok) {
        return dispatch({ type: types.UpdateSellingAddressSuccess });
      } else {
        return dispatch({ type: types.UpdateSellingAddressFailure });
      }
    } catch (error) {
      return dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}
