import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import rootReducer from '../reducers';

const persistConfig = {
  key: 'primary',
  storage,
  // Add reducers here to persist them
  whitelist: ['auth', 'dashboard'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = [thunk, promise];

export default function initializeStore(initialState = {}) {
  return createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
  );
}
