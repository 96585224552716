import * as types from '../actions/actionTypes';

export const defaultAlertSubText =
  'Unfortunately, Torii is currently unable to process your request';
export const defaultAlertHeadingText = 'Sorry';
export const defaultSuccessText =
  'A Torii team member will contact you shortly.';

export const filterDialogs = {
  filterBedBathOpen: false,
  filterHomeTypeOpen: false,
  filterPriceRangeOpen: false,
  filterRoomConditionOpen: false,
  filterSquareFeetOpen: false,
  filterMoreFiltersOpen: false,
  filterRoomPhotoOrderOpen: false,
  filterSortOptionsOpen: false,
};

const initialState = {
  alertDialogOpen: false,
  alertDialogHeading: defaultAlertHeadingText,
  alertDialogSubText: defaultAlertSubText,
  deleteSearchDialogOpen: false,
  deleteSearchId: undefined,
  favoritesSuccessDialog: false,
  forgotDialogOpen: false,
  filterPriceRangeOpen: false,
  filterBedBathOpen: false,
  filterSquareFeetOpen: false,
  filterHomeTypeOpen: false,
  filterRoomConditionOpen: false,
  filterMoreFiltersOpen: false,
  filterRoomPhotoOrderOpen: false,
  filterSortOptionsOpen: false,
  helpDialogOpen: false,
  loginDialogOpen: false,
  newUser: false,
  resetPasswordDialogOpen: false,
  saveAndContinueDialogOpen: false,
  saveAndContinueSuccessDialogOpen: false,
  savedSearchPopup: false,
  saveSearchDialogOpen: false,
  scheduleShowingDialogOpen: false,
  scheduleShowingListing: {},
  signupCallback: undefined,
  signupDialogOpen: false,
  signupSubtext: undefined,
  successDialogOpen: false,
  successDialogText: defaultSuccessText,
  unsubscribedDialogOpen: false,
  welcomeDialogOpen: false,
  welcomeSurveyDialogOpen: false,
};

/**
 * State management for data related to dialogs.
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function dialogs(state: any = initialState, action: any) {
  switch (action.type) {
    case types.ScheduleMeeting:
      return Object.assign({}, state, {
        welcomeDialogOpen: true,
      });
    case types.SignupSuccess:
      return Object.assign({}, state, {
        loginDialogOpen: false,
        newUser: true,
        signupDialogOpen: false,
        welcomeSurveyDialogOpen: true,
        welcomeDialogOpen: false,
      });
    case types.OauthSignupSuccess:
      return Object.assign({}, state, {
        loginDialogOpen: false,
        newUser: true,
        signupDialogOpen: false,
      });
    case types.ResetPasswordSuccess:
      return Object.assign({}, state, {
        resetPasswordDialogOpen: true,
      });
    case types.CloseResetPasswordSuccessDialog:
      return Object.assign({}, state, {
        resetPasswordDialogOpen: false,
      });
    case types.OpenAlertDialog:
      return Object.assign({}, state, {
        alertDialogHeading: action.alertDialogHeading,
        alertDialogOpen: true,
        alertDialogSubText: action.alertDialogSubText,
      });
    case types.OpenForgotDialog:
      return Object.assign({}, state, {
        forgotDialogOpen: true,
      });
    case types.OpenLoginDialog:
      return Object.assign({}, state, {
        loginDialogOpen: true,
      });
    case types.OpenSignupDialog:
      return Object.assign({}, state, {
        signupDialogOpen: true,
        signupSubtext: action.signupSubtext ? action.signupSubtext : undefined,
        signupCallback: action.signupCallback
          ? action.signupCallback
          : undefined,
      });
    case types.LoginSuccess:
    case types.OauthLoginSuccess:
      return Object.assign({}, state, {
        loginDialogOpen: false,
        signupDialogOpen: false,
      });
    case types.CloseUnsubscribeSuccessDialog:
      return Object.assign({}, state, {
        unsubscribedDialogOpen: false,
      });
    case types.FeedEmailOptOutSuccess:
      return Object.assign({}, state, {
        unsubscribedDialogOpen: true,
      });
    case types.CloseAlertDialog:
      return Object.assign({}, state, {
        alertDialogHeading: defaultAlertHeadingText,
        alertDialogOpen: false,
        alertDialogSubText: defaultAlertSubText,
      });
    case types.CloseForgotDialog:
      return Object.assign({}, state, {
        forgotDialogOpen: false,
      });
    case types.CloseLoginDialog:
      return Object.assign({}, state, {
        loginDialogOpen: false,
      });
    case types.CloseSignupDialog:
      return Object.assign({}, state, {
        signupDialogOpen: false,
        signupSubtext: undefined,
      });
    case types.ClearNewUser:
      return Object.assign({}, state, {
        newUser: false,
      });
    case types.CloseWelcomeDialog:
      return Object.assign({}, state, {
        welcomeDialogOpen: false,
      });
    case types.CloseScheduleShowingDialog:
      return Object.assign({}, state, {
        scheduleShowingDialogOpen: false,
        scheduleShowingListing: {},
      });
    case types.CloseSaveAndContinueDialog:
      return Object.assign({}, state, {
        saveAndContinueDialogOpen: false,
      });
    case types.CloseSaveAndContinueSuccessDialog:
      return Object.assign({}, state, {
        saveAndContinueSuccessDialogOpen: false,
      });
    case types.OpenSaveAndContinueDialog:
      return Object.assign({}, state, {
        saveAndContinueDialogOpen: true,
      });
    case types.OpenSaveAndContinueSuccessDialog:
      return Object.assign({}, state, {
        saveAndContinueSuccessDialogOpen: true,
      });
    case types.OpenScheduleShowingDialog:
      return Object.assign({}, state, {
        scheduleShowingDialogOpen: true,
        scheduleShowingListing: action.listing,
      });
    case types.OpenSuccessDialog:
      return Object.assign({}, state, {
        successDialogOpen: true,
        successDialogText: action.successDialogText
          ? action.successDialogText
          : defaultSuccessText,
      });
    case types.CloseSuccessDialog:
      return Object.assign({}, state, {
        successDialogOpen: false,
        successDialogText: defaultSuccessText,
      });
    case types.OpenWelcomeSurveyDialog:
      return Object.assign({}, state, {
        welcomeSurveyDialogOpen: true,
      });
    case types.CloseWelcomeSurveyDialog:
      return Object.assign({}, state, {
        welcomeSurveyDialogOpen: false,
        // welcomeDialogOpen: state.newUser ? true : false,
      });
    case types.OpenSaveSearchDialog:
      return Object.assign({}, state, {
        saveSearchDialogOpen: true,
      });
    case types.CloseSaveSearchDialog:
      return Object.assign({}, state, {
        saveSearchDialogOpen: false,
      });
    case types.OpenDeleteSearchDialog:
      return Object.assign({}, state, {
        deleteSearchDialogOpen: true,
        deleteSearchId: action.searchId,
      });
    case types.CloseDeleteSearchDialog:
      return Object.assign({}, state, {
        deleteSearchDialogOpen: false,
        deleteSearchId: undefined,
      });
    case types.OpenSavedSearchPopup:
      return Object.assign({}, state, {
        savedSearchPopup: true,
      });
    case types.OpenHelpDialog:
      return Object.assign({}, state, {
        helpDialogOpen: true,
      });
    case types.CloseHelpDialog:
      return Object.assign({}, state, {
        helpDialogOpen: false,
      });
    case types.CloseSavedSearchPopup:
      return Object.assign({}, state, {
        savedSearchPopup: false,
      });
    case types.ClearSignupCallback:
      return Object.assign({}, state, {
        signupCallback: undefined,
      });
    case types.OpenFavoritesSuccessDialog:
      return Object.assign({}, state, {
        favoritesSuccessDialog: true,
      });
    case types.CloseFavoritesSuccessDialog:
      return Object.assign({}, state, {
        favoritesSuccessDialog: false,
      });
    case types.LogoutSuccess:
      return initialState;
    case types.UpdateFilterDialog:
      return Object.assign({}, state, {
        ...filterDialogs,
        [action.dialog]: !state[action.dialog],
      });
    default:
      return state;
  }
}
