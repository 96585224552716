const AgentCallRequestFailure = 'AGENT_CALL_REQUEST_FAILURE';
const AgentCallRequestSuccess = 'AGENT_CALL_REQUEST_SUCCESS';
const AuthenticationLoading = `AUTHENTICATION_LOADING`;
const BlockedFetchFailure = 'BLOCKED_FETCH_FAILURE';
const BlockedFetchSuccess = 'BLOCKED_FETCH_SUCCESS';
const BlockListingFailure = 'BLOCKED_LISTING_FAILURE';
const BlockListingSuccess = 'BLOCKED_LISTING_SUCCESS';
const ChangePasswordFailure = 'CHANGE_PASSWORD_FAILURE';
const ChangePasswordSuccess = 'CHANGE_PASSWORD_SUCCESS';
const CheckoutFormFailure = 'CHECKOUT_FORM_FAILURE';
const CheckoutFormSuccess = 'CHECKOUT_FORM_SUCCESS';
const ClearBlockedListings = 'CLEAR_BLOCKED_LISTINGS';
const ClearComps = 'CLEAR_COMPS';
const ClearFavorites = 'CLEAR_FAVORITES';
const ClearFeed = 'CLEAR_FEED';
const ClearFixers = 'CLEAR_FIXERS';
const ClearInvestmentProps = 'CLEAR_INVESTMENT_PROPS';
const ClearListings = 'CLEAR_LISTINGS';
const ClearMessages = 'CLEAR_MESSAGES';
const ClearNeighborhood = 'CLEAR_NEIGHBORHOOD';
const ClearNewUser = 'CLEAR_NEW_USER';
const ClearRandomListings = 'CLEAR_RANDOM_LISTINGS';
const ClearSchools = 'CLEAR_SCHOOLS';
const ClearSearchResults = 'CLEAR_SEARCH_RESULTS';
const ClearSignedUpMessage = 'SIGNED_UP_MESSAGE_CLEAR';
const ClearSignupCallback = 'CLEAR_SIGNUP_CALLBACK';
const ClearTrendingSocialListings = 'CLEAR_TRENDING_SOCIAL_LISTINGS';
const ClearUndervaluedProps = 'CLEAR_UNDERVALUED_PROPS';
const CloseAlertDialog = 'CLOSE_ALERT_DIALOG';
const CloseDeleteSearchDialog = 'CLOSE_DELETE_SEARCH_DIALOG';
const CloseFavoritesSuccessDialog = 'CLOSE_FAVORITES_SUCCESS_DIALOG';
const CloseFileUploadModal = 'CLOSE_FILE_UPLOAD_MODAL';
const CloseForgotDialog = 'CLOSE_FORGOT_DIALOG';
const CloseHelpDialog = 'CLOSE_HELP_DIALOG';
const CloseLoginDialog = 'CLOSE_LOGIN_DIALOG';
const CloseOfferDialog = 'CLOSE_OFFER_DIALOG';
const CloseResetPasswordSuccessDialog = 'CLOSE_RESET_PASSWORD_SUCCESS_DIALOG';
const CloseSaveAndContinueDialog = 'CLOSE_SAVE_AND_CONTINUE_DIALOG';
const CloseSaveAndContinueSuccessDialog =
  'CLOSE_SAVE_AND_CONTINUE_SUCCESS_DIALOG';
const CloseSavedSearchPopup = 'CLOSE_SAVED_SEARCH_POPUP';
const CloseSaveSearchDialog = 'CLOSE_SAVE_SEARCH_DIALOG';
const CloseScheduleShowingDialog = 'CLOSE_SCHEDULE_SHOWING_DIALOG';
const CloseSignupDialog = 'CLOSE_SIGNUP_DIALOG';
const CloseSuccessDialog = 'CLOSE_SUCCESS_DIALOG';
const CloseUndoSavedSearchToast = 'CLOSE_UNDO_DELETE_SEARCH_TOAST';
const CloseUnsubscribeSuccessDialog = 'CLOSE_UNSUBSCRIBE_SUCCESS_DIALOG';
const CloseWelcomeDialog = 'CLOSE_WELCOME_DIALOG';
const CloseWelcomeSurveyDialog = 'CLOSE_WELCOME_SURVEY_DIALOG';
const ClosingCostLoading = 'CLOSING_COST_LOADING';
const ClosingCostsFetch = 'CLOSING_COST_FETCH';
const ClosingCostsFetchFailure = 'CLOSING_COST_FETCH_FAILURE';
const ClosingCostsFetchSuccess = 'CLOSING_COST_FETCH_SUCCESS';
const CloseYourTeamModal = 'CLOSE_YOUR_TEAM_MODAL';
const CompsFetchFailure = 'COMPS_FETCH_FAILURE';
const CompsFetchStart = 'COMPS_FETCH_START';
const CompsFetchSuccess = 'COMPS_FETCH_SUCCESS';
const ContactFormFailure = 'CONTACT_FORM_FAILURE';
const ContactFormSuccess = 'CONTACT_FORM_SUCCESS';
const DeleteAccountFailure = 'DELETE_ACCOUNT_FAILURE';
const DeleteAccountLoading = 'DELETE_ACCOUNT_LOADING';
const DeleteAccountSuccess = 'DELETE_ACCOUNT_SUCCESS';
const DeleteBlockedFailure = 'DELETE_BLOCKED_FAILURE';
const DeleteBlockedSuccess = 'DELETE_BLOCKED_SUCCESS';
const DeleteShowingFailure = 'DeleteShowingFailure';
const DeleteShowingSuccess = 'DeleteShowingSuccess';
const DisableQuickStartGuide = 'DISABLE_QUICK_START_GUIDE';
const DisableYourNextTask = 'DISABLE_YOUR_NEXT_TASK';
const EListSubscriptionFailure = 'E_LIST_SUBSCRIPTION_FAILURE';
const EListSubscriptionSuccess = 'E_LIST_SUBSCRIPTION_SUCCESS';
const FavoritesDeleteFailure = 'FAVORITES_DELETE_FAILURE';
const FavoritesDeleteSuccess = 'FAVORITES_DELETE_SUCCESS';
const FavoritesFetchFailure = 'FAVORITES_FETCH_FAILURE';
const FavoritesFetchSuccess = 'FAVORITES_FETCH_SUCCESS';
const FavoritesSaveFailure = 'FAVORITES_SAVE_FAILURE';
const FavoritesSaveSuccess = 'FAVORITES_SAVE_SUCCESS';
const FeedEmailOptOutFailure = 'FEED_EMAIL_OPT_OUT_FAILURE';
const FeedEmailOptOutSuccess = 'FEED_EMAIL_OPT_OUT_SUCCESS';
const FeedFetchFailure = 'FEED_FETCH_FAILURE';
const FeedFetchSuccess = 'FEED_FETCH_SUCCESS';
const FeedMarkerSelect = 'FEED_MARKER_SELECT';
const FeedSearchFetchFailure = 'FEED_SEARCH_FETCH_FAILURE';
const FeedSearchFetchSuccess = 'FEED_SEARCH_FETCH_SUCCESS';
const FeedSearchSaveFailure = 'FEED_SEARCH_SAVE_FAILURE';
const FeedSearchSaveSuccess = 'FEED_SEARCH_SAVE_SUCCESS';
const FetchCitiesFailure = 'FETCH_CITIES_FAILURE';
const FetchCitiesSuccess = 'FETCH_CITIES_SUCCESS';
const FetchingFeed = 'FETCHING_FEED';
const FetchingRecommendedListings = 'FETCHING_RECOMMENDED_LISTINGS';
const RecommendsFetchSuccess = 'RECOMMENDS_FETCH_SUCCESS';
const RecommendsFetchFail = 'RECOMMENDS_FETCH_FAIL';
const FetchSavedSearchesFailure = 'FETCH_SAVED_SEARCHES_FAILURE';
const FetchSavedSearchesRequest = 'FETCH_SAVED_SEARCHES_REQUEST';
const FetchSavedSearchesSuccess = 'FETCH_SAVED_SEARCHES_SUCCESS';
const FixersFetchFailure = 'FIXERS_FETCH_FAILURE';
const FixersFetchSuccess = 'FIXERS_FETCH_SUCCESS';
const FixersLoading = 'FIXERS_LOADING';
const ForgotPasswordFailure = 'FORGOT_PASSWORD_FAILURE';
const ForgotPasswordSuccess = 'FORGOT_PASSWORD_SUCCESS';
const GenericError = 'GENERIC_ERROR';
const HeaderSearchComplete = 'HEADER_SEARCH_COMPLETE';
const HidePreapprovedTrueModal = 'HIDE_PREAPPROVED_TRUE_MODAL';
const HidePreapprovedFalseModal = 'HIDE_PREAPPROVED_FALSE_MODAL';
const HidePreapprovedContactModal = 'HIDE_PREAPPROVED_CONTACT_MODAL';
const HideDocumentsUploadSuccessModal = 'HIDE_DOCUMENTS_UPLOAD_SUCCESS_MODAL';
const HomeSearchComplete = 'HOME_SEARCH_COMPLETE';
const InitialMapLoad = 'INITIAL_MAP_LOAD';
const InvestmentPropsFetchFailure = 'INVESTMENT_PROPS_FETCH_FAILURE';
const InvestmentPropsFetchSuccess = 'INVESTMENT_PROPS_FETCH_SUCCESS';
const InvestmentPropsLoading = 'INVESTMENT_PROPS_LOADING';
const LinkFailure = 'LINK_FAILURE';
const ListingFetchFailure = 'LISTING_FETCH_FAILURE';
const ListingFetchSuccess = 'LISTING_FETCH_SUCCESS';
const ListingLoading = 'LISTING_LOADING';
const ListingSearchFailure = 'LISTING_SEARCH_FAILURE';
const ListingSearchSuccess = 'LISTING_SEARCH_SUCCESS';
const LoadOpeningsError = 'LOAD_OPENINGS_ERROR';
const LoadOpeningsStart = 'LOAD_OPENINGS_START';
const LoadOpeningsSuccess = 'LOAD_OPENINGS_SUCCESS';
const LoadProviderError = 'LOAD_PROVIDER_ERROR';
const LoadProviderStart = 'START_LOADING_PROVIDER';
const LoadProviderSuccess = 'LOAD_PROVIDER_SUCCESS';
const LoadTrendingSocialListings = 'LOAD_TRENDING_SOCIAL_LISTINGS';
const LoginFailure = 'LOGIN_FAILURE';
const LoginSuccess = 'LOGIN_SUCCESS';
const LogoutSuccess = 'LOGOUT_SUCCESS';
const ModifyCityBoundary = 'MODIFY_CITY_BOUNDARY';
const MonthlyMarketDataFetchFailure = 'MONTHLY_MARKET_DATA_FETCH_FAILURE';
const MonthlyMarketDataFetchSuccess = 'MONTHLY_MARKET_DATA_FETCH_SUCCESS';
const MonthlyMarketMonthsFetchFailure = 'MONTHLY_MARKET_MONTHS_FETCH_FAILURE';
const MonthlyMarketMonthsFetchSuccess = 'MONTHLY_MARKET_MONTHS_FETCH_SUCCESS';
const NeighborhoodFetchFailure = 'NEIGHBORHOOD_FETCH_FAILURE';
const NeighborhoodFetchLoading = 'NEIGHBORHOOD_FETCH_LOADING';
const NeighborhoodFetchSuccess = 'NEIGHBORHOOD_FETCH_SUCCESS';
const OauthFailure = 'OAUTH_FAILURE';
const OauthLoginSuccess = 'OAUTH_LOGIN_SUCCESS';
const OauthSignupSuccess = 'OAUTH_SIGNUP_SUCCESS';
const OffersFetchFailure = 'OFFERS_FETCH_FAILURE';
const OffersFetchSuccess = 'OFFERS_FETCH_SUCCESS';
const OnboardingProfileSuccess = 'ONBOARDING_PROFILE_SUCCESS';
const OpenAlertDialog = 'OPEN_ALERT_DIALOG';
const OpenDeleteSearchDialog = 'OPEN_DELETE_SEARCH_DIALOG';
const OpenFavoritesSuccessDialog = 'OPEN_FAVORITES_SUCCESS_DIALOG';
const OpenFileUploadModal = 'OPEN_FILE_UPLOAD_MODAL';
const OpenForgotDialog = 'OPEN_FORGOT_DIALOG';
const OpenHelpDialog = 'OPEN_HELP_DIALOG';
const OpenHousesFetchFailure = 'OPEN_HOUSES_FETCH_FAILURE';
const OpenHousesFetchSuccess = 'OPEN_HOUSES_FETCH_SUCCESS';
const OpenLoginDialog = 'OPEN_LOGIN_DIALOG';
const OpenOfferDialog = 'OPEN_OFFER_DIALOG';
const OpenSaveAndContinueDialog = 'OPEN_SAVE_AND_CONTINUE_DIALOG';
const OpenSaveAndContinueSuccessDialog = 'OPEN_SAVE_SUCCESS_DIALOG';
const OpenSavedSearchPopup = 'OPEN_SAVED_SEARCH_POPUP';
const OpenSaveSearchDialog = 'OPEN_SAVE_SEARCH_DIALOG';
const OpenScheduleShowingDialog = 'OPEN_SCHEDULE_SHOWING_DIALOG';
const OpenSignupDialog = 'OPEN_SIGNUP_DIALOG';
const OpenSuccessDialog = 'OPEN_SUCCESS_DIALOG';
const OpenUndoSavedSearchToast = 'OPEN_UNDO_DELETE_SEARCH_TOAST';
const OpenWelcomeSurveyDialog = 'OPEN_WELCOME_SURVEY_DIALOG';
const OpenYourTeamModal = 'OPEN_YOUR_TEAM_MODAL';
const PostRatingFailure = 'POST_RATING_FAILURE';
const PostRatingSuccess = 'POST_RATING_SUCCESS';
const PricePointerFailure = 'PRICE_POINTER_FAILURE';
const PricePointerSuccess = 'PRICE_POINTER_SUCCESS';
const RandomListingCountFailure = 'RANDOM_LISTING_COUNT_FAILURE';
const RandomListingCountSuccess = 'RANDOM_LISTING_COUNT_SUCCESS';
const RandomListingFetchFailure = 'RANDOM_LISTING_FETCH_FAILURE';
const RandomListingFetchSuccces = 'RANDOM_LISTING_FETCH_SUCCESS';
const RelatedListingsFetchFailure = 'RELATED_LISTINGS_FETCH_FAILURE';
const RelatedListingsFetchStart = 'RELATED_LISTINGS_FETCH_START';
const RelatedListingsFetchSuccess = 'RELATED_LISTINGS_FETCH_SUCCESS';
const RentFormFailure = 'RENT_FORM_FAILURE';
const RentFormSuccess = 'RENT_FORM_SUCCESS';
const RequestedLenderIntro = 'REQUESTED_LENDER_INTRO';
const ResetPasswordFailure = 'RESET_PASSWORD_FAILURE';
const ResetPasswordSuccess = 'RESET_PASSWORD_SUCCESS';
const ResetSortedFeed = 'RESET_SORTED_FEED';
const ScheduleMeeting = 'SCHEDULE_MEETING';
const SchoolsFetchFailure = 'SCHOOLS_FETCH_FAILURE';
const SchoolsFetchLoading = 'SCHOOLS_FETCH_LOADING';
const SchoolsFetchSuccess = 'SCHOOLS_FETCH_SUCCESS';
const SearchSurveyFetchFailure = 'SEARCH_SURVEY_FETCH_FAILURE';
const SearchSurveyFetchSuccess = 'SEARCH_SURVEY_FETCH_SUCCESS';
const SearchResultsLoading = 'SEARCH_RESULTS_LOADING';
const SendEmailVerification = 'SEND_EMAIL_VERIFICATION';
const SendPhoneVerification = 'SEND_PHONE_VERIFICATION';
const SetBlockedLoading = 'SET_BLOCKED_LOADING';
const SetFavoritesLoading = 'SET_FAVORITES_LOADING';
const SetDocumentUploadComplete = 'SET_DOCUMENT_UPLOAD_COMPLETE';
const SetRandomHomeParams = 'SET_RANDOM_HOME_PARAMS';
const SetSignupPath = 'SET_SIGNUP_PATH';
const ShowEmailVerifyModal = 'SET_EMAIL_VERIFY_MODAL';
const ShowEmailVerifyPrompt = 'SHOW_EMAIL_VERIFY_PROMPT';
const ShowPhoneVerifyModal = 'SET_PHONE_VERIFY_MODAL';
const ShowPhoneVerifyPrompt = 'SHOW_PHONE_VERIFY_PROMPT';
const ShowingRequestFailure = 'SHOWING_REQUEST_FAILURE';
const ShowingRequestSuccess = 'SHOWING_REQUEST_SUCCESS';
const ShowingSubmitLoading = 'SHOWING_SUBMIT_LOADING';
const ShowingDeleteLoading = 'SHOWING_DELETE_LOADING';
const ShowingsFetchLoading = 'SHOWING_FETCH_LOADING';
const ShowingsUpdateSuccess = 'SHOWINGS_UPDATE_SUCCESS';
const ShowingsUpdateFailure = 'SHOWINGS_UPDATE_FAILURE';
const ShowingsFetchFailure = 'SHOWINGS_FETCH_FAILURE';
const ShowingsFetchSuccess = 'SHOWINGS_FETCH_SUCCESS';
const ShowDocumentsUploadSuccessModal = 'SHOW_DOCUMENTS_UPLOAD_SUCCESS_MODAL';
const SignupFailure = 'SIGNUP_FAILURE';
const SignUpNpFailure = 'SIGNUP_NP_FAILURE';
const SignUpNpSuccess = 'SIGNUP_NP_SUCCESS';
const SignUpNewFailure = 'SIGNUP_NEW_FAILURE';
const SignUpNewSuccess = 'SIGNUP_NEW_SUCCESS';
const SignupSidebarSuccess = 'SIGNUP_SIDEBAR_SUCCESS';
const SignupSuccess = 'SIGNUP_SUCCESS';
const SignupSurveyFetchFailure = 'SIGNUP_SURVEY_FETCH_FAILURE';
const SignupSurveyFetchSuccess = 'SIGNUP_SURVEY_FETCH_SUCCESS';
const SocialListingClear = 'SOCIAL_LISTING_CLEAR';
const SocialListingError = 'SOCIAL_LISTING_ERROR';
const SocialListingFetchVotesSuccess = 'SOCIAL_LISTING_FETCH_VOTES_SUCCESS';
const SocialListingFetchVotesFailure = 'SOCIAL_LISTING_FETCH_VOTES_FAILURE';
const SocialListingLeaderboardSuccess = 'SOCIAL_LISTING_LEADERBOARD_SUCCESS';
const SocialListingLeaderboardFailure = 'SOCIAL_LISTING_LEADERBOARD_FAILURE';
const SocialListingPostDeleteSuccess = 'SOCIAL_LISTING_POST_DELETE_SUCCESS';
const SocialListingPostDeleteFailure = 'SOCIAL_LISTING_POST_DELETE_FAILURE';
const SocialListingPostsFailure = 'SOCIAL_LISTING_POST_FAILURE';
const SocialListingPostForReply = 'SOCIAL_LISTING_POST_FOR_REPLY';
const SocialListingPostReportClear = 'SOCIAL_LISTING_POST_REPORT_CLEAR';
const SocialListingPostReportSuccess = 'SOCIAL_LISTING_POST_REPORT_SUCCESS';
const SocialListingPostReportFailure = 'SOCIAL_LISTING_POST_REPORT_FAILUREˀ';
const SocialListingPostsSuccess = 'SOCIAL_LISTING_POST_SUCCESS';
const SocialListingRatingCheckFailed = 'SOCIAL_LISTING_RATING_CHECK_FAILED';
const SocialListingRatingCheckSuccess = 'SOCIAL_LISTING_RATING_CHECK_SUCCESS';
const SocialListingRatingFailure = 'SOCIAL_LISTING_RATING_FAILURE';
const SocialListingRatingSuccess = 'SOCIAL_LISTING_RATING_SUCCESS';
const SocialListingStart = 'SOCIAL_LISTING_START';
const SocialListingSubmitPostSuccess = 'SOCIAL_LISTING_SUBMIT_POST_SUCCESS';
const SocialListingSubmitPostFailure = 'SOCIAL_LISTING_SUBMIT_POST_FAILURE';
const SocialListingSuccess = 'SOCIAL_LISTING_SUCCESS';
const SocialListingVoteDeleteSuccess = 'SOCIAL_LISTING_VOTE_DELETE_SUCCESS';
const SocialListingVoteDeleteFailure = 'SOCIAL_LISTING_VOTE_DELETE_FAILURE';
const SocialListingVoteSuccess = 'SOCIAL_LISTING_VOTE_SUCCESS';
const SocialListingVoteFailure = 'SOCIAL_LISTING_VOTE_FAILURE';
const SortedFeed = 'SORTED_FEED';
const StoreRandomHomePreferences = 'STORE_RANDOM_HOME_PREFERENCES';
const SetShowFavoritesModal = 'SET_SHOW_FAVORITES_MODAL';
const SetLenderIntroductionComplete = 'SET_LENDER_INTRODUCTION_COMPLETE';
const SetLenderAlreadyExists = 'SET_LENDER_ALREADY_EXISTS';
const SetFirstShowingCompleted = 'SET_FIRST_SHOWING_COMPLETED';
const SetFirstOfferSubmitted = 'SET_FIRST_OFFER_SUBMITTED';
const SetSearchPreferencesCompleted = 'SET_SEARCH_PREFERENCES_COMPLETED';
const SetFirstSearchCompleted = 'SET_FIRST_SEARCH_COMPLETED';
const SetFirstFavoriteCompleted = 'SET_FIRST_FAVORITE_COMPLETED';
const SetQuickStartGuideCompleted = 'SET_QUICK_START_GUIDE_COMPLETED';
const SetPreferencesStarted = 'SET_PREFERENCES_STARTED';
const ShowPreapprovedTrueModal = 'SET_SHOW_PREAPPROVED_TRUE_MODAL';
const ShowPreapprovedFalseModal = 'SET_SHOW_PREAPPROVED_FALSE_MODAL';
const ShowPreapprovedContactModal = 'SET_SHOW_PREAPPROVED_CONTACT_MODAL';
const UnsetShowFavoritesModal = 'UNSET_SHOW_FAVORITES_MODAL';
const UnsetLenderIntroduction = 'UNSET_LENDER_INTRODUCTION';
const UnsetLenderAlreadyExists = 'UNSET_LENDER_ALREADY_EXISTS';
const ToggleSurveyLoader = 'TOGGLE_SURVEY_LOADER';
const ToggleSurveyFetchLoader = 'TOGGLE_SURVEY_FETCH_LOADER';
const TrendingSocialListingsFailure = 'LOAD_TRENDING_SOCIAL_LISTINGS_FAILURE';
const TrendingSocialListingsSuccess = 'LOAD_TRENDING_SOCIAL_LISTINGS_SUCCESS';
const TrendsFormFailure = 'TRENDS_FORM_FAILURE';
const TrendsFormSuccess = 'TRENDS_FORM_SUCCESS';
const ToggleQuickStartGuide = 'TOGGLE_QUICK_START_GUIDE';
const ToggleYourNextTask = 'TOGGLE_YOUR_NEXT_TASK';
const TogglePhoneNumberOauthModal = 'TOGGLE_PHONE_NUMBER_OAUTH_MODAL';
const ToggleListingModal = 'TOGGLE_LISTING_MODAL';
const ToggleToolsButtonLoader = 'TOGGLE_TOOLS_BUTTON_LOADER';
const UndervaluedPropsFetchFailure = 'UNDERVALUED_PROPS_FETCH_FAILURE';
const UndervaluedPropsFetchSuccess = 'UNDERVALUED_PROPS_FETCH_SUCCESS';
const UndervaluedPropsLoading = 'UNDERVALUED_PROPS_LOADING';
const UnsetUploadComplete = 'UNSET_UPLOAD_COMPLETE';
const UnsetFirstShowingCompleted = 'UNSET_FIRST_SHOWING_SCHEDULED';
const UnsetFirstOfferSubmitted = 'UNSET_FIRST_OFFER_SUBMITTED';
const UnsetSearchPreferencesCompleted = 'UNSET_SEARCH_PREFERENCES_COMPLETED';
const UnsetFirstSearchCompleted = 'UNSET_FIRST_SEARCH_COMPLETED';
const UnsetFirstFavoriteCompleted = 'UNSET_FIRST_FAVORITE_COMPLETED';
const UnsetQuickStartGuideCompleted = 'UNSET_QUICK_START_GUIDE_COMPLETED';
const UnsetPreferencesStarted = 'UNSET_PREFERENCES_STARTED';
const UnlinkFailure = 'UNLINK_FAILURE';
const UnlinkSucces = 'UNLINK_SUCCESS';
const UpdateCta = 'UPDATE_CTA';
const UpdateFeedSearch = 'UPDATE_FEED_SEARCH';
const UpdateFilterDialog = 'UPDATE_FILTER_DIALOG';
const UpdateFilterSearch = 'UPDATE_FILTER_SEARCH';
const UpdateProfileFailure = 'UPDATE_PROFILE_FAILURE';
const UpdateProfileSuccess = 'UPDATE_PROFILE_SUCCESS';
const UpdateSavedSearchFailure = 'UPDATE_SAVED_SEARCH_FAILURE';
const UpdateSavedSearchRequest = 'UPDATE_SAVED_SEARCH_REQUEST';
const UpdateSavedSearchSuccess = 'UPDATE_SAVED_SEARCH_SUCCESS';
const UpdateSearchSurvey = 'UPDATE_SEARCH_SURVEY';
const UpdateSearchSurveyFailure = 'UPDATE_SEARCH_SURVEY_FAILURE';
const UpdateSearchSurveySuccess = 'UPDATE_SEARCH_SURVEY_SUCCESS';
const UpdateSellingAddressFailure = 'UPDATE_SELLING_ADDRESS_FAILURE';
const UpdateSellingAddressSuccess = 'UPDATE_SELLING_ADDRESS_SUCCESS';
const UpdateSignupDetails = 'UPDATE_SIGNUP_DETAILS';
const UpdateSignupProgress = 'UPDATE_SIGNUP_PROGRESS';
const UpdateSignupSurvey = 'UPDATE_SIGNUP_SURVEY';
const UpdateSignupSurveyFailure = 'UPDATE_SIGNUP_SURVEY_FAILURE';
const UpdateSignupSurveySuccess = 'UPDATE_SIGNUP_SURVEY_SUCCESS';
const UpdateUtm = 'UPDATE_UTM';
const VerifyEmailSuccess = 'VERIFY_EMAIL_SUCCESS';
const VerifyPhoneSuccess = 'VERIFY_PHONE_SUCCESS';
const VisitedRandomHome = 'VISITED_RANDOM_HOME';
const YourTeamLoading = 'YOUR_TEAM_LOADING';
const YourTeamSuccess = 'YOUR_TEAM_SUCCESS';
const YourTeamFailure = 'YOUR_TEAM_FAILURE';

export {
  AgentCallRequestFailure,
  AgentCallRequestSuccess,
  AuthenticationLoading,
  BlockedFetchFailure,
  BlockedFetchSuccess,
  BlockListingFailure,
  BlockListingSuccess,
  ChangePasswordFailure,
  ChangePasswordSuccess,
  CheckoutFormFailure,
  CheckoutFormSuccess,
  ClearBlockedListings,
  ClearComps,
  ClearFavorites,
  ClearFeed,
  ClearFixers,
  ClearInvestmentProps,
  ClearListings,
  ClearMessages,
  ClearNeighborhood,
  ClearNewUser,
  ClearRandomListings,
  ClearSchools,
  ClearSearchResults,
  ClearSignedUpMessage,
  ClearSignupCallback,
  ClearTrendingSocialListings,
  ClearUndervaluedProps,
  CloseAlertDialog,
  CloseDeleteSearchDialog,
  CloseFavoritesSuccessDialog,
  CloseFileUploadModal,
  CloseForgotDialog,
  CloseHelpDialog,
  CloseLoginDialog,
  CloseOfferDialog,
  CloseResetPasswordSuccessDialog,
  CloseSaveAndContinueDialog,
  CloseSaveAndContinueSuccessDialog,
  CloseSavedSearchPopup,
  CloseSaveSearchDialog,
  CloseScheduleShowingDialog,
  CloseSignupDialog,
  CloseSuccessDialog,
  CloseUndoSavedSearchToast,
  CloseUnsubscribeSuccessDialog,
  CloseWelcomeDialog,
  CloseWelcomeSurveyDialog,
  ClosingCostLoading,
  ClosingCostsFetch,
  ClosingCostsFetchFailure,
  ClosingCostsFetchSuccess,
  CloseYourTeamModal,
  CompsFetchFailure,
  CompsFetchStart,
  CompsFetchSuccess,
  ContactFormFailure,
  ContactFormSuccess,
  DeleteAccountFailure,
  DeleteAccountLoading,
  DeleteAccountSuccess,
  DeleteBlockedFailure,
  DeleteBlockedSuccess,
  DeleteShowingFailure,
  DeleteShowingSuccess,
  DisableQuickStartGuide,
  DisableYourNextTask,
  EListSubscriptionFailure,
  EListSubscriptionSuccess,
  FavoritesDeleteFailure,
  FavoritesDeleteSuccess,
  FavoritesFetchFailure,
  FavoritesFetchSuccess,
  FavoritesSaveFailure,
  FavoritesSaveSuccess,
  FeedEmailOptOutFailure,
  FeedEmailOptOutSuccess,
  FeedFetchFailure,
  FeedFetchSuccess,
  FeedMarkerSelect,
  FeedSearchFetchFailure,
  FeedSearchFetchSuccess,
  FeedSearchSaveFailure,
  FeedSearchSaveSuccess,
  FetchCitiesFailure,
  FetchCitiesSuccess,
  FetchingFeed,
  FetchingRecommendedListings,
  RecommendsFetchSuccess,
  RecommendsFetchFail,
  FetchSavedSearchesFailure,
  FetchSavedSearchesRequest,
  FetchSavedSearchesSuccess,
  FixersFetchFailure,
  FixersFetchSuccess,
  FixersLoading,
  ForgotPasswordFailure,
  ForgotPasswordSuccess,
  GenericError,
  HeaderSearchComplete,
  HidePreapprovedTrueModal,
  HidePreapprovedFalseModal,
  HidePreapprovedContactModal,
  HideDocumentsUploadSuccessModal,
  HomeSearchComplete,
  InitialMapLoad,
  InvestmentPropsFetchFailure,
  InvestmentPropsFetchSuccess,
  InvestmentPropsLoading,
  LinkFailure,
  ListingFetchFailure,
  ListingFetchSuccess,
  ListingLoading,
  ListingSearchFailure,
  ListingSearchSuccess,
  LoadOpeningsError,
  LoadOpeningsStart,
  LoadOpeningsSuccess,
  LoadProviderError,
  LoadProviderStart,
  LoadProviderSuccess,
  LoadTrendingSocialListings,
  LoginFailure,
  LoginSuccess,
  LogoutSuccess,
  ModifyCityBoundary,
  MonthlyMarketDataFetchFailure,
  MonthlyMarketDataFetchSuccess,
  MonthlyMarketMonthsFetchFailure,
  MonthlyMarketMonthsFetchSuccess,
  NeighborhoodFetchFailure,
  NeighborhoodFetchLoading,
  NeighborhoodFetchSuccess,
  OauthFailure,
  OauthLoginSuccess,
  OauthSignupSuccess,
  OffersFetchFailure,
  OffersFetchSuccess,
  OnboardingProfileSuccess,
  OpenAlertDialog,
  OpenDeleteSearchDialog,
  OpenFavoritesSuccessDialog,
  OpenFileUploadModal,
  OpenForgotDialog,
  OpenHelpDialog,
  OpenHousesFetchFailure,
  OpenHousesFetchSuccess,
  OpenLoginDialog,
  OpenOfferDialog,
  OpenSaveAndContinueDialog,
  OpenSaveAndContinueSuccessDialog,
  OpenSavedSearchPopup,
  OpenSaveSearchDialog,
  OpenScheduleShowingDialog,
  OpenSignupDialog,
  OpenSuccessDialog,
  OpenUndoSavedSearchToast,
  OpenWelcomeSurveyDialog,
  OpenYourTeamModal,
  PostRatingFailure,
  PostRatingSuccess,
  PricePointerFailure,
  PricePointerSuccess,
  RandomListingCountFailure,
  RandomListingCountSuccess,
  RandomListingFetchFailure,
  RandomListingFetchSuccces,
  RelatedListingsFetchFailure,
  RelatedListingsFetchStart,
  RelatedListingsFetchSuccess,
  RentFormFailure,
  RentFormSuccess,
  RequestedLenderIntro,
  ResetPasswordFailure,
  ResetPasswordSuccess,
  ResetSortedFeed,
  ScheduleMeeting,
  SchoolsFetchFailure,
  SchoolsFetchLoading,
  SchoolsFetchSuccess,
  SearchSurveyFetchFailure,
  SearchSurveyFetchSuccess,
  SearchResultsLoading,
  SendEmailVerification,
  SendPhoneVerification,
  SetBlockedLoading,
  SetFavoritesLoading,
  SetDocumentUploadComplete,
  SetRandomHomeParams,
  SetSignupPath,
  ShowEmailVerifyModal,
  ShowEmailVerifyPrompt,
  ShowPhoneVerifyModal,
  ShowPhoneVerifyPrompt,
  ShowingRequestFailure,
  ShowingRequestSuccess,
  ShowingSubmitLoading,
  ShowingDeleteLoading,
  ShowingsFetchLoading,
  ShowingsUpdateSuccess,
  ShowingsUpdateFailure,
  ShowingsFetchFailure,
  ShowingsFetchSuccess,
  ShowDocumentsUploadSuccessModal,
  SignupFailure,
  SignUpNpFailure,
  SignUpNpSuccess,
  SignUpNewFailure,
  SignUpNewSuccess,
  SignupSidebarSuccess,
  SignupSuccess,
  SignupSurveyFetchFailure,
  SignupSurveyFetchSuccess,
  SocialListingClear,
  SocialListingError,
  SocialListingFetchVotesSuccess,
  SocialListingFetchVotesFailure,
  SocialListingLeaderboardSuccess,
  SocialListingLeaderboardFailure,
  SocialListingPostDeleteSuccess,
  SocialListingPostDeleteFailure,
  SocialListingPostsFailure,
  SocialListingPostForReply,
  SocialListingPostReportClear,
  SocialListingPostReportSuccess,
  SocialListingPostReportFailure,
  SocialListingPostsSuccess,
  SocialListingRatingCheckFailed,
  SocialListingRatingCheckSuccess,
  SocialListingRatingFailure,
  SocialListingRatingSuccess,
  SocialListingStart,
  SocialListingSubmitPostSuccess,
  SocialListingSubmitPostFailure,
  SocialListingSuccess,
  SocialListingVoteDeleteSuccess,
  SocialListingVoteDeleteFailure,
  SocialListingVoteSuccess,
  SocialListingVoteFailure,
  SortedFeed,
  StoreRandomHomePreferences,
  SetShowFavoritesModal,
  SetLenderIntroductionComplete,
  SetLenderAlreadyExists,
  SetFirstShowingCompleted,
  SetFirstOfferSubmitted,
  SetSearchPreferencesCompleted,
  SetFirstSearchCompleted,
  SetFirstFavoriteCompleted,
  SetQuickStartGuideCompleted,
  SetPreferencesStarted,
  ShowPreapprovedTrueModal,
  ShowPreapprovedFalseModal,
  ShowPreapprovedContactModal,
  UnsetShowFavoritesModal,
  UnsetLenderIntroduction,
  UnsetLenderAlreadyExists,
  ToggleSurveyLoader,
  ToggleSurveyFetchLoader,
  TrendingSocialListingsFailure,
  TrendingSocialListingsSuccess,
  TrendsFormFailure,
  TrendsFormSuccess,
  ToggleQuickStartGuide,
  ToggleYourNextTask,
  TogglePhoneNumberOauthModal,
  ToggleListingModal,
  ToggleToolsButtonLoader,
  UndervaluedPropsFetchFailure,
  UndervaluedPropsFetchSuccess,
  UndervaluedPropsLoading,
  UnsetUploadComplete,
  UnsetFirstShowingCompleted,
  UnsetFirstOfferSubmitted,
  UnsetSearchPreferencesCompleted,
  UnsetFirstSearchCompleted,
  UnsetFirstFavoriteCompleted,
  UnsetQuickStartGuideCompleted,
  UnsetPreferencesStarted,
  UnlinkFailure,
  UnlinkSucces,
  UpdateCta,
  UpdateFeedSearch,
  UpdateFilterDialog,
  UpdateFilterSearch,
  UpdateProfileFailure,
  UpdateProfileSuccess,
  UpdateSavedSearchFailure,
  UpdateSavedSearchRequest,
  UpdateSavedSearchSuccess,
  UpdateSearchSurvey,
  UpdateSearchSurveyFailure,
  UpdateSearchSurveySuccess,
  UpdateSellingAddressFailure,
  UpdateSellingAddressSuccess,
  UpdateSignupDetails,
  UpdateSignupProgress,
  UpdateSignupSurvey,
  UpdateSignupSurveyFailure,
  UpdateSignupSurveySuccess,
  UpdateUtm,
  VerifyEmailSuccess,
  VerifyPhoneSuccess,
  VisitedRandomHome,
  YourTeamLoading,
  YourTeamSuccess,
  YourTeamFailure,
};
