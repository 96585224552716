import { Dispatch } from 'redux';
import fetchApi from '../services/api';
import * as types from './actionTypes';
import { mockSchools, neighborhoodData } from '.././test/mocks';
import { SchoolSearchParams } from '../lib/constants';

export const LISTINGS_FETCH_LIMIT = 18;

export function constructGreatschoolsParams(values: any) {
  let params = '';
  const paramsToCheck = [
    'lat',
    'lon',
    'school_type', // Valid values: "public", "charter", "private". The default is all.
    'level_codes', // Valid values: "e", "m", "h"
    'city', // Valid values: “Oakland”, “New York”, “Atlanta”
    'state', // Valid values: “ca”, “ga”, “wi”
    'zip', // Valid values: “94501”
    'distance',
    'limit',
  ];
  paramsToCheck.forEach(param => {
    if (values[param]) {
      params += `&${param}=${values[param]}`;
    }
  });
  return params;
}

export function clearSchools() {
  return {
    type: types.ClearSchools,
  };
}
export function fetchSchoolsLoading(schoolsLoading: boolean) {
  return {
    schoolsLoading,
    type: types.SchoolsFetchLoading,
  };
}

export function clearNeighborhood() {
  return {
    type: types.ClearNeighborhood,
  };
}
export function fetchNeighborhoodLoading(neighborhoodLoading: boolean) {
  return {
    neighborhoodLoading,
    type: types.NeighborhoodFetchLoading,
  };
}

/**
 * Return the average listing price in a given zip code.
 * @param {string} zip - A zip code
 */
export function getPricePointerData(zip: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi(`/util/price-deltas/${zip}`);
      const json = await response.json();
      if (response.ok) {
        dispatch({
          messages: [json],
          pricePoints: json.results,
          type: types.PricePointerSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.PricePointerFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch average rental data for a zip code
 * @param {Object} values - Lorem ipsum.
 */
export function submitRentForm(values: { zip: string }) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi(`/util/rents/${values.zip}`);
      const json = await response.json();
      if (response.ok) {
        dispatch({
          messages: [json],
          rents: json.results,
          type: types.RentFormSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.RentFormFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch sales and rental trend data for a zip code <br>
 * Default is 2 Bedrooms and historical data going back 12 months. <br>
 * @param {string} zip - A zip code
 * @param {number} noBedrooms - Number of bedrooms
 * @param {number} monthsBack - Number of months back to fetch for
 */
export function getTrends(
  zip: string,
  noBedrooms: number = 2,
  monthsBack: number = 12,
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi(
        `/util/zip-code-trends/${zip}?noBedrooms=${noBedrooms}&monthsBack=${monthsBack}`,
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({
          messages: [json],
          trends: json.results,
          type: types.TrendsFormSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.TrendsFormFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch fixer-upper listings
 * @param {number} offset - offset
 * @param {number} limit - how many listings to fetch
 */
export function fetchFixers(
  offset: number = 0,
  limit: number = LISTINGS_FETCH_LIMIT,
  dateTo?: Date,
  state: string = 'MA',
  reset: boolean = false,
  city?: string | undefined,
) {
  let q = '';
  if (dateTo) {
    q = `?dateTo=${dateTo}`;
  }
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    if (reset) {
      dispatch({
        type: types.FixersLoading,
      });
      dispatch({
        type: types.ClearFixers,
      });
    } else {
      dispatch({
        type: types.ToggleToolsButtonLoader,
        toggle: true,
      });
    }
    try {
      const response = await fetchApi(
        `/listings/fixers/?offset=${offset}&limit=${limit}&state=${state}${
          !!city ? `&city=${city}` : ''
        }`,
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({
          city,
          listings: json.results,
          messages: [json],
          state,
          type: types.FixersFetchSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.FixersFetchFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch high-value investment properties
 * This is used for the Fresh Finds tool
 * @param {number} offset - offset
 * @param {number} limit - how many listings to fetch
 */
export function fetchInvestmentProps(
  offset: number = 0,
  limit: number = LISTINGS_FETCH_LIMIT,
  state: string = 'MA',
  minCapRate: number = 8,
  reset: boolean = false,
  city?: string | undefined,
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });

    if (reset) {
      dispatch({
        type: types.ClearInvestmentProps,
      });
      dispatch({
        type: types.InvestmentPropsLoading,
      });
    } else {
      dispatch({
        type: types.ToggleToolsButtonLoader,
        toggle: true,
      });
    }
    try {
      const response = await fetchApi(
        `/listings/investment-props?offset=${offset}&limit=${limit}&state=${state}&minCapRate=${minCapRate}${
          !!city ? `&city=${city}` : ''
        }`,
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({
          city,
          messages: [json],
          properties: json.results,
          state,
          minCapRate,
          type: types.InvestmentPropsFetchSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.InvestmentPropsFetchFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch properties that are cheap relative to properties around them
 * This is used for the Best Buys tool
 * @param {number} offset - offset
 * @param {number} limit - how many listings to fetch
 */
export function fetchUndervaluedProps(
  offset: number = 0,
  limit: number = LISTINGS_FETCH_LIMIT,
  state: string = 'MA',
  reset: boolean = false,
  city?: string | undefined,
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    if (reset) {
      dispatch({
        type: types.UndervaluedPropsLoading,
      });
      dispatch({
        type: types.ClearUndervaluedProps,
      });
    } else {
      dispatch({
        type: types.ToggleToolsButtonLoader,
        toggle: true,
      });
    }
    try {
      const response = await fetchApi(
        `/listings/undervalued-props?offset=${offset}&limit=${limit}&state=${state}${
          !!city ? `&city=${city}` : ''
        }`,
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({
          city,
          messages: [json],
          properties: json.results,
          state,
          type: types.UndervaluedPropsFetchSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.UndervaluedPropsFetchFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch a single listing by its ID
 * @param {number} id - A listing's ID
 * @param {boolean} full - True if we want to fetch all of a listing's details
 */
export function fetchListingById(
  id: number,
  full = false,
  photosByRoom: string | undefined,
  toggleModal?: boolean,
  brightMlsDemo?: boolean,
) {
  let subRoute = 'c';
  let photosByRoomRoute = '';
  if (full) {
    subRoute = 'f';
  }
  if (photosByRoom) {
    photosByRoomRoute = `&photosByRoom=${photosByRoom}`;
  }
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    dispatch({
      type: types.ListingLoading,
    });
    if (toggleModal) {
      dispatch(toggleListingModal(true));
    }
    try {
      const response = await fetchApi(
        `/listing/id/${subRoute}/${id}?photos=photoMedium,photoLargeList${photosByRoomRoute}`,
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({
          listing: json.listing,
          messages: [json],
          type: types.ListingFetchSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.ListingFetchFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch open houses for a listing
 * @param {number} id - A listing's ID
 */
export function fetchOpenHousesByListingId(id: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearMessages,
    });
    try {
      const response = await fetchApi(`/open-houses/${id}`);
      const json = await response.json();
      if (response.ok) {
        dispatch({
          messages: [json],
          openHouses: json.results,
          type: types.OpenHousesFetchSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.OpenHousesFetchFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

let waitingTimer: NodeJS.Timeout;
/**
 * Search for listings, either by their address or their MLS ID
 * @param {type} q - The search query
 * @param {boolean} allowFallback - True if we want to fallback to a<br>
 * slower but more comprehensive search
 */
export function searchListingsByAddressOrId(q: string, allowFallback = false) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.SearchResultsLoading,
      searchResultsLoading: true,
    });
    dispatch({
      type: types.ClearMessages,
    });
    if (waitingTimer) {
      clearTimeout(waitingTimer);
    }
    // Setting Timer so if our API call is taking longer than 3 sec
    // we hide the loader and atleast show google search results
    waitingTimer = setTimeout(() => {
      dispatch({
        type: types.SearchResultsLoading,
        searchResultsLoading: false,
      });
    }, 3000);
    try {
      const response = await fetchApi(
        `/listing/search?q=${q}${allowFallback ? '&allowFallback=true' : ''}`,
      );
      const json = await response.json();
      clearTimeout(waitingTimer);
      if (response.ok) {
        dispatch({
          searchResults: json.results,
          type: types.ListingSearchSuccess,
        });
      } else {
        dispatch({
          messages: [json],
          type: types.ListingSearchFailure,
        });
      }
    } catch (error) {
      clearTimeout(waitingTimer);
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Clear search results
 */
export function clearSearchResults() {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ClearSearchResults,
    });
  };
}

export function fetchCities(state: string) {
  return async (dispatch: any) => {
    try {
      const response = await fetchApi(
        `/listings/available-cities?state=${state}`,
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({
          cities: json.results,
          type: types.FetchCitiesSuccess,
        });
      } else {
        dispatch({
          type: types.FetchCitiesFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch Schools near a listing
 * @param values - Listing's latitude and longitude
 * @param distance - Maximum radius
 * @param limit = Number of schools to fetch
 * @returns
 */
export function fetchSchools(
  values: SchoolSearchParams,
  distance: number = 5,
  limit: number = 20,
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(fetchSchoolsLoading(true));
      if (process.env.NODE_ENV !== 'production') {
        // GreatSchools allows only a certain number of requests per month based on our plan
        // So using the mock response in development mode
        dispatch({
          schools: mockSchools({}).results,
          type: types.SchoolsFetchSuccess,
        });
      } else {
        const params = constructGreatschoolsParams({
          ...values,
          distance,
          limit,
        });
        const schoolResponse = await fetchApi(`/listings/schools?${params}`);
        if (schoolResponse.ok) {
          const schoolResponseJson = await schoolResponse.json();
          dispatch({
            schools: schoolResponseJson.schools,
            type: types.SchoolsFetchSuccess,
          });
        } else {
          dispatch({
            type: types.SchoolsFetchFailure,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: types.SchoolsFetchFailure,
      });
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Fetch neighborhood score for a listing
 * @param lat - Listing's latitude
 * @param lon - Listing's longitude
 * @returns
 */
export function fetchNeighborhoodScore(
  id: number,
  mlsId: number,
  lat: number,
  lon: number,
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(fetchNeighborhoodLoading(true));
      if (process.env.NODE_ENV !== 'production') {
        // Neighborhood score APIs allow only certain number of request per month
        // So using the mock response in development mode
        dispatch({
          neighborhood: neighborhoodData({}),
          type: types.NeighborhoodFetchSuccess,
        });
      } else {
        const params = `listingId=${id}&listingMlsId=${mlsId}&lat=${lat}&lon=${lon}`;
        const scoreResponse = await fetchApi(
          `/listings/neighborhood?${params}`,
        );
        const neighborhoodData = await scoreResponse.json();
        if (scoreResponse.ok) {
          dispatch({
            neighborhood: neighborhoodData,
            type: types.NeighborhoodFetchSuccess,
          });
        } else {
          dispatch({
            type: types.NeighborhoodFetchFailure,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: types.NeighborhoodFetchFailure,
      });
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

/**
 * Toggle Listing Modal
 * @param toggle - true/false
 */
export function toggleListingModal(toggle: boolean) {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.ToggleListingModal,
      toggle: toggle,
    });
  };
}
