import * as React from 'react';
import * as Sentry from '@sentry/react';
import Router, { withRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import { isNil, noop, debounce } from 'lodash';
import { Loader, LoaderOptions } from 'google-maps';
import { Divider, Paper, TextField } from '@material-ui/core';
import { Home, Room, Search } from '@material-ui/icons';

import {
  clearSearchResults,
  searchListingsByAddressOrId,
} from '../../../actions/listing';
import {
  setupMapBounds,
  saveFeedSearch,
  updateFeedSearchHelper,
  fetchFeed,
} from '../../../actions/feed';
import SearchResultsItem from './SearchResultsItem';
import track from '../../../lib/track';
import config from '../../../services/config';
import LogoLoader from '../LogoLoader';
const SaveSearchDialog = dynamic(() => import('../../layout/SaveSearchDialog'));

// Hack for tests
declare global {
  interface Window {
    google: any;
  }
}
if (typeof window !== 'undefined') {
  window.google = window.google || {};
}

const options: LoaderOptions = {
  language: 'en',
  region: 'us',
  libraries: config.googleMaps.libraries,
};
const minCharLength = 4;
const styles = {
  resultsLabel: {
    color: '#323232',
    fontSize: 12,
    lineHeight: '14px',
    marginBottom: 12,
    marginLeft: 15,
    marginTop: 17,
    textShadow: 'none',
  },
};

export interface AutocompleteAdditionalProps {
  fullWidth: boolean;
  id: string;
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
  [x: string]: any;
}

let TIMER: NodeJS.Timeout;
const WAIT_TIME = 5000;

export interface Props {
  additionalProps: AutocompleteAdditionalProps;
  allowPlaceSearch: boolean;
  clearId?: Function;
  dispatch: any;
  feedSearch: any;
  hideSearchResults?: boolean;
  inputRef?: any;
  InputProps: any;
  onInputBlur: Function;
  onInputFocus: Function;
  paperStyles: any;
  results: Function;
  router: any;
  saveFeedSearch: Function;
  searchHistory: any[];
  searchResults: any[];
  searchResultsLoading: boolean;
  setupMapBounds: Function;
  style?: {};
  swiperView: boolean;
  token: string;
  input: any;
  meta: any;
  rest: any;
}

export interface State {
  clear: Function | undefined;
  filteredSearches: any[];
  mapsMounted: boolean;
  placeData: any[];
  searchText: string;
  showHistory: boolean;
}

export class SearchInput extends React.Component<Props, State> {
  downShiftRef: any;
  geocoder: any = undefined;
  service: any = undefined;
  _isMounted: boolean = false;
  constructor(props: any) {
    super(props);
    this.state = {
      clear: undefined,
      filteredSearches: [],
      mapsMounted: false,
      placeData: [],
      searchText:
        this.props.feedSearch.locationName && this.props.allowPlaceSearch
          ? this.props.feedSearch.locationName
          : props.searchText || '',
      showHistory: false,
    };
    this.searchListingDebouncer = debounce(this.searchListingDebouncer, 300);
    this.googlePlaceDebouncer = debounce(this.googlePlaceDebouncer, 300);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.feedSearch.locationName && this.props.allowPlaceSearch) {
      this.setState({
        searchText: this.props.feedSearch.locationName,
      });
    }
    this.loadGoogleMaps();
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.feedSearch.locationName !== this.props.feedSearch.locationName
    ) {
      this.setState({
        searchText: this.props.feedSearch.locationName,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (TIMER) {
      clearTimeout(TIMER);
    }
  }

  attachGoogleMapFunctions() {
    this.geocoder = new window.google.maps.Geocoder();
    this.service = new window.google.maps.places.AutocompleteService(null);
    if (this._isMounted)
      this.setState({
        mapsMounted: true,
      });
  }

  loadGoogleMaps = async () => {
    if (this.state.mapsMounted) {
      return;
    }
    if (window?.google?.maps?.places && window?.google?.maps?.Geocoder) {
      this.attachGoogleMapFunctions();
    } else {
      // We wait 5 seconds to see if the script loads.
      TIMER = setTimeout(async () => {
        if (window?.google?.maps?.places && window?.google?.maps?.Geocoder)
          this.attachGoogleMapFunctions();
        else {
          try {
            const loader = new Loader(config.googleMaps.apiKey, options);
            const google = await loader.load();
            window.google = google;
            setTimeout(() => {
              this.geocoder = new google.maps.Geocoder();
              this.service = new google.maps.places.AutocompleteService();
            });
            if (this._isMounted)
              this.setState({
                mapsMounted: true,
              });
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      }, WAIT_TIME);
    }
  };

  getLatLng = (locationID: number, cb: Function) => {
    this.geocoder.geocode(
      { placeId: locationID },
      (results: any, status: string) => {
        cb(results, status);
      },
    );
  };

  searchLocation = (lat: number, lng: number, name: string) => {
    const currentFeedSearch = Object.assign({}, this.props.feedSearch);
    delete currentFeedSearch.boundingBox;
    delete currentFeedSearch.location;
    delete currentFeedSearch.locationName;
    delete currentFeedSearch.polygonBoundaries;
    const valuesToSubmit = {
      location: `${lat},${lng}`,
      locationName: name,
      ...currentFeedSearch,
    };
    // If a user is logged in, save their search in the DB
    if (this.props.token) {
      this.props.dispatch(saveFeedSearch(valuesToSubmit, this.props.token));
    }

    this.props.dispatch(setupMapBounds(valuesToSubmit, !this.props.swiperView));
    if (this.props.swiperView) {
      this.props.dispatch(fetchFeed(valuesToSubmit, this.props.token));
    }
  };

  onSelectPlace = (item: any) => {
    if (item) {
      this.setState({
        searchText: item.description,
        showHistory: false,
      });
      this.getLatLng(item.place_id, (results: any) => {
        this.searchLocation(
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng(),
          results[0].formatted_address,
        );
      });
      !isNil(this.props.results)
        ? this.props.results(item, false)
        : this.getLatLng(item.place_id, (results: any) => {
            this.searchLocation(
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng(),
              results[0].formatted_address,
            );
          });
    }
  };

  onSelectListing = (listing: any) => {
    if (listing) {
      this.setState({
        clear: this.clearSelection,
        searchText: listing.address,
        showHistory: false,
      });
      this.props.results(listing, true);
    } else {
      this.setState({
        clear: undefined,
        searchText: '',
        showHistory: false,
      });
    }
  };

  onSelectRecentHistory = (searchQuery: any) => {
    this.setState({
      searchText: searchQuery.locationName,
      showHistory: false,
    });
    this.props.dispatch(updateFeedSearchHelper(searchQuery));

    if (Router.pathname === '/search') {
      this.props.dispatch(setupMapBounds(searchQuery, !this.props.swiperView));
      if (this.props.swiperView) {
        this.props.dispatch(fetchFeed(searchQuery, this.props.token));
      }
    } else {
      Router.push({
        pathname: '/search',
        query: {
          location: searchQuery.location,
        },
      });
    }
  };

  recentSearchFilter = (searchText: any = '') => {
    const { searchHistory } = this.props;
    if (searchHistory && searchHistory.length > 0) {
      const filteredSearches = searchHistory.filter((history: any) => {
        if (
          history.locationName &&
          history.locationName.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return history;
        }
      });
      this.setState({
        filteredSearches,
      });
    }
  };

  searchListingDebouncer = (searchText: string) => {
    this.props.dispatch(searchListingsByAddressOrId(searchText, false));
  };

  googlePlaceDebouncer = (searchText: string) => {
    // Only try searching with Google if we have it turned on
    // and Google Maps has finished loading
    if (this.props.allowPlaceSearch && this.state.mapsMounted) {
      this.service.getPlacePredictions(
        {
          componentRestrictions: {
            country: 'us',
          },
          input: searchText,
        },
        (predictions: any) => {
          if (predictions) {
            this.setState({ placeData: predictions });
          }
        },
      );
    } else {
      noop();
    }
  };

  onChangeSearch = (searchText: any) => {
    this.setState({
      searchText,
    });
    this.recentSearchFilter(searchText);
    if (searchText.length && searchText.length >= minCharLength) {
      // Do a search for properties on Torii
      this.searchListingDebouncer(searchText);
      // Do a search for places from google
      this.setState({ searchText }, () => {
        if (searchText.length === 0) {
          return;
        }
        this.googlePlaceDebouncer(searchText);
      });
    } else {
      this.props.dispatch(clearSearchResults());
      this.props.clearId && this.props.clearId();
      this.setState({
        placeData: [],
      });
    }
  };

  clearSelection = () => {
    if (this.state.clear) {
      this.state.clear();
    }
  };

  trackEvents = (selection: any) => {
    const { filteredSearches } = this.state;
    if (
      filteredSearches.filter(item => item.location === selection.location)
        .length > 0
    ) {
      track.buttonClick('Recent Search', this.props.router.pathname, '/search');
    }
    if (selection) {
      const { description } = selection;
      track.search(
        { query: { selection: description } },
        this.props.router.pathname,
      );
    }
  };

  onEnterPress = () => {
    const { placeData } = this.state;
    if (this.props.searchResults.length > 0) {
      this.onSelectListing(this.props.searchResults[0]);
      this.downShiftRef.closeMenu();
      return;
    }
    if (placeData.length > 0) {
      this.onSelectPlace(placeData[0]); // Select the first google place suggestion
      this.downShiftRef.closeMenu();
    }
  };

  render() {
    const {
      additionalProps,
      hideSearchResults,
      InputProps,
      inputRef,
      onInputBlur,
      onInputFocus,
      paperStyles,
      searchHistory,
      searchResults,
      searchResultsLoading,
      input,
    } = this.props;
    const { filteredSearches, placeData, searchText, showHistory } = this.state;
    return (
      <React.Fragment>
        <Downshift
          ref={r => (this.downShiftRef = r)}
          id={additionalProps.id}
          itemToString={item => (item ? item.address : '')}
          onChange={selection => {
            this.trackEvents(selection);
            if (selection) {
              if (selection.place_id) {
                this.onSelectPlace(selection);
              } else if (selection.address) {
                this.onSelectListing(selection);
              } else {
                this.onSelectRecentHistory(selection);
              }
            }
          }}
          onStateChange={({ inputValue, type }) => {
            if (
              type === Downshift.stateChangeTypes.changeInput ||
              type === Downshift.stateChangeTypes.clickItem
            ) {
              this.onChangeSearch(inputValue || '');
            }
          }}
        >
          {({ getInputProps, getItemProps, isOpen, inputValue }) => {
            return (
              <div key={this.props.feedSearch.locationName}>
                <TextField
                  {...input}
                  inputProps={{
                    ...getInputProps({
                      style: { fontSize: 16 },
                      onKeyDown: (event: any) => {
                        if (event.key === 'Enter') {
                          // Prevent Downshift's default 'Enter' behavior.
                          event.preventDefault();
                          this.onEnterPress();
                        }
                      },
                    }),
                    onBlur: () => {
                      onInputBlur();
                      this.setState({
                        showHistory: false,
                      });
                    },
                    onFocus: () => {
                      if (
                        searchHistory.length > 0 &&
                        filteredSearches.length === 0
                      ) {
                        this.recentSearchFilter();
                      }
                      this.setState({
                        showHistory: true,
                      });
                      onInputFocus();
                    },
                    value: searchText,
                  }}
                  InputLabelProps={{
                    shrink: !isNil(searchText),
                  }}
                  InputProps={InputProps}
                  inputRef={inputRef}
                  type="text"
                  {...additionalProps}
                />
                {!hideSearchResults && showHistory && (
                  <Paper style={paperStyles}>
                    {filteredSearches.length > 0 && (
                      <div
                        style={{
                          alignItems: 'flex-start',
                          backgroundColor: 'transparent',
                          border: 'none',
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 10,
                          minWidth: '100%',
                          outline: 'none',
                        }}
                      >
                        <p style={styles.resultsLabel}>Recent Searches</p>
                        <SearchResultsItem
                          avatar={<Search style={{ marginTop: 3 }} />}
                          custom
                          disconnect={false}
                          getItemProps={getItemProps}
                          items={filteredSearches}
                          noFilter
                          primary="locationName"
                          resultsToShow={3}
                          secondary=""
                          value={inputValue}
                        />
                      </div>
                    )}
                  </Paper>
                )}
                {isOpen && (
                  <Paper style={paperStyles}>
                    {searchResultsLoading && searchResults.length === 0 ? (
                      <div
                        style={{
                          alignItems: 'flex-start',
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 10,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          className="row center-xs"
                          style={{ width: '50%', marginTop: 15 }}
                        >
                          <LogoLoader imageProps={{ height: 50, width: 50 }} />
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        {searchResults.length > 0 && (
                          <div
                            style={{
                              alignItems: 'flex-start',
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: 10,
                              overflow: 'hidden',
                            }}
                          >
                            <p style={styles.resultsLabel}>Listings</p>
                            <SearchResultsItem
                              avatar={<Home style={{ marginTop: 2 }} />}
                              disconnect={false}
                              getItemProps={getItemProps}
                              items={searchResults}
                              noFilter
                              primary="address"
                              resultsToShow={3}
                              secondary="id"
                              value={inputValue}
                            />
                          </div>
                        )}
                        {searchResults.length > 0 && placeData.length > 0 && (
                          <Divider />
                        )}
                        {placeData.length > 0 && (
                          <div
                            style={{
                              alignItems: 'flex-start',
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: 10,
                            }}
                          >
                            <p style={styles.resultsLabel}>Map Results</p>
                            <SearchResultsItem
                              avatar={<Room style={{ marginTop: 3 }} />}
                              disconnect={false}
                              getItemProps={getItemProps}
                              items={placeData}
                              noFilter
                              primary="description"
                              resultsToShow={3}
                              secondary=""
                              value={inputValue}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </Paper>
                )}
              </div>
            );
          }}
        </Downshift>

        <SaveSearchDialog />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  feedSearch: state.feed.feedSearch,
  searchHistory: state.feed.searchHistory,
  searchResults: state.listings.searchResults,
  searchResultsLoading: state.listings.searchResultsLoading,
  swiperView: state.feed.swiperView,
  token: state.auth.token,
});

export default connect(mapStateToProps)(withRouter(SearchInput));
