import React, { useState } from 'react';
import { Dispatch } from 'redux';
import Router from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { CaretDown, SignOut } from 'phosphor-react';
import { logout } from '../../../actions/auth';

const useStyles = makeStyles({
  icon: {
    color: '#323232',
    width: 32,
    height: 32,
  },
  iconContainer: {
    minWidth: 40,
  },
  menuStyle: {
    borderRadius: 30,
    margin: 0,
    justifyContent: 'center',
  },
  columnMenuStyle: {
    borderRadius: 30,
    padding: 0,
    flexDirection: 'column',
    margin: 0,
    justifyContent: 'center',
  },
  paperStyle: {
    width: 200,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
  },
  listStyle: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  avatarStyle: {
    borderRadius: '50%',
    height: 40,
    width: 40,
  },
  userName: {
    color: '#323232',
    fontSize: 17,
  },
  textStyle: {
    color: '#443461',
    fontSize: 16,
    lineHeight: 24,
  },
});

export interface Props {
  dispatch: any;
  router: any;
  user: any;
}

export interface State {
  anchorEl: any;
}

export const HeaderMenu: React.FC<Props> = ({ dispatch, user, router }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleLogout = (event: any) => {
    event.preventDefault();
    dispatch(logout());
    router.push('/');
  };

  const handleMenu = (event: any) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const naivgateToAccount = () => {
    router.push('/account');
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div className="header-menu no-mobile no-tablet">
        <ButtonBase
          focusRipple
          onClick={handleMenu}
          data-aria-owns={open ? 'menu-appbar' : null}
          className="avatar-container"
        >
          <img
            className="avatar"
            data-alt="Profile"
            alt="User profile photo"
            src={user.picture || user.gravatar}
          />
          <Typography className="user-name no-mobile no-tablet">
            {user.firstName} {user.lastName}
          </Typography>
          <CaretDown
            className={`toggle-arrow ${open ? 'up' : 'down'}`}
            size={16}
            color="#FFFFFF"
            weight="fill"
          />
        </ButtonBase>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          open={open}
          onClose={handleClose}
          style={{ marginTop: 40 }}
          classes={{ paper: classes.paperStyle, list: classes.listStyle }}
        >
          <MenuItem
            onClick={naivgateToAccount}
            classes={{ root: classes.columnMenuStyle }}
          >
            <img
              className={classes.avatarStyle}
              data-alt="Profile"
              alt="User profile photo"
              src={user.picture || user.gravatar}
            />
            <Typography className={classes.userName}>Settings</Typography>
          </MenuItem>
          <div className="header-menu-divider" />
          <MenuItem
            classes={{ root: classes.menuStyle }}
            onClick={handleLogout}
          >
            <ListItemIcon classes={{ root: classes.iconContainer }}>
              <SignOut size={24} color="#443461" weight="bold" />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.textStyle }}
              primary="Sign Out"
            />
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  );
};

export default HeaderMenu;
