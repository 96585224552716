import * as React from 'react';
import Link from 'next/link';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Button, Toolbar, Select, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  CurrencyCircleDollar,
  House,
  CaretDown,
  CaretUp,
  NotePencil,
  Wrench,
} from 'phosphor-react';
import HeaderSearch from './HeaderSearch';
import HeaderMenu from './HeaderMenu';
import WelcomeDialog from '../WelcomeDialog';
import * as types from '../../../actions/actionTypes';
import { logout } from '../../../actions/auth';
import { isMobile, isTablet } from '../../../lib/getDeviceSize';
import EventEmitter from '../../../lib/eventEmitter';

export interface Props {
  classes: any;
  dispatch: any;
  feedSearch: any;
  router: any;
  token: string | null;
  user: any;
}

export interface State {
  width: number;
  height: number;
  searchHidden: boolean;
  navigationShow: boolean;
  buyDropdownChoice: string;
  showDropDown: boolean;
}

const styles = {
  icon: {
    color: 'white',
    width: 32,
    height: 32,
  },
  appHeader: {
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 97,
  },
  toolHeader: {
    boxShadow: 'none',
    minHeight: isTablet() || isMobile() ? 64 : 86,
  },
  toolHeaderSearch: {
    boxShadow: 'none',
    minHeight: 64,
  },
};

export class Header extends React.Component<Props, State> {
  // Pages where we want the header search to be hidden
  searchHiddenRoutes = [
    '/',
    '/about',
    '/buy',
    '/buying-with-torii',
    '/careers',
    '/contact',
    '/get-preapproved',
    '/join',
    '/reviews',
    '/search-homes',
    '/sell',
    '/welcome',
    '/tools',
    '/tools/mortgage-calculator',
    '/tools/affordability-calculator',
  ];
  // Pages where we want the header navigation list can be seen.
  navigationShowRoutes = [
    '/',
    '/join',
    '/tools',
    '/tools/mortgage-calculator',
    '/tools/affordability-calculator',
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
      searchHidden: false,
      navigationShow: false,
      showDropDown: false,
      buyDropdownChoice: '',
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
    this.setState({
      searchHidden:
        this.searchHiddenRoutes.indexOf(this.props.router.pathname) >= 0,
      navigationShow:
        this.navigationShowRoutes.indexOf(this.props.router.pathname) >= 0,
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.router.pathname !== this.props.router.pathname) {
      this.setState({
        searchHidden:
          this.searchHiddenRoutes.indexOf(this.props.router.pathname) >= 0,
        navigationShow:
          this.navigationShowRoutes.indexOf(this.props.router.pathname) >= 0,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  handleLogout(event: any) {
    event.preventDefault();
    this.props.dispatch(logout());
  }

  handleQuiz = () => {
    if (this.props.token) {
      this.props.router.push({
        pathname: '/survey',
        query: {
          stage: 'info',
          part: 'basics',
          question: 'cover',
        },
      });
    } else {
      const { asPath } = this.props.router;
      const usePath = asPath.includes('/search') ? '/search' : asPath;
      this.props.router.push({
        pathname: '/signup/onboarding',
        query: {
          stage: 'intro',
          question: 'cover',
          signupPath: encodeURI(usePath),
        },
      });
    }
  };

  openLogin = () => {
    this.props.dispatch({
      type: types.OpenLoginDialog,
      // Navigate to Dashboard if loggedin from HomePage
      postAuthPath: this.props.router.pathname === '/' ? '/dashboard' : null,
    });
  };

  openSignup = () => {
    const { asPath } = this.props.router;
    const usePath = asPath.includes('/search') ? '/search' : asPath;
    // this.props.router.push({
    //   pathname: '/signup/onboarding',
    //   query: {
    //     stage: 'intro',
    //     question: 'cover',
    //     signupPath: encodeURI(usePath),
    //   },
    // });
    this.props.dispatch({
      signupPath: encodeURI(usePath),
      type: types.SetSignupPath,
    });
    this.props.dispatch({
      type: types.OpenSignupDialog,
    });
  };

  toggleWelcomeDialog = () => {
    this.props.dispatch({
      type: types.ScheduleMeeting,
    });
  };

  handleChange = (event: any) => {
    if (event.target.value === 'survey') {
      this.handleQuiz();
    } else {
      this.setState(
        {
          buyDropdownChoice: event.target.value,
        },
        () => {
          this.props.router.push({
            pathname: this.state.buyDropdownChoice,
          });
        },
      );
    }
  };

  handleDropdownShow = () => {
    this.setState({
      showDropDown: !this.state.showDropDown,
    });
  };

  render() {
    const { classes, router, token } = this.props;
    const rightNav = (
      <React.Fragment>
        <div className="no-desktop-small no-desktop no-desktop-xl">
          <Button
            className="dashboard-toggle-icon-button"
            onClick={() => EventEmitter.emit('openSidebarMenu')}
          >
            <MenuIcon className={classes.icon} />
          </Button>
        </div>
        <ul className="list-inline no-mobile no-tablet">
          {token ? (
            <HeaderMenu
              user={this.props.user}
              dispatch={this.props.dispatch}
              router={router}
            />
          ) : (
            <React.Fragment>
              <li className="no-mobile no-tablet">
                <Button
                  color="primary"
                  className="header-link"
                  onClick={() => {
                    this.openLogin();
                  }}
                >
                  Log In
                </Button>
              </li>
              <li className="no-mobile no-tablet">
                <Button
                  color="primary"
                  className="header-link signup-link"
                  onClick={() => {
                    this.openSignup();
                  }}
                >
                  Create Account
                </Button>
              </li>
            </React.Fragment>
          )}
        </ul>
      </React.Fragment>
    );

    return (
      <AppBar
        position="static"
        className="header"
        classes={{
          root: classes.appHeader,
        }}
      >
        <Toolbar className="row between-xs toolHeader">
          <div className="row middle-xs" style={{ flexWrap: 'nowrap' }}>
            <Link href={token ? '/dashboard' : '/'}>
              <a aria-label="Torii Homepage" className="logo" />
            </Link>
            {!this.state.searchHidden ? (
              <div>
                <HeaderSearch dispatch={this.props.dispatch} />
              </div>
            ) : !token || this.state.navigationShow ? (
              <div className="nav-links-container no-mobile no-tablet">
                <div className="nav-dropdown">
                  <Link href="/buy">
                    <a className="buy-option-label">Buy</a>
                  </Link>
                  <Select
                    labelId="buy-option-label"
                    id="buy-option"
                    onChange={this.handleChange}
                    label="Buy"
                    value=""
                    open={this.state.showDropDown}
                    onClose={() => this.handleDropdownShow()}
                    MenuProps={{
                      className: 'nav-links-select',
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom',
                      },
                    }}
                    IconComponent={() =>
                      this.state.showDropDown ? (
                        <CaretUp
                          onClick={() => this.handleDropdownShow()}
                          size={32}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                        />
                      ) : (
                        <CaretDown
                          onClick={() => this.handleDropdownShow()}
                          size={32}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                        />
                      )
                    }
                    disableUnderline
                  >
                    <MenuItem value={'buy'}>
                      <div className="nav-dropdown-link">
                        <House size={24} color="#463264" weight="duotone" />
                        <span>Buy With Torii</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={'get-preapproved'}>
                      <div className="nav-dropdown-link">
                        <CurrencyCircleDollar
                          size={24}
                          color="#463264"
                          weight="duotone"
                        />
                        <span>Get Preapproved</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={'survey'}>
                      <div className="nav-dropdown-link">
                        <NotePencil
                          size={24}
                          color="#463264"
                          weight="duotone"
                        />

                        <span>Personalization Quiz</span>
                      </div>
                    </MenuItem>
                    <MenuItem value={'tools'} className="nav-dropdown-link">
                      <div className="nav-dropdown-link">
                        <Wrench size={24} color="#463264" weight="duotone" />
                        <span>Homebuying Tools</span>
                      </div>
                    </MenuItem>
                  </Select>
                </div>
                <Link href="/sell">
                  <h4 className="nav-links">Sell</h4>
                </Link>
                <Link href="/search-homes">
                  <h4 className="nav-links">Search</h4>
                </Link>
                <Link href="/join">
                  <h4 className="nav-links">For Agents</h4>
                </Link>
                <Link href="/about">
                  <h4 className="nav-links">About</h4>
                </Link>
                <Link href="/buying-with-torii?focus=faq">
                  <h4 className="nav-links">FAQ</h4>
                </Link>
              </div>
            ) : null}
            <WelcomeDialog />
          </div>
          <div className="right-nav-container">{rightNav}</div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state: any) => ({
  feedSearch: state.feed.feedSearch,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(Header)));
