import { removeFromArrayOfObjects } from '../lib/helpers';
import * as types from '../actions/actionTypes';

const initialState = {
  favorites: [],
  favoritesFetched: false,
  favoritesLoading: false,
  showFavoritesModal: true,
};

/**
 * State management for data related to user favorites
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function favorites(state: any = initialState, action: any) {
  switch (action.type) {
    case types.FavoritesSaveSuccess:
      return Object.assign({}, state, {
        favorites: [action.listing].concat(state.favorites),
      });
    case types.SetFavoritesLoading:
      return Object.assign({}, state, {
        favoritesLoading: true,
      });
    case types.FavoritesDeleteSuccess:
      return Object.assign({}, state, {
        favorites: removeFromArrayOfObjects(
          state.favorites,
          'id',
          action.deletedListing.id,
        ),
      });
    case types.FavoritesFetchSuccess:
      return Object.assign({}, state, {
        favorites: state.favorites.concat(action.favorites),
        favoritesFetched: true,
        favoritesLoading: false,
      });
    case types.ClearFavorites:
      return Object.assign({}, state, {
        favorites: [],
        favoritesFetched: false,
      });
    case types.SetShowFavoritesModal:
      return Object.assign({}, state, {
        showFavoritesModal: true,
      });
    case types.UnsetShowFavoritesModal:
      return Object.assign({}, state, {
        showFavoritesModal: false,
      });
    case types.FavoritesFetchFailure:
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
