import * as types from '../actions/actionTypes';

const initialState = {
  showings: [],
  showingsLoading: true,
  showingsSubmitLoading: false,
  showingDeleteLoading: false,
};

/**
 * State management for data related to user showings
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function showings(state: any = initialState, action: any) {
  switch (action.type) {
    case types.ShowingsFetchLoading:
      return Object.assign({}, state, {
        showingsLoading: action.showingsLoading,
      });
    case types.ShowingsFetchSuccess:
      return Object.assign({}, state, {
        showings: action.showings,
        showingsLoading: false,
      });
    case types.ShowingsFetchFailure:
      return Object.assign({}, state, {
        showings: [],
        showingsLoading: false,
      });
    case types.ShowingSubmitLoading:
      return Object.assign({}, state, {
        showingsSubmitLoading: action.submitLoading,
      });
    case types.ShowingDeleteLoading:
      return Object.assign({}, state, {
        showingDeleteLoading: action.showingDeleteLoading,
      });
    case types.ShowingRequestSuccess:
      return Object.assign({}, state, {
        showingsSubmitLoading: false,
        showings: action.showings,
      });
    case types.ShowingRequestFailure:
      return Object.assign({}, state, {
        showingsSubmitLoading: false,
      });
    case types.ShowingsUpdateSuccess:
      return Object.assign({}, state, {
        showings: action.showings,
        showingsSubmitLoading: false,
      });
    case types.ShowingsUpdateFailure:
      return Object.assign({}, state, {
        showingsSubmitLoading: false,
      });
    case types.DeleteShowingSuccess:
      return Object.assign({}, state, {
        showingDeleteLoading: false,
      });
    case types.DeleteShowingFailure:
      return Object.assign({}, state, {
        showingDeleteLoading: false,
      });

    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
