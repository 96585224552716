import { Dispatch } from 'redux';
import * as Sentry from '@sentry/react';

import fetchApi from '../services/api';
import track from '../lib/track';
import * as types from './actionTypes';
import { forceLoginHelper } from './auth';
import { checkIfSurveyCompleted } from '../lib/helpers';

const endPoints = {
  fetchSearchSurvey: '/get-search-survey',
  saveSearchSurvey: '/save-search-survey',
};

export function fetchSearchSurvey(token: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchApi(
        endPoints.fetchSearchSurvey,
        {},
        'get',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        const { results } = json;
        if (Object.keys(results).length === 0) {
          // Do not bring up old survey when login
          // return setTimeout(() => {
          //   dispatch({
          //     type: types.OpenWelcomeSurveyDialog,
          //   });
          // }, 1000);
        } else {
          return dispatch({
            type: types.SearchSurveyFetchSuccess,
            values: results,
          });
        }
      } else {
        return dispatch(
          forceLoginHelper(json, () => {
            if (response.status === 400) {
              dispatch({
                message: json.msg,
                type: types.SearchSurveyFetchFailure,
              });
            }
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}

export function saveSearchSurvey(values: any, token: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: types.UpdateSearchSurvey,
        values,
      });
      const response = await fetchApi(
        endPoints.saveSearchSurvey,
        { ...values, partial: true },
        'post',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({
          type: types.UpdateSearchSurveySuccess,
        });
        const surveyCompleted = checkIfSurveyCompleted(values);
        if (surveyCompleted) {
          // Don't blow up if we have an issue with tracking
          try {
            track.completeSearchSurvey(values);
          } catch (error) {
            Sentry.captureException(error);
          }
        }
        return dispatch({
          type: types.CloseWelcomeSurveyDialog,
        });
      } else {
        return dispatch(
          forceLoginHelper(json, () => {
            dispatch({
              type: types.CloseWelcomeSurveyDialog,
            });
            dispatch({
              message: json.msg,
              type: types.UpdateSearchSurveyFailure,
            });
          }),
        );
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
}
