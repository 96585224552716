import { createTheme } from '@material-ui/core/styles';

// Creates a Material-UI theme instance.
const theme = createTheme({
  palette: {
    primary: {
      light: '#533b80',
      main: '#443461',
      dark: '#302347',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ff80ab',
      main: '#f3a4bf',
      dark: '#f50057',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  overrides: {
    MuiButton: {
      text: {
        fontFamily: 'Poppins !important',
        letterSpacing: '0 !important',
      },
    },
  },
});

export default theme;
