import * as Sentry from '@sentry/react';
import * as types from '../actions/actionTypes';

const initialState = {};

/**
 * State management for errors
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function errors(state: any = initialState, action: any) {
  switch (action.type) {
    case types.GenericError:
      // Report all GenericError's to Sentry, because these should
      // be cases where we don't know what happened
      Sentry.captureException(action.error);
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
