import * as types from '../actions/actionTypes';

const initialState = {
  cities: [],
  fixers: [],
  fixersCity: undefined,
  fixersFetched: false,
  fixersLoading: false,
  fixersState: 'MA',
  investmentProps: [],
  investmentPropsCity: undefined,
  investmentPropsFetched: false,
  investmentPropsLoading: false,
  investmentPropsMinCapRate: 8,
  investmentPropsState: 'MA',
  listing: {},
  listingFetched: false,
  listingLoading: false,
  listings: [],
  neighborhood: undefined,
  neighborhoodLoading: false,
  openHouses: [],
  pricePoints: [],
  rents: {},
  searchResults: [],
  searchResultsLoading: false,
  schools: [],
  schoolsLoading: false,
  trends: [],
  undervaluedProps: [],
  undervaluedPropsCity: undefined,
  undervaluedPropsFetched: false,
  undervaluedPropsLoading: false,
  undervaluedPropsState: 'MA',
  listingModalOpen: false,
  loadingMoreListings: false,
};

/**
 * State management for data related to listings.
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function listings(state: any = initialState, action: any) {
  switch (action.type) {
    case types.ClearSearchResults:
      return Object.assign({}, state, {
        searchResults: [],
      });
    case types.ClearFixers:
      return Object.assign({}, state, {
        fixers: [],
      });
    case types.ClearListings:
      return Object.assign({}, state, {
        listing: {},
        listingFetched: false,
        listingLoading: false,
        listings: [],
      });
    case types.ClearInvestmentProps:
      return Object.assign({}, state, {
        investmentProps: [],
      });
    case types.ClearUndervaluedProps:
      return Object.assign({}, state, {
        undervaluedProps: [],
      });
    case types.FixersFetchFailure:
      return Object.assign({}, state, {
        fixers: [],
        fixersFetched: false,
        fixersLoading: false,
        loadingMoreListings: false,
      });
    case types.FixersFetchSuccess:
      return Object.assign({}, state, {
        fixers: state.fixers.concat(action.listings),
        fixersCity: action.city,
        fixersFetched: true,
        fixersLoading: false,
        loadingMoreListings: false,
        fixersState: action.state,
      });
    case types.FixersLoading:
      return Object.assign({}, state, {
        fixersLoading: true,
      });
    case types.InvestmentPropsFetchFailure:
      return Object.assign({}, state, {
        investmentProps: [],
        investmentPropsFetched: false,
        investmentPropsLoading: false,
        loadingMoreListings: false,
      });
    case types.InvestmentPropsFetchSuccess:
      return Object.assign({}, state, {
        investmentProps: state.investmentProps.concat(action.properties),
        investmentPropsCity: action.city,
        investmentPropsFetched: true,
        investmentPropsLoading: false,
        loadingMoreListings: false,
        investmentPropsMinCapRate: action.minCapRate,
        investmentPropsState: action.state,
      });
    case types.InvestmentPropsLoading:
      return Object.assign({}, state, {
        investmentPropsLoading: true,
      });
    case types.ListingFetchSuccess:
      return Object.assign({}, state, {
        listing: action.listing,
        listingFetched: true,
        listingLoading: false,
      });
    case types.ListingFetchFailure:
      return Object.assign({}, state, {
        listing: {},
        listingFetched: true,
        listingLoading: false,
      });
    case types.ListingLoading:
      return Object.assign({}, state, {
        listingLoading: true,
      });
    case types.SearchResultsLoading:
      return Object.assign({}, state, {
        searchResultsLoading: action.searchResultsLoading,
      });
    case types.ListingSearchSuccess:
      return Object.assign({}, state, {
        searchResultsLoading: false,
        searchResults: action.searchResults,
      });
    case types.ListingSearchFailure:
      return Object.assign({}, state, {
        searchResultsLoading: false,
        searchResults: [],
      });
    case types.OpenHousesFetchFailure:
      return Object.assign({}, state, {
        openHouses: {},
      });
    case types.OpenHousesFetchSuccess:
      return Object.assign({}, state, {
        openHouses: action.openHouses,
      });
    case types.PricePointerFailure:
      return Object.assign({}, state, {
        pricePoints: [],
      });
    case types.PricePointerSuccess:
      return Object.assign({}, state, {
        pricePoints: action.pricePoints,
      });
    case types.RentFormFailure:
      return Object.assign({}, state, {
        rents: {},
      });
    case types.RentFormSuccess:
      return Object.assign({}, state, {
        rents: action.rents,
      });
    case types.TrendsFormFailure:
      return Object.assign({}, state, {
        trends: [],
      });
    case types.TrendsFormSuccess:
      return Object.assign({}, state, {
        trends: action.trends,
      });
    case types.UndervaluedPropsFetchFailure:
      return Object.assign({}, state, {
        undervaluedProps: [],
        undervaluedPropsFetched: false,
        undervaluedPropsLoading: false,
        loadingMoreListings: false,
      });
    case types.UndervaluedPropsFetchSuccess:
      return Object.assign({}, state, {
        undervaluedProps: state.undervaluedProps.concat(action.properties),
        undervaluedPropsCity: action.city,
        undervaluedPropsFetched: true,
        undervaluedPropsLoading: false,
        loadingMoreListings: false,
        undervaluedPropsState: action.state,
      });
    case types.UndervaluedPropsLoading:
      return Object.assign({}, state, {
        undervaluedPropsLoading: true,
      });
    case types.FetchCitiesSuccess:
      return Object.assign({}, state, {
        cities: action.cities,
      });
    case types.FetchCitiesFailure:
      return Object.assign({}, state, {
        cities: [],
      });
    case types.ClearSchools:
      return Object.assign({}, state, {
        schools: [],
      });
    case types.SchoolsFetchLoading:
      return Object.assign({}, state, {
        schoolsLoading: action.schoolsLoading,
      });
    case types.SchoolsFetchSuccess:
      return Object.assign({}, state, {
        schools: action.schools,
        schoolsLoading: false,
      });
    case types.SchoolsFetchFailure:
      return Object.assign({}, state, {
        schools: [],
        schoolsLoading: false,
      });
    case types.ClearNeighborhood:
      return Object.assign({}, state, {
        neighborhood: undefined,
      });
    case types.NeighborhoodFetchLoading:
      return Object.assign({}, state, {
        neighborhoodLoading: action.neighborhoodLoading,
      });
    case types.NeighborhoodFetchSuccess:
      return Object.assign({}, state, {
        neighborhood: action.neighborhood,
        neighborhoodLoading: false,
      });
    case types.NeighborhoodFetchFailure:
      return Object.assign({}, state, {
        neighborhood: undefined,
        neighborhoodLoading: false,
      });
    case types.ToggleListingModal:
      return Object.assign({}, state, {
        listingModalOpen: action.toggle,
      });
    case types.ToggleToolsButtonLoader:
      return Object.assign({}, state, {
        loadingMoreListings: action.toggle,
      });

    case types.LogoutSuccess:
      return Object.assign({}, initialState, {
        listing: state.listing,
        neighborhood: state.neighborhood,
        pricePoints: state.pricePoints,
        listingFetched: state.listingFetched,
        rents: state.rents,
        schools: state.schools,
      });
    default:
      return state;
  }
}
