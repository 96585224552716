import * as React from 'react';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import track from '../../../lib/track';
import { isMobile } from '../../../lib/getDeviceSize';
import { schedulingLink } from '../../../lib/constants';
import { buttonStyles } from '../../../styles/base';

import styles from '../../../styles/sass/components/WelcomeDialog.module.scss';
import { formatUtmParams } from '../../../lib/helpers';

export interface Props {
  classes: any;
  closeModal: Function;
  newUser: boolean;
  scheduleMeeting?: boolean;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
}

export interface State {
  calling: boolean;
  tracked: boolean;
}

export class WelcomeDialogContents extends React.Component<Props, State> {
  state = {
    calling: false,
    tracked: false,
  };

  setCalling = () => {
    this.setState({ calling: true });
  };

  componentDidMount() {
    const { newUser } = this.props;
    if (!this.state.tracked) {
      track.modalView(
        `Welcome Dialog: ${newUser ? 'Account Created!' : 'Schedule a Call'}`,
      );
      this.setState({ tracked: true });
    }
  }

  handleSchedule = () => {
    const utmParams = formatUtmParams(this.props);
    window.open(`${schedulingLink}${utmParams}`, '_blank');
  };

  render() {
    const { classes, closeModal, newUser } = this.props;
    const { calling } = this.state;
    const greetings = newUser ? 'Account Created!' : 'Schedule a Call';

    if (newUser) {
      return (
        <div className={`row ${styles.accountCreatedDialogInner}`}>
          <div
            className={`col-xs-12 col-sm-6 row center-xs middle-xs ${styles.accountCreatedHeader}`}
          >
            <div className={styles.accountCreatedHeaderText}>
              <h2>{greetings}</h2>
              <p>
                Thanks for signing up. For the best home buying experience, make
                sure to download the Torii app.
              </p>
            </div>
          </div>
          <div
            className={`col-xs-12 col-sm-6 row center-xs middle-xs ${styles.accountCreatedStoreContainer}`}
          >
            <div
              className={`col-xs-12 row center-xs between-xs ${styles.appButtonsContainer}`}
            >
              <Button
                className={styles.linkContainer}
                onClick={() =>
                  track.buttonClick(
                    'App Store Link',
                    'Home',
                    'https://itunes.apple.com/us/app/torii-real-estate/id1362196303?ls=1&mt=8',
                  )
                }
              >
                <a
                  data-alt="iOS link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://itunes.apple.com/us/app/torii-real-estate/id1362196303?ls=1&mt=8"
                  className={`${styles.getStartedButton} ${styles.iosButton}`}
                >
                  Torii for iOS
                </a>
              </Button>
              <Button
                className={styles.linkContainer}
                onClick={() =>
                  track.buttonClick(
                    'Google Play Link',
                    'Home',
                    'https://play.google.com/store/apps/details?id=com.toriiapp.android',
                  )
                }
              >
                <a
                  data-alt="Android link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.toriiapp.android"
                  className={`${styles.getStartedButton} ${styles.androidButton}`}
                >
                  Torii for Android
                </a>
              </Button>
              <Button
                onClick={() => closeModal()}
                style={{
                  backgroundColor: 'transparent',
                  marginTop: isMobile() ? 25 : 40,
                  width: 164,
                }}
              >
                <Typography
                  style={{ letterSpacing: 1.75, textTransform: 'capitalize' }}
                >
                  MAYBE LATER
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className={`col-xs-12 row center-xs middle-xs ${styles.welcomeDialogInner}`}
      >
        <div className={styles.welcomeDialogBg}>
          <div className={styles.bgLower} />
        </div>
        {!calling && (
          <div className={styles.welcomeDialogPrompt}>
            <div className={styles.scheduleCallIcon}>📱</div>
            <h1>{greetings}</h1>
            <p className={styles.areYouReady}>
              Are you ready to start your home search?
            </p>
            <div
              className={`col-xs-12 row center-xs between-xs ${styles.buttonsContainer}`}
            >
              <div className={styles.buttonWrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={`${styles.welcomeButton} ${classes.buttonMedium}`}
                  onClick={this.handleSchedule}
                >
                  Schedule a Call
                </Button>
              </div>
              <a
                data-alt="Schedule a Call"
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+1-617-213-0223"
                className={`${styles.buttonWrapper} ${styles.mobileOnly}`}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={`${styles.welcomeButton} ${styles.first} ${classes.buttonMedium}`}
                  onClick={() => this.setCalling()}
                >
                  Text or Call Us
                </Button>
              </a>
            </div>
            <p className={styles.subtext}>
              Maybe later,{' '}
              <Button
                className={styles.closeModalText}
                onClick={() => closeModal()}
              >
                take me back
              </Button>{' '}
              where I was...
            </p>
          </div>
        )}
        {calling && (
          <div className={styles.welcomeDialogPrompt}>
            <div className={styles.phoneIcon} />
            <a
              data-alt="Schedule a Call"
              target="_blank"
              rel="noopener noreferrer"
              href="tel:+1-617-213-0223"
              className={styles.phoneWrapper}
            >
              <h1>(617) 213-0223</h1>
            </a>
            <p className={styles.callText}>
              Great! Text or call the number above to be connected to a Torii
              real estate expert!
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  utmCampaign: state.auth.utmCampaign,
  utmMedium: state.auth.utmMedium,
  utmSource: state.auth.utmSource,
  utmTerm: state.auth.utmTerm,
});

export default withStyles(buttonStyles)(
  connect(mapStateToProps)(WelcomeDialogContents),
);
