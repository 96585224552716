const config = {
  amplitudeAnalyticsKey: '1a39175aa65ddbea165e684821c60d04',
  googleMaps: {
    apiKey: 'AIzaSyDtJi_IiieR6SPFj5XqRI-Ytob8Mcx06Uc',
    libraries: ['geometry', 'drawing', 'places'],
  },
  mixpanelAnalyticsKey: '862ed083e5724b72c5aece949538de98',
  // The key below is for the Torii - Dev mixpanel project
  mixpanelDev: '71858775b0700772f67c279af74de1cd',
};

export default config;
