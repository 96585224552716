import * as types from '../actions/actionTypes';

export const defaultState = 'MA';
export const defaultMaxPrice = 800000;

const initialState = {
  count: 10,
  listingFetched: false,
  listings: [],
  location: 'MA',
  maxPrice: 800000,
  visited: false,
};

/**
 * State mangement for random listing data. Used for the Random Home tool.
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function randomHome(state: any = initialState, action: any) {
  switch (action.type) {
    case types.StoreRandomHomePreferences:
      return Object.assign({}, state, {
        location: action.location,
        maxPrice: action.maxPrice,
      });
    case types.RandomListingCountSuccess:
      return Object.assign({}, state, {
        count: action.count,
        location: action.location,
        maxPrice: action.maxPrice,
      });
    case types.RandomListingCountFailure:
      return Object.assign({}, state, {
        count: 10,
        location: defaultState,
        maxPrice: defaultMaxPrice,
      });
    case types.RandomListingFetchSuccces:
      return Object.assign({}, state, {
        listingFetched: true,
        listings: state.listings.concat(action.listings),
      });
    case types.SetRandomHomeParams:
      return Object.assign({}, state, {
        maxPrice: action.maxPrice,
        randomHomeState: action.randomHomeState,
      });
    case types.VisitedRandomHome:
      return Object.assign({}, state, {
        visited: true,
      });
    case types.RandomListingFetchFailure:
    case types.ClearRandomListings:
      return Object.assign({}, state, {
        listingFetched: false,
        listings: [],
      });
    default:
      return state;
  }
}
