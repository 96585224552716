import * as types from '../actions/actionTypes';

const initialState = {
  showEmailVerifyPrompt: false,
  showPhoneVerifyPrompt: false,
  emailVerificationSent: false,
  phoneVerificationSent: false,
  searchPreferencesCompleted: false,
  firstSearchCompleted: false,
  firstFavoriteCompleted: false,
  quickStartCompleted: false,
  showEmailVerifyModal: false,
  showPhoneVerifyModal: false,
  showPreapprovedTrueModal: false,
  showPreapprovedFalseModal: false,
  showPreapprovedContactModal: false,
  showDocumentUploadSuccessModal: false,
  lenderAlreadyIntroduced: false,
  lenderIntroductionComplete: false,
  fileUploadModalOpen: false,
  documentUploadComplete: false,
  selectedAgent: {},
  yourTeamModalOpen: false,
  firstOfferSubmitted: false,
  firstShowingScheduled: false,
  showQuickStartGuide: true,
  showYourNextTask: true,
  yourTeamLoading: false,
  yourTeam: [],
  recommendsLoading: false,
  recommendedListings: [],
  preferencesStarted: false,
  showPhoneNumberOauthModal: false,
};

/**
 * State management for dashboard
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function dashboard(state: any = initialState, action: any) {
  switch (action.type) {
    case types.FetchingRecommendedListings:
      return Object.assign({}, state, {
        recommendsLoading: action.recommendsLoading,
      });
    case types.RecommendsFetchSuccess:
      return Object.assign({}, state, {
        recommendsLoading: false,
        recommendedListings: state.recommendedListings.concat(
          action.recommendedListings,
        ),
      });
    case types.RecommendsFetchFail:
      return Object.assign({}, state, {
        recommendsLoading: false,
      });
    case types.ShowPreapprovedTrueModal:
      return Object.assign({}, state, {
        showPreapprovedTrueModal: true,
      });
    case types.ShowPreapprovedFalseModal:
      return Object.assign({}, state, {
        showPreapprovedFalseModal: true,
      });
    case types.ShowPreapprovedContactModal:
      return Object.assign({}, state, {
        showPreapprovedContactModal: true,
      });
    case types.HidePreapprovedTrueModal:
      return Object.assign({}, state, {
        showPreapprovedTrueModal: false,
      });
    case types.HidePreapprovedFalseModal:
      return Object.assign({}, state, {
        showPreapprovedFalseModal: false,
      });
    case types.HidePreapprovedContactModal:
      return Object.assign({}, state, {
        showPreapprovedContactModal: false,
      });
    case types.UnsetLenderIntroduction:
      return Object.assign({}, state, {
        lenderIntroductionComplete: false,
      });
    case types.SetLenderIntroductionComplete:
      return Object.assign({}, state, {
        lenderIntroductionComplete: true,
      });
    case types.UnsetLenderAlreadyExists:
      return Object.assign({}, state, {
        lenderAlreadyIntroduced: false,
      });
    case types.SetLenderAlreadyExists:
      return Object.assign({}, state, {
        lenderAlreadyIntroduced: true,
      });
    case types.OpenFileUploadModal:
      return Object.assign({}, state, {
        fileUploadModalOpen: true,
      });
    case types.CloseFileUploadModal:
      return Object.assign({}, state, {
        fileUploadModalOpen: false,
      });
    case types.ShowDocumentsUploadSuccessModal:
      return Object.assign({}, state, {
        showDocumentUploadSuccessModal: true,
      });
    case types.HideDocumentsUploadSuccessModal:
      return Object.assign({}, state, {
        showDocumentUploadSuccessModal: false,
      });
    case types.SetDocumentUploadComplete:
      return Object.assign({}, state, {
        documentUploadComplete: true,
      });
    case types.UnsetUploadComplete:
      return Object.assign({}, state, {
        documentUploadComplete: false,
      });
    case types.OpenYourTeamModal:
      return Object.assign({}, state, {
        selectedAgent: action.agent,
        yourTeamModalOpen: true,
      });
    case types.CloseYourTeamModal:
      return Object.assign({}, state, {
        selectedAgent: undefined,
        yourTeamModalOpen: false,
      });
    case types.SetFirstOfferSubmitted:
      return Object.assign({}, state, {
        firstOfferSubmitted: true,
      });
    case types.UnsetFirstOfferSubmitted:
      return Object.assign({}, state, {
        firstOfferSubmitted: false,
      });
    case types.SetFirstShowingCompleted:
      return Object.assign({}, state, {
        firstShowingScheduled: true,
      });
    case types.UnsetFirstShowingCompleted:
      return Object.assign({}, state, {
        firstShowingScheduled: false,
      });
    case types.ToggleQuickStartGuide:
      return Object.assign({}, state, {
        showQuickStartGuide: true,
      });
    case types.DisableQuickStartGuide:
      return Object.assign({}, state, {
        showQuickStartGuide: false,
      });
    case types.ToggleYourNextTask:
      return Object.assign({}, state, {
        showYourNextTask: true,
      });
    case types.DisableYourNextTask:
      return Object.assign({}, state, {
        showYourNextTask: false,
      });
    case types.SetSearchPreferencesCompleted:
      return Object.assign({}, state, {
        searchPreferencesCompleted: true,
      });
    case types.UnsetSearchPreferencesCompleted:
      return Object.assign({}, state, {
        searchPreferencesCompleted: false,
      });
    case types.SetFirstSearchCompleted:
      return Object.assign({}, state, {
        firstSearchCompleted: true,
      });
    case types.UnsetFirstSearchCompleted:
      return Object.assign({}, state, {
        firstSearchCompleted: false,
      });
    case types.SetFirstFavoriteCompleted:
      return Object.assign({}, state, {
        firstFavoriteCompleted: true,
      });
    case types.UnsetFirstFavoriteCompleted:
      return Object.assign({}, state, {
        firstFavoriteCompleted: false,
      });
    case types.SetQuickStartGuideCompleted:
      return Object.assign({}, state, {
        quickStartCompleted: true,
      });
    case types.UnsetQuickStartGuideCompleted:
      return Object.assign({}, state, {
        quickStartCompleted: false,
      });
    case types.YourTeamLoading:
      return Object.assign({}, state, {
        yourTeamLoading: action.yourTeamLoading,
      });
    case types.YourTeamSuccess:
      return Object.assign({}, state, {
        yourTeam: action.yourTeam,
        yourTeamLoading: false,
      });
    case types.YourTeamFailure:
      return Object.assign({}, state, {
        yourTeam: [],
        yourTeamLoading: false,
      });
    case types.SendEmailVerification:
      return Object.assign({}, state, {
        emailVerificationSent: true,
      });
    case types.SendPhoneVerification:
      return Object.assign({}, state, {
        phoneVerificationSent: true,
      });
    case types.ShowEmailVerifyPrompt:
      return Object.assign({}, state, {
        showEmailVerifyPrompt: action.showEmailVerifyPrompt,
      });
    case types.ShowPhoneVerifyPrompt:
      return Object.assign({}, state, {
        showPhoneVerifyPrompt: action.showPhoneVerifyPrompt,
      });
    case types.ShowEmailVerifyModal:
      return Object.assign({}, state, {
        showEmailVerifyModal: action.showEmailVerifyModal,
      });
    case types.ShowPhoneVerifyModal:
      return Object.assign({}, state, {
        showPhoneVerifyModal: action.showPhoneVerifyModal,
      });
    case types.SetPreferencesStarted:
      return Object.assign({}, state, {
        preferencesStarted: true,
      });
    case types.UnsetPreferencesStarted:
      return Object.assign({}, state, {
        preferencesStarted: false,
      });
    case types.TogglePhoneNumberOauthModal:
      return Object.assign({}, state, {
        showPhoneNumberOauthModal: action.toggle,
      });
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
