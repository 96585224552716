import { isIOS, isChrome } from 'react-device-detect';

export function isMobile() {
  if (typeof window !== 'undefined') {
    if (isIOS && isChrome) return document.documentElement.clientWidth <= 529;
    return window.innerWidth <= 529;
  }
  return false;
}

export function isTablet() {
  if (typeof window !== 'undefined') {
    if (isIOS && isChrome)
      return (
        document.documentElement.clientWidth >= 530 &&
        document.documentElement.clientWidth < 949
      );
    return window.innerWidth >= 530 && window.innerWidth < 949;
  }
  return false;
}

export function isWeb() {
  if (typeof window !== 'undefined') {
    if (isIOS && isChrome) return document.documentElement.clientWidth > 949;
    return window.innerWidth > 949;
  }
  return false;
}
