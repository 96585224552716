import * as React from 'react';
import { Avatar, MenuItem } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import { formatSearchDetails } from '../../../lib/helpers';
import combineStyles from '../../../styles/combineStyles';
import importedStyles from './styles';

const styles = createStyles({
  root: {
    marginRight: 10,
  },
  secondary: {
    color: 'rgba(0, 0, 0, 0.54) !important',
    fontSize: '14px !important',
    fontWeight: 'normal',
    lineHeight: '1.43rem !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textShadow: 'none',
    whiteSpace: 'nowrap',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
const combinedStyles = combineStyles(styles, importedStyles);

export interface AutocompleteAdditionalProps {
  classes?: any;
  fullWidth: boolean;
  id: string;
  label: string;
  name: string;
  placeholder: string;
  required: boolean;
  [x: string]: any;
}

export interface Props {
  avatar?: any;
  classes: any;
  custom?: boolean;
  disconnect: boolean;
  getItemProps: Function;
  items: any[];
  resultsToShow: number;
  noFilter: boolean;
  primary: string;
  secondary?: string;
  value: string | null;
}

export class SearchResultsItem extends React.Component<Props, {}> {
  getSuggestions = (
    items: any,
    inputValue: any,
    primary: string,
    disconnect: boolean,
    noFilter: boolean,
  ) => {
    if (disconnect) {
      return [];
    }
    if (noFilter) {
      return items;
    }
    return items.filter((suggestion: any) => {
      const keep = !inputValue || (suggestion[primary] || '').toLowerCase();
      return keep;
    });
  };

  render() {
    const {
      avatar,
      classes,
      custom,
      disconnect,
      getItemProps,
      items,
      noFilter,
      primary,
      secondary,
      value,
    } = this.props;

    return (
      <div style={{ width: '100%' }}>
        {this.getSuggestions(items, value, primary, disconnect, noFilter)
          .slice(0, this.props.resultsToShow)
          .map((item: any) => {
            return (
              <MenuItem
                {...getItemProps({
                  item,
                })}
                key={item[primary]}
                style={{ height: 50 }}
              >
                <Avatar
                  classes={{
                    root: classes.root,
                  }}
                >
                  {!avatar && <span>{item[primary][0]}</span>}
                  {avatar && <span>{avatar}</span>}
                </Avatar>
                {custom && (
                  <div className={classes.textContainer}>
                    <span>{item.locationName}</span>
                    <span className={classes.secondary}>
                      {formatSearchDetails(item)}
                    </span>
                  </div>
                )}
                {secondary && (
                  <div className={classes.textContainer}>
                    <span>{item[primary]}</span>
                    <span className={classes.secondary}>{item[secondary]}</span>
                  </div>
                )}
                {!secondary && !custom && (
                  <div className={classes.textContainer}>
                    <span>{item[primary]}</span>
                  </div>
                )}
                {get(item, 'numberOfNewMatches', 0) > 0 && (
                  <div className={classes.newTagContainer}>
                    <span className={classes.newTag}>
                      {item.numberOfNewMatches}
                    </span>
                  </div>
                )}
              </MenuItem>
            );
          })}
      </div>
    );
  }
}

export default withStyles(combinedStyles)(SearchResultsItem);
