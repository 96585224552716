import moment from 'moment';

export const minPrice = 0;
export const maxPrice = 20000000;
export const startingYear = 1800;
export const endingYear = moment().year();
export const fieldTextColor = 'grey';
export const maxSqFeet = 5000;
export const maxHOA = 2500;

export const sqFeetRange: any[] = [];
for (let i = 500; i <= maxSqFeet; ) {
  if (i < 1000) {
    sqFeetRange.push(i);
    i = i + 100;
  } else if (i >= 1000 && i < 2500) {
    sqFeetRange.push(i);
    i = i + 250;
  } else if (i >= 2500 && i <= 5000) {
    sqFeetRange.push(i);
    i = i + 500;
  }
}

export const hoaRange: any[] = [];
for (let i = 100; i <= maxHOA; ) {
  if (i < 1000) {
    hoaRange.push(i);
    i = i + 100;
  } else if (i >= 1000 && i <= 2500) {
    hoaRange.push(i);
    i = i + 500;
  }
}

// Range of prices to let users choose from
export const priceRange: any[] = [];
for (let i = 250000; i <= maxPrice; ) {
  if (i < 500000) {
    priceRange.push(i);
    i = i + 25000;
  } else if (i >= 500000 && i < 1000000) {
    priceRange.push(i);
    i = i + 50000;
  } else if (i >= 1000000 && i < 5000000) {
    priceRange.push(i);
    i = i + 250000;
  } else if (i >= 5000000 && i < 10000000) {
    priceRange.push(i);
    i = i + 500000;
  } else {
    priceRange.push(i);
    i = i + 2000000;
  }
}

// Range of ages to let users choose from
export const maxValueList: any = [];
for (let i = startingYear; i <= endingYear; ) {
  if (i >= startingYear && i < 1900) {
    maxValueList.push(i);
    i = i + 10;
  } else if (i >= 1900 && i < 1970) {
    maxValueList.push(i);
    i = i + 5;
  } else {
    maxValueList.push(i);
    i = i + 1;
  }
}

export const yearRange = maxValueList.slice().reverse();

export const searchFilterState = {
  condo: false,
  minDaysOnMarket: 0,
  daysOnMarket: 100000,
  hasGarageSpaces: false,
  maxAge: startingYear,
  maxHOA: 0,
  maxPrice: 1000000000,
  minAge: endingYear,
  minBath: 0,
  minBr: 0,
  minParking: 0,
  minPrice,
  minSf: 0,
  maxSf: 50000,
  multi: false,
  openHousesOnly: false,
  priceRange,
  propType: [],
  single: false,
  sqFeetRange,
  yearRange,
  photosByRoom: '',
  conditionsByRoomType: [],
};
