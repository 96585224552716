import * as types from '../actions/actionTypes';

const initialState = {
  offersFetched: false,
  offerListing: undefined,
  userOffers: [],
  showOfferDialog: false,
};

/**
 * State mangement for offers
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function offer(state: any = initialState, action: any) {
  switch (action.type) {
    case types.CloseOfferDialog:
      return Object.assign({}, state, {
        offerListing: undefined,
        showOfferDialog: false,
      });
    case types.OffersFetchFailure:
      return Object.assign({}, state, {
        userOffers: [],
      });
    case types.OffersFetchSuccess:
      return Object.assign({}, state, {
        offersFetched: true,
        userOffers: action.offers,
      });

    case types.OpenOfferDialog:
      return Object.assign({}, state, {
        offerListing: action.listing,
        showOfferDialog: true,
      });
    case types.LogoutSuccess:
      return initialState;
    default:
      return state;
  }
}
