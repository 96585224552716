import * as types from '../actions/actionTypes';

const initialState = {
  loading: false,
  socialListing: null,
  error: '',
  signedUp: false,
  ratingByUser: {},
  avgRating: 0,
  totalUsersRated: 0,
  trendsLoading: true,
  trendingListings: [],
  posts: [],
  selectedPost: null,
  reportedPost: null,
  votes: [],
  leaderboard: [],
};

/**
 * State management for data related to a listing from the sidebar.
 * @param {Object} state - The current (or initial) state
 * @param {Object} action - An incoming action
 */
export default function socialListing(state: any = initialState, action: any) {
  switch (action.type) {
    case types.SocialListingStart:
      return Object.assign({}, state, {
        loading: true,
      });
    case types.SocialListingSuccess:
      return Object.assign({}, state, {
        loading: false,
        socialListing: action.socialListing,
      });
    case types.SocialListingError:
      return Object.assign({}, state, {
        socialListing: {},
        loading: false,
        error: action.error,
      });
    case types.SocialListingClear:
      return Object.assign({}, state, {
        socialListing: null,
        error: '',
        ratingByUser: null,
      });
    case types.SignupSidebarSuccess:
      return Object.assign({}, state, {
        signedUp: true,
      });
    case types.ClearSignedUpMessage:
      return Object.assign({}, state, {
        signedUp: false,
      });
    case types.SocialListingRatingCheckSuccess:
      return Object.assign({}, state, {
        ratingByUser: action.rating,
      });
    case types.SocialListingRatingCheckFailed:
      return Object.assign({}, state, {
        ratingByUser: {},
        error: action.error,
      });
    case types.LoadTrendingSocialListings:
      return Object.assign({}, state, {
        trendsLoading: action.trendsLoading,
      });
    case types.TrendingSocialListingsSuccess:
      return Object.assign({}, state, {
        trendsLoading: false,
        trendingListings: action.trendingListings,
      });
    case types.TrendingSocialListingsFailure:
      return Object.assign({}, state, {
        trendsLoading: false,
        trendingListings: [],
      });
    case types.PostRatingSuccess:
      return Object.assign({}, state, {
        avgRating: action.rating,
        totalUsersRated: action.ratedByUsers,
      });
    case types.PostRatingFailure:
      return Object.assign({}, state, {
        avgRating: 0,
        totalUsersRated: 0,
        error: action.error,
      });
    case types.SocialListingRatingSuccess:
      return Object.assign({}, state, {
        avgRating: action.rating,
        totalUsersRated: action.ratedBy,
      });
    case types.SocialListingRatingFailure:
      return Object.assign({}, state, {
        avgRating: action.rating,
        totalUsersRated: action.ratedBy,
        error: action.error,
      });
    case types.SocialListingPostsSuccess:
      return Object.assign({}, state, {
        posts: action.posts,
      });
    case types.SocialListingPostsFailure:
      return Object.assign({}, state, {
        posts: null,
        error: action.error,
      });
    case types.SocialListingSubmitPostSuccess:
      return Object.assign({}, state, {
        selectedPost: null,
      });
    case types.SocialListingSubmitPostFailure:
      return Object.assign({}, state, {
        error: action.error,
      });
    case types.SocialListingVoteSuccess:
      return Object.assign({}, state, {});
    case types.SocialListingVoteFailure:
      return Object.assign({}, state, {
        error: action.error,
      });
    case types.SocialListingFetchVotesSuccess:
      return Object.assign({}, state, {
        votes: action.votes,
      });
    case types.SocialListingFetchVotesFailure:
      return Object.assign({}, state, {
        error: action.error,
      });
    case types.SocialListingPostForReply:
      return Object.assign({}, state, {
        selectedPost: action.post,
      });
    case types.SocialListingPostDeleteSuccess:
      return Object.assign({}, state, {});
    case types.SocialListingPostDeleteFailure:
      return Object.assign({}, state, {
        error: action.error,
      });
    case types.SocialListingVoteDeleteSuccess:
      return Object.assign({}, state, {});
    case types.SocialListingVoteDeleteFailure:
      return Object.assign({}, state, {
        error: action.error,
      });
    case types.SocialListingPostReportSuccess:
      return Object.assign({}, state, {
        reportedPost: action.reportedPost,
      });
    case types.SocialListingPostReportFailure:
      return Object.assign({}, state, {
        error: action.error,
      });
    case types.SocialListingPostReportClear:
      return Object.assign({}, state, {
        reportedPost: null,
      });
    case types.SocialListingLeaderboardSuccess:
      return Object.assign({}, state, {
        leaderboard: action.rankings,
      });
    case types.SocialListingLeaderboardFailure:
      return Object.assign({}, state, {
        error: action.error,
      });
    default:
      return state;
  }
}
