import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';
import { SignOut, SignIn, CaretUp, CaretDown } from 'phosphor-react';
import { Button } from '@material-ui/core';
import styles from './../../../styles/sass/components/SideBar.module.scss';
import { AuthenticatedMenu } from './SideBarData';
import { useDispatch, useSelector } from '../../../lib/react-redux-hooks';
import EventEmitter from '../../../lib/eventEmitter';
import { isWeb } from '../../../lib/getDeviceSize';
import { logout } from '../../../actions/auth';
import * as types from '../../../actions/actionTypes';
import { PAGES_WITH_COLLAPSED_SIDEBAR } from '../../../lib/constants';

interface Props {
  height: number;
  router: any;
}
const SideBar = ({ height, router }: Props) => {
  const sideBarRef: any = useRef();
  const [isCollapsed, collapse] = useState(false);
  const [menuVisible, toggleMenu] = useState(false);
  const [buySubmenuOpen, toggleBuySubmenu] = useState(false);
  const dispatch = useDispatch();
  const { feedSearch, token, user } = useSelector((state: any) => {
    return {
      feedSearch: state.feed.feedSearch,
      token: state.auth.token,
      user: state.auth.user,
    };
  });

  useEffect(() => {
    let clickEvent: any = document.addEventListener(
      'mousedown',
      (event: any) => {
        if (
          sideBarRef &&
          sideBarRef.current &&
          !sideBarRef.current.contains(event.target)
        ) {
          toggleMenu(false);
        }
      },
    );
    let scrollEvent: any = document.addEventListener('scroll', (event: any) => {
      if (
        sideBarRef &&
        sideBarRef.current &&
        !sideBarRef.current.contains(event.target)
      ) {
        toggleMenu(false);
      }
    });
    EventEmitter.on('openSidebarMenu', _handleToggleEvent);

    return () => {
      document.removeEventListener('mousedown', clickEvent);
      document.removeEventListener('scroll', scrollEvent);
      EventEmitter.removeListener('openSidebarMenu', _handleToggleEvent);
    };
  }, []);

  useEffect(() => {
    if (PAGES_WITH_COLLAPSED_SIDEBAR.indexOf(router.pathname) >= 0) {
      collapse(true);
    } else {
      collapse(false);
    }
  }, [router.pathname]);

  const handleQuiz = () => {
    if (token) {
      router.push({
        pathname: '/survey',
        query: {
          stage: 'info',
          part: 'basics',
          question: 'cover',
        },
      });
    } else {
      const { asPath } = router;
      const usePath = asPath.includes('/search') ? '/search' : asPath;
      router.push({
        pathname: '/signup/onboarding',
        query: {
          stage: 'intro',
          question: 'cover',
          signupPath: encodeURI(usePath),
        },
      });
    }
  };

  const _handleToggleEvent = useCallback(() => {
    toggleMenu(true);
  }, []);

  const checkCurrentPath = (item: any) => {
    if (item.title === 'FAQ') {
      return router.pathname === '/buying-with-torii';
    } else {
      return router.pathname.includes(item.linkURL);
    }
  };

  const handleMenuSelect = useCallback(
    (item: any) => {
      if (item.title === 'Search') {
        router.push({
          pathname: '/search',
          query: {
            location: feedSearch.location,
            filters: JSON.stringify(feedSearch),
          },
        });
      } else {
        router.push(item.linkURL);
      }
      // Close the SideBar on menu click
      toggleMenu(false);
    },
    [feedSearch],
  );

  const handleSignout = useCallback(() => {
    dispatch(logout());
    router.push('/');
  }, []);

  const handleSignIn = useCallback(() => {
    toggleMenu(false);
    dispatch({
      type: types.OpenLoginDialog,
    });
  }, []);

  const handleSignUp = useCallback(() => {
    toggleMenu(false);
    // const { asPath } = router;
    // const usePath = asPath.includes('/search') ? '/search' : asPath;
    // router.push({
    //   pathname: '/signup/onboarding',
    //   query: {
    //     stage: 'intro',
    //     question: 'cover',
    //     signupPath: encodeURI(usePath),
    //   },
    // });
    dispatch({ type: types.OpenSignupDialog });
  }, []);

  const renderMenu = useCallback(() => {
    return (
      <React.Fragment>
        {AuthenticatedMenu.map((item: any, index: number) => {
          const active = checkCurrentPath(item);
          const selectedMenuStyle = isCollapsed
            ? styles.collapsedSelectedMenuItem
            : styles.selectedMenuItem;
          return (
            <React.Fragment key={index}>
              {index === 5 && <hr className={styles.simpleLine} />}
              <MenuItem
                active={active}
                key={index}
                onClick={() => handleMenuSelect(item)}
                className={active ? selectedMenuStyle : styles.menuItem}
                icon={active ? item.iconIsActive : item.iconNotActive}
                style={{
                  borderRadius: index === 0 && !isWeb() ? '0px 25px 0 0px' : 0,
                }}
              >
                {item.title}
              </MenuItem>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }, [isCollapsed, router]);

  const handleOpenSubmenu = (e: any) => {
    e.stopPropagation();
    toggleMenu(true);
    toggleBuySubmenu(!buySubmenuOpen);
  };

  return (
    <div className={styles.sidebarContainer} style={{ height }}>
      <ProSidebar
        style={{ height, borderRadius: '0 40 40 0', zIndex: 11 }}
        collapsed={!menuVisible && isCollapsed}
        breakPoint="lg"
        toggled={menuVisible}
      >
        <SidebarContent>
          <div
            className={`${styles.userDetails} no-desktop-small no-desktop no-desktop-xl`}
          >
            {token && (
              <React.Fragment>
                <div className={styles.profileContainer}>
                  <img
                    className={styles.userImage}
                    src={user.picture || user.gravatar}
                  />
                  <div className={styles.userName}>
                    {user.firstName} {user.lastName}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          <Menu className={styles.menuItems} iconShape="round" ref={sideBarRef}>
            {(token || isWeb()) && renderMenu()}
            {token && !isWeb() && (
              <MenuItem
                key={'signout'}
                onClick={handleSignout}
                className={styles.menuItem}
                icon={<SignOut size={24} color="#737373" weight="regular" />}
              >
                Sign Out
              </MenuItem>
            )}
          </Menu>
        </SidebarContent>
        {!isWeb() && !token && (
          <SidebarFooter style={{ borderTop: 'none' }}>
            <Menu className={styles.menuItems} iconShape="round">
              <MenuItem
                key={'buy'}
                onClick={() => router.push('/buy')}
                className={styles.menuItem}
              >
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  Buy
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: 35,
                    }}
                    onClick={handleOpenSubmenu}
                    onMouseDown={handleOpenSubmenu}
                  >
                    {buySubmenuOpen ? (
                      <CaretDown size={16} color="#2D2332" weight="regular" />
                    ) : (
                      <CaretUp size={16} color="#2D2332" weight="regular" />
                    )}
                  </span>
                </span>
              </MenuItem>
              {buySubmenuOpen && (
                <div>
                  <MenuItem
                    key={'get-preapproved'}
                    onClick={() => router.push('/get-preapproved')}
                    className={`${styles.menuItem} ${styles.subMenuItem}`}
                  >
                    Get Pre-Approved
                  </MenuItem>
                  <MenuItem
                    key={'tools'}
                    onClick={() => router.push('/tools')}
                    className={`${styles.menuItem} ${styles.subMenuItem}`}
                  >
                    Homebuying Tools
                  </MenuItem>
                  <MenuItem
                    key={'why-torii'}
                    onClick={() => router.push('/buy')}
                    className={`${styles.menuItem} ${styles.subMenuItem}`}
                  >
                    Why Torii
                  </MenuItem>
                  <MenuItem
                    key={'quiz'}
                    onClick={handleQuiz}
                    className={`${styles.menuItem} ${styles.subMenuItem}`}
                  >
                    Personalization Quiz
                  </MenuItem>
                </div>
              )}
              <MenuItem
                key={'sell'}
                onClick={() => router.push('/sell')}
                className={styles.menuItem}
              >
                Sell
              </MenuItem>
              <MenuItem
                key={'search-homes'}
                onClick={() => router.push('/search-homes')}
                className={styles.menuItem}
              >
                Search
              </MenuItem>
              <MenuItem
                key={'join'}
                onClick={() => router.push('/join')}
                className={styles.menuItem}
              >
                For Agents
              </MenuItem>
              <MenuItem
                key={'about'}
                onClick={() => router.push('/about')}
                className={styles.menuItem}
              >
                About
              </MenuItem>
              <MenuItem
                key={'faq'}
                onClick={() => router.push('/buying-with-torii?focus=faq')}
                className={styles.menuItem}
              >
                FAQ
              </MenuItem>
              <MenuItem
                key={'login'}
                onClick={handleSignIn}
                className={styles.menuItem}
                icon={<SignIn size={24} color="#737373" weight="regular" />}
              >
                Login
              </MenuItem>
              <MenuItem key={'SignUp'}>
                <Button className={styles.signupButton} onClick={handleSignUp}>
                  <span>Create Account</span>
                </Button>
              </MenuItem>
            </Menu>
          </SidebarFooter>
        )}
      </ProSidebar>
    </div>
  );
};

export default SideBar;
