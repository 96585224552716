import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { media } from '../../../styles/base';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  imageStyle: {
    objectFit: 'contain',
    [media.mobile]: {
      height: '50%',
      width: '50%',
    },
  },
  textStyle: {
    color: '#195A50',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '30px',
  },
});

interface Props {
  imageProps?: any;
  loaderText?: string | React.ReactNode;
  loaderTextStyle?: any;
}

function LogoLoader({ imageProps, loaderText, loaderTextStyle }: Props) {
  const [pageLoaded, togglePageLoaded] = useState(false);

  useEffect(() => {
    togglePageLoaded(true);
  }, []);

  const classes = useStyles();
  return (
    <React.Fragment>
      {pageLoaded && (
        <div className={classes.container}>
          <img
            className={classes.imageStyle}
            src="/static/img/dashboard/logo_loading_animation.gif"
            alt="loading..."
            {...imageProps}
          />
          {loaderText && (
            <h3 className={classes.textStyle} {...loaderTextStyle}>
              {loaderText}
            </h3>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
export default LogoLoader;
