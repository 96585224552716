import { Dispatch } from 'redux';
import * as Sentry from '@sentry/react';

import fetchApi from '../services/api';
import track from '../lib/track';
import * as types from './actionTypes';
import { getOrCreateStore } from '../lib/with-redux-store';
import { checkIfSurveyCompleted } from '../lib/helpers';

const endPoints = {
  fetchSignupSurvey: '/get-search-survey',
  saveSignupSurvey: '/save-search-survey',
};

export const toggleSurveyLoader = (showSurveyLoader: boolean = false) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      showSurveyLoader,
      type: types.ToggleSurveyLoader,
    });
  };
};

export const toggleSurveyFetchLoader = (surveyFetchLoader: boolean = false) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      surveyFetchLoader,
      type: types.ToggleSurveyFetchLoader,
    });
  };
};
export const updateSignupSurvey = (values: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: types.UpdateSignupSurvey,
      values,
    });
  };
};

export const saveSignupSurvey = (values: any, token: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchApi(
        endPoints.saveSignupSurvey,
        { ...values, partial: true },
        'post',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({
          type: types.UpdateSignupSurveySuccess,
        });
        const surveyCompleted = checkIfSurveyCompleted(values);
        if (surveyCompleted) {
          try {
            track.completeSearchSurvey(values);
          } catch (error) {
            Sentry.captureException(error);
          }
        }
      } else {
        return dispatch({
          message: json.msg,
          type: types.UpdateSignupSurveyFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
};

export const fetchSignupSurvey = (token: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(toggleSurveyFetchLoader(true));
      const response = await fetchApi(
        endPoints.fetchSignupSurvey,
        {},
        'get',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        const { results } = json;
        return dispatch({
          type: types.SignupSurveyFetchSuccess,
          values: results,
        });
      } else {
        if (response.status === 400) {
          dispatch({
            message: json.msg,
            type: types.SignupSurveyFetchFailure,
          });
        }
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
};

/**
 * Save suvery values in bulk at the end of each section or altogether after auth
 * @param {string} token
 * @returns
 */
export const saveSurveyValues = (token: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const store = getOrCreateStore();
      const signupSurveyStore = store.getState().signupSurvey;
      const { signupSurveyDetails } = signupSurveyStore;
      const response = await fetchApi(
        endPoints.saveSignupSurvey,
        { ...signupSurveyDetails },
        'post',
        {},
        token,
      );
      const json = await response.json();
      if (response.ok) {
        const { results } = json;
        const surveyCompleted = checkIfSurveyCompleted(results);
        if (surveyCompleted) {
          // Don't blow up if we have an issue with tracking
          try {
            track.completeSearchSurvey(results);
          } catch (error) {
            Sentry.captureException(error);
          }
        }
        return dispatch({
          type: types.SignupSurveyFetchSuccess,
          values: results,
        });
      } else {
        return dispatch({
          message: json.msg,
          type: types.UpdateSignupSurveyFailure,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  };
};
