import * as React from 'react';

import LogoLoader from '../LogoLoader';

export interface Props {
  bottom?: number;
  top?: number;
  padTop?: number;
  imageProps?: any;
}

interface State {
  pageLoaded: boolean;
}

export class ToriiLoader extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      pageLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      pageLoaded: true,
    });
  }

  render() {
    const { imageProps } = this.props;
    return (
      <React.Fragment>
        {this.state.pageLoaded && (
          <div
            className="col-xs-12 row center-xs"
            style={{
              marginBottom: this.props.bottom,
              marginTop: this.props.top,
              paddingTop: this.props.padTop,
              position: 'relative',
            }}
          >
            <LogoLoader
              imageProps={{ height: 150, width: 150, ...imageProps }}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ToriiLoader;
